const state = {
  presentedDay: null,
};
const getters = {
  presentedDay: state => state.presentedDay,
};
const actions = {  
  addSerchAttractionMarker: ({ dispatch, rootGetters}, attractionIndex ) => {
    const serchAttractios = rootGetters.serchAttractios;
    const daylyAttraction = serchAttractios[attractionIndex];
    if( ! daylyAttraction ){return;}
    //all the attraction at the day
    var lat = parseFloat(daylyAttraction.latitude);
    var lng = parseFloat(daylyAttraction.longtitude);
    console.log("this is the line that we are colling")
    dispatch( 'addMarkerByLatAndLngToGlobalMap',
              { latPoint: lat,
                lngPoint: lng,
                name:daylyAttraction.title,
                bounce: google.maps.Animation.BOUNCE,
                iconUrl: "https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/icons%2Fmap_letter%2Fblack_numbers%2Fblack_map_Icon_empty.png?alt=media&token=ae5de8aa-cee4-47a7-966b-e02eb1b93480"});
  },
  updateMarkerOfDay: ({ dispatch, getters}, dayIndex ) => {
    const daylyAttraction = getters.allRide.attractionsByDay[dayIndex];
    var i;
    if( ! daylyAttraction ){return;}
    //all the attraction at the day
    for(i=0; i < daylyAttraction.daily_attractions.length; i++ ){
      var lat = parseFloat(daylyAttraction.daily_attractions[i].latitude);
      var lng = parseFloat(daylyAttraction.daily_attractions[i].longtitude);
      dispatch( 'addMarkerByLatAndLngToGlobalMap', { latPoint: lat, lngPoint: lng, name:daylyAttraction.daily_attractions[i].title});
    }
    // add the hotel at the morning
    var morningAccommodationLat = parseFloat(daylyAttraction.base_trip_accommodation.latitude);
    var morningAccommodationLng = parseFloat(daylyAttraction.base_trip_accommodation.longtitude);
    dispatch( 'addMarkerByLatAndLngToGlobalMap', { latPoint: morningAccommodationLat , lngPoint: morningAccommodationLng , name:"hotel A"});

    if( dayIndex == 0 ){return;}

    const preDaylyAttraction = getters.allRide.attractionsByDay[dayIndex - 1];
    if( ! preDaylyAttraction ){return;}
    if( preDaylyAttraction.base_trip_accommodation.latitude == daylyAttraction.base_trip_accommodation.latitude ){return;}

    // add the hotel at the end of the day
    var endDayAccommodationLat = parseFloat(preDaylyAttraction.base_trip_accommodation.latitude);
    var endDayAccommodationLng = parseFloat(preDaylyAttraction.base_trip_accommodation.longtitude);
    dispatch( 'addMarkerByLatAndLngToGlobalMap', { latPoint: endDayAccommodationLat , lngPoint: endDayAccommodationLng , name:"morning hotel"});
  },
  async updateMarkerByScrollerLocation({dispatch , commit, getters }){
    var dayToUpdate = await getters.serchDayIndex;
    var serchattractionIndexFocues = await dispatch( 'getTheFocuesDay' );
    const presentedDay = getters.presentedDay;
    
    if ( presentedDay == serchattractionIndexFocues ){return;}
    dispatch('removeAllMarkersToGlobalMAp');
    commit( 'setPresentedDay', serchattractionIndexFocues );
    dispatch( 'updateMarkerOfDay', dayToUpdate );
    dispatch( 'addSerchAttractionMarker', serchattractionIndexFocues );
    dispatch( 'fitBoundesToMarkersAtGlobalMap');
  },
  getTheFocuesDay: ({ rootGetters}) => {
    const serchAttractios = rootGetters.serchAttractios
    var i;
    for(i=0; i < serchAttractios.length; i++ ){
      const dailyAttractionItem = document.getElementById('serch_attractions_index_' + i );
      if( ! dailyAttractionItem ){
        return 0;
      }
      if ( dailyAttractionItem.getBoundingClientRect().y > 100 ){// tis si the topest item
        if ( i == 0 ){
          return 0;
        }
        return i-1;
      }
    }
    return 0;
  },
}
const mutations = {
  setPresentedDay( state, dayNumber ) {
    state.presentedDay = dayNumber;
  },
};





export default {
  state,
  getters,
  actions,
  mutations
};
