<template>
  <div>
    <div v-for="(umiqAttractions, index) in allAtractions" :key="index" :id="'daily_attraction_day_' + index">
        <b-container>
          <b-row>
            <b-col>
              <h5
                class="text-right font-weight-bold"
                style="color: #34d0b6"
              >
                uniq attraction id {{ umiqAttractions.AttractionUniqnessId }}
              </h5>
            
            </b-col>
          </b-row>
        </b-container>
        <b-card
          no-body
          v-for="(attraction, idx) in umiqAttractions.convertedUniqAtractions"
          :key="idx"
          class="my-4 border-0"
        >
        <div v-if="umiqAttractions.chosenAttraction.id==attraction.id" > 
          <h1 >
            ++++this is the uniq attraction+++++
          </h1>

        </div>

        <div v-else>
          <b-btn 
            @click="setAsMainAttraction( { 'uniqnessAttractionId': umiqAttractions.AttractionUniqnessId, 'attractionId': attraction.id } )"
            pill
            variant="warning"
            class="my-lg-0 my-md-2 my-2 ml-auto text-white p-3"
          >Set as main chosen attraction</b-btn>
        </div>
        <b-btn
          @click="moveAttractionToUniqnessGrop( { 'attractionId': attraction.id } )"
          pill
          variant="warning"
          class="my-lg-0 my-md-2 my-2 ml-auto text-white p-3"
        >move attraction to uniq group:</b-btn>
        <b-form-input
                              :value="attractionsUniqnessGroupIdToMergeWith"
                              @input="updateAttractionsUniqnessGroupIdToMergeWith"
                              placeholder="Umiqness Group Id"
                              class="border-0 shadow text-right"
                              size="lg"
        ></b-form-input>




        <b-row no-gutters>
          <b-col cols="3" lg="4" md="4" class="d-none d-md-block">
            <img
            v-if="attraction.image"
            :src="attraction.image"
            alt="Image"
            class="rounded"
            style="
            max-width: 100%;
            display: block;
            height: auto;"
            >
          </b-col>
          <b-col cols="12" lg="8" md="8">
            <b-container>
              <b-row class="res-row">
                <b-col cols="12">
                  <b-row>
                    <b-col>
                      </b-col>
                      <b-col cols="auto">
                        <div  dir="rtl" >
                          <h4 class="text-right font-weight-bold">
                            {{ attraction.title }}
                          </h4>
                          <h4 class="text-right font-weight-bold">
                            attraction id: {{ attraction.id }}
                          </h4>
                        </div>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <h3>
                          {{attraction.text}}
                        </h3>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="12" v-if="attraction.link">
                    <div class="my-3 text-right">
                      <b-link
                        :href= "addhttp( attraction.link )"
                        target="_blank">
                        <u>לינק לאתר</u>
                      </b-link>
                    </div>
                  </b-col>
                  <b-col cols="12" class="my-3 d-flex d-md-none">
                    <img
                    v-if="attraction.image"
                    :src="attraction.image"
                    alt="Image"
                    class="rounded"
                    style="
                    max-width: 100%;
                    display: block;
                    height: 50vh;"
                    >
                  </b-col>
                </b-row>
              </b-container>
            </b-col>
          </b-row>
          <span class="border-bottom"></span>

        </b-card>
      </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "AttractionUniqnes",
  components: {
  },
  computed: mapGetters([
    "allAtractions",
    "attractionsUniqnessGroupIdToMergeWith",
  ]),
  methods: {
    ...mapActions([
      "fetchAttractionUniqnes",
      "setAsMainAttraction",
      "moveAttractionToUniqnessGrop",
      "validateAndupdateAppToken",
      
    ]),
    addhttp( url ) {
      // We have an RIGHT-TO-LEFT MARK at the end of the strint ( at some links )
      // and we should remove them!
      if ( !/^(?:f|ht)tps?:/i.test( url ) ){
        url =  'http://'.concat( url )
      }
      // return url.replace(/[^\x00-\x7F]/g, "");
      return url.replace(/[^\x20-\x7E]/g, "");
    },
    updateAttractionsUniqnessGroupIdToMergeWith ( groupId ) {
      this.$store.commit('setAttractionsUniqnessGroupIdToMergeWith', groupId )
    },


 },
  created() {
    this.validateAndupdateAppToken();
    this.fetchAttractionUniqnes();
  },
};
</script>

<style scoped>
.jumbotron {
  background: url('https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/gui_images%2Fhero-bg.jpg?alt=media&token=170e81d2-ae7e-46eb-b20a-75f0b49fe11d') no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 100%;
}
@media only screen and (min-width: 1200px) {
  .jumbotron {
    background-position: 100% 100% !important;
    background-size: cover;
    background-origin: padding-box !important;
  }
}
@media only screen and (min-width: 1024px) {
  .jumbotron {
    background-position: 25% 100%;
    background-origin: padding-box !important;
  }
}
@media only screen and (min-width: 768px) {
  .jumbotron {
    background-position: 30% center;
    background-origin: content-box;
  }
}
@media only screen and (max-width: 425px) {
  .jumbotron {
    background-position: 25% center;
    background-origin: content-box;
  }
}
.sticky {
  top: 3em;
}

#container {
  width: 100%;
  height: 100%;
}
#up {
  min-height: 50px;
}
#down {
  height: calc(100% - 50px);
}
.lead-head {
  /* font-family: Assistant; */
  font-style: normal;
  font-weight: 800;
  font-size: 30px;
  line-height: 39px;
  text-align: right;
}
.res-row {
  min-height: 235px;
}

@media (max-width: 575.98px) {
  .res-row {
    min-height: 235px;
  }
}
@media (min-width: 768px) and (max-width: 1023.98px) {
  .res-row {
    min-height: 235px;
  }
}
@media (min-width: 1024.98px) {
  .res-row {
    min-height: 283px;
  }
}
.dropdown .dropdown-menu {
  border: none;
}
</style>
