

// it may be that this page is not used any more!!!!!!!!!!!!!!!!!!!

<template>
  <div>
    <p> </p>
    <p>asd</p>
    <button  type="button" class="btn btn-lg btn-block btn-primary" @click="openRideInformationPage()"> +הכנסת פרטי טיול שכבר נעשה+</button>
    <p></p>
    <h1 v-if=userRides style="text-align:center">הטיולים שלי </h1>
    <div v-for="userRide in userRides" :key="userRide.id" class="border">
      <div align='center'>
        <h2>{{ userRide.title }}</h2>
        <button  type="button" class="btn btn-primary" @click="goToRide(userRide.id)"> צפה וערוך טיול זה</button>
        <p>{{userRide.text}}</p>
        <p>{{userRide.start_date}}</p>
        <p>{{userRide.end_date}}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'UserRides',
  computed: mapGetters(['userRides', 'isAppLoggedIn', 'userId' ]),
  methods: { ...mapActions([  'fetchUserRides',
                              'serchAttractionToRide',
                              'removeAttractionFromRide',
                              'validateAndupdateAppToken',
                              'openRideInformationPage' ]),
    curentuserIsTheRideAuthor( attractionAuthorId ){
      if( this.userId == attractionAuthorId ){
        return true;
        }
      return false
    },
    goToRide( rideId ) {
      this.$router.push('/single_ride_with_map/' + rideId )

    }
  },
  created() {
    this.validateAndupdateAppToken();
    this.fetchUserRides();
  }
};
</script>

<style scoped>
.ride-container {
  column-count: 3;
  column-gap: 0;
}
img {
  max-width: 100%;
  padding: 5px;
}

/* The popup form - hidden by default */
.form-popup {

  margin: auto;
  width: 50%;
  border: 3px solid green;
  padding: 10px;

  display: none;
  position: fixed;
  bottom: 15px;
  /* right: 150px; */
  border: 3px solid #f1f1f1;
  /* z-index: 9; */
}

</style>
