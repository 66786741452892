<template>
  <b-modal hide-footer centered v-model="isVisible">
    <section class="text-center">
      <b-row no-gutters class="justify-content-center">
        <b-col cols="12" class="py-2">
          <div class="text-center mt-2 mb-5">
            <h3 class="font-weight-bold">אמסיס סופיא</h3>
            <p class="my-3">
              סופיאל רושיק ךל חלשנ ונחנאו ךלש ליימה תבותכ תא ןזה
              <br />.ךלש Tripplanner תמסיס
            </p>
          </div>
          <b-form role="form" @submit.prevent="handleSubmit(onSubmit)">
            <div>
              <b-form-input
                v-model="text"
                autocapitalize="none"
                placeholder="ליימ"
                class="border-0 shadow text-right"
                size="lg"
              ></b-form-input>
            </div>

            <div class="text-center mt-5">
              <b-row class="justify-content-center">
                <b-col lg="9" md="9">
                  <b-btn pill block variant="warning" class="py-3"
                    >תשלח קישור</b-btn
                  >
                </b-col>
              </b-row>
            </div>
            <div class="text-center mt-5">
              <b-row class="justify-content-center">
                <b-col lg="9" md="9">
                  <b-row>
                    <b-col>
                      <b-link @click.prevent="$emit('showmodal', 'oldSignup')">
                        <span class="turquoise">םשריהל</span> ךירצ ינא
                      </b-link>
                    </b-col>
                    <b-col>
                      <b-link @click.prevent="$emit('showmodal', 'login')">
                        <span class="turquoise">תורבחתהל</span> הרזח
                      </b-link>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </div>
          </b-form>
        </b-col>
      </b-row>
    </section>
  </b-modal>
</template>
<script>
export default {
  props: ["value"],
  data() {
    return {
      text: "",
      model: {
        email: "",
        password: "",
        rememberMe: false,
      },
      validation: false,
    };
  },
  computed: {
    isVisible: {
      set(val) {
        this.$emit("input", val);
      },
      get() {
        return this.value;
      },
    },
  },
  methods: {
    onSubmit() {
      // this will be called only after form is valid. You can do api call here to login
    },
  },
};
</script>

<style>
</style>