<template>
<div>
  <b-modal
    id="updateAttractionTime"
    hide-footer
    centered
    v-model="isVisible"
    modal-class="modal-cover "
    content-class="shadow-lg"
  >
    <button class="poup_close_me" block @click="$bvModal.hide('updateAttractionTime')">X</button>
    <div v-if="attractionForChangeingTheDate">
      <form
        role="form"
        @submit.prevent="handleSubmit(onSubmit)"
        class="add_ride_form"
      >
      <h2>עריכת יום ושעת תחילת הפעילות </h2>
      <h3>{{attractionForChangeingTheDate.title}}</h3>

      <h3>יום:</h3>      
      <datepicker
      class="date_picker"
      name="attractionDate"
      v-model="updateAttractionDate"
      :disabled-dates=getCoulenderDates
      ></datepicker>

      <h3>שעה:</h3>      
      <vue-timepicker
        format="hh:mm:ss A"
        name="attractionTime"
        v-model="updateAttractionTime"
      ></vue-timepicker>
      <b-btn @click="internalUpdateAttractionTimeAtRide" pill variant="warning" class="py-3 px-5 text-white">
        שמירה
      </b-btn>
      </form>


    </div>
  </b-modal>
</div>
</template>
<script>

import { mapActions, mapGetters } from "vuex";
import Datepicker from 'vuejs-datepicker';
import VueTimepicker from 'vue2-timepicker'
import moment from 'moment';

// CSS
import 'vue2-timepicker/dist/VueTimepicker.css'
export default {
props: ["value"],
  name: "UpdateAttractionTimePopUp",
  components: {
    Datepicker,
    VueTimepicker,
  },

  computed: {
    ...mapGetters([ 'allRide',
                    'newAttractionDateTime',
                    'attractionForChangeingTheDate',
                  ]),
    isVisible: {
        set(val) {
            this.$emit("input", val);
        },
        get() {
            return this.value;
        },
      },
    updateAttractionDate:{
      get () {
        return this.newAttractionDateTime
      },
      set (value) {
        var rideFirstDay = new Date( value );
        this.$store.commit('setNewAttractionDate', moment(rideFirstDay).format('M/D/YYYY') );
      }
    },
    updateAttractionTime:{
      get () {
        console.log( this.newAttractionDateTime.split(',')[1] )
        return this.newAttractionDateTime.split(',')[1];
      },
      set (value) {
        this.$store.commit('setNewAttractionTime', value );
      }
    },
    getCoulenderDates:{
    get () {
      var startDateMomentObject = moment(this.allRide.ride.start_date, "DD-MM-YYYY");
      var startDate = startDateMomentObject.toDate();
      var endDateMomentObject = moment(this.allRide.ride.end_date, "DD-MM-YYYY");
      var endDate = endDateMomentObject.toDate();
      var disabledDates = { 
                            to: startDate,
                            from: endDate
      }
    return disabledDates
    },
    }
    },


methods:{
    ...mapActions([  'updateAttractionTimeAtRide',
                      'showModal' ]),
    internalUpdateAttractionTimeAtRide( ){
      this.updateAttractionTimeAtRide( );
      this.showModal('')
    },

  },

};
</script>
<style scoped>
.modal.modal-cover .modal-content,
.modal.modal-cover .modal-footer,
.modal.modal-cover .modal-header {
  border: none;
  align-items: stretch;
}

p{
  letter-spacing: 0.045em;
  line-height: 1.4;
  font-size: 1.8rem;


}

.poup_close_me{
  font-size:2rem;
  height:5rem;
  /* position: fixed; */
  top:2rem;
  /* z-index: 999; */
  width: 5rem;
  box-shadow: 0 1.2rem 3.2rem rgba(0, 0, 0, 0.03);
  display: flex; 
  justify-content: center;
  align-items: center;
  color: black;
}

.date_picker{
  text-align: right;
}






</style>


















