<template>
	<div class="trip-cta2-section">
		<div class="container">
			<div class="trip-cta2-title">
				<h2>איך זה עובד?</h2>
			</div>
			<div class="trip-cta-border">
				<div class="row">
					<div class="col-md-4 col-sm-6 offset-md-0 offset-sm-3">
						<div class="trip-cta2-wrap">
							<div class="trip-cta2-thumb">
								<img src="https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/gui_images%2FctaGlobus.png?alt=media&token=f334abc5-89a8-4b70-a06e-c1d6f1e642c8" alt="">
							</div>
							<div class="trip-cta2-data">
								<p>בוחרים טיול ששותף<br/>בתור בסיס</p>
							</div>
						</div>
					</div>
					<div class="col-md-4 col-sm-6">
						<div class="trip-cta2-wrap">
							<div class="trip-cta2-thumb">
								<img src="https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/gui_images%2FctaGlobus.png?alt=media&token=f334abc5-89a8-4b70-a06e-c1d6f1e642c8" alt="">
							</div>
							<div class="trip-cta2-data">
								<p>הוסיפו/הסירו פעילויות<br/>קיימות לטיול</p>
							</div>
						</div>
					</div>
					<div class="col-md-4 col-sm-6">
						<div class="trip-cta2-wrap">
							<div class="trip-cta2-thumb">
								<img src="https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/gui_images%2Fctascrean.png?alt=media&token=5eae5025-0fea-4621-9a99-c54c91073584" alt="">
							</div>
							<div class="trip-cta2-data">
								<p>תהנו מהטיול<br/>ושתפו משפחות</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
  name: 'HowItWorks',
};
</script>
<style>






@media (max-width: 777px) {

    .trip-cta-border:before{
        content: none;
    }
}


@media (max-width: 667px) {

.trip-cta2-section {
    padding: 30px 0 10px;
}
.trip-cta2-wrap {
    margin-bottom: 20px;
}
.trip-cta2-title h2 {
    font-size: 14px;
    margin-bottom: 30px;
}
.trip-cta2-data p {
    font-size: 14px;
    line-height: 18px;
}
.trip-cta2-thumb img {
    width: 100px;
    height: 100%;
}
}





</style>
