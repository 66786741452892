import api from '../../api/globalRides';
import { router } from '../../main';

const state = {
  globalRides: [],
  globalRidesFilters: {'status': 'noFilters'},
  globalRidesSearchRidePhysicalDisabilities: false,
};

const getters = {
  globalRides: state => state.globalRides,
  globalRidesFilters: state => state.globalRidesFilters,
  globalRidesSearchRidePhysicalDisabilities: state => state.globalRidesSearchRidePhysicalDisabilities,
};

const actions = {
  async fetchGlobalRides({ dispatch, state, commit }) {
    const globalRidesFilters = state.globalRidesFilters
    dispatch( 'showModal', "loadingIcon" );
    const response = await api.fetchGlobalRides( globalRidesFilters );
    if(response.data.rides.length==0){
      dispatch( 'showModalWithParameters',
      { errorNumber : "sorry_we_dont_have_this_ride_please_add_it_at_your_return",
        type:'textConverter' 
      })
      return;
    }
    dispatch( 'showModal', "" );
    commit('setGlobalRides', response.data.rides);
  },

  setfiltersAndFetchGlobalRides({ dispatch, state },latAndLng){
    const filters = {
      lat: latAndLng.latitude,
      lng: latAndLng.longtitude,
      physicalDisabilities: false
    };
    mutations.setGlobalRidesFilters(state, filters)
    dispatch("fetchGlobalRides");
  },

  async fetchGlobalRidesByName({ dispatch }, placeName) {
    dispatch( 'showModal', "loadingIcon" );
    dispatch('getPlaceLocationFromNameAndRunCalback', { callback: "setfiltersAndFetchGlobalRides", name: placeName} );
    return;
  },

  searchRidesByGeoPoint( {commit, dispatch, getters }, place) {
    if (place.geometry) {
      // this.gMapPlace = place;
      const physicalDisabilities = getters.globalRidesSearchRidePhysicalDisabilities;
      const filters = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
        physicalDisabilities: physicalDisabilities
      };
    commit('setGlobalRidesFilters', filters);
    dispatch( 'serchattraction', {} );
    }
  },
  serchattraction({dispatch}) {
    const path = '/search_ride';
    if ( router.history.current.fullPath !== path ) router.push( path )
    dispatch( 'fetchGlobalRides', {} );
  },
  goToSerchattractionPage( /* eslint-disable-line no-unused-vars */ _ ) {
    const path = '/search_ride';
    router.push( path )

  },
};

const mutations = {
  setGlobalRides: (state, rides) => {
    state.globalRides = rides;
  },
  setGlobalRidesFilters: (state, filters) => {
    state.globalRidesFilters = filters;
  },
  SetGlobalRidesSearchRidePhysicalDisabilities: ( state, status ) => {
    state.globalRidesSearchRidePhysicalDisabilities = status;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
