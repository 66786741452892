
// import api from '../../api/editRide';
import api from '../../api/accommodation';
import { router } from '../../main';
// import { FormTimepickerPlugin } from 'bootstrap-vue';

const state = {
  accommodationName: '',
  accommodationAddress: '',
  accommodationBookingConfirmation: '',
  accommodationText: '',
  accommodationLink: '',
  accommodationCheckIn: null,//'7/16/2020, 12:00:00 AM',
  accommodationCheckOut: null,//'7/17/2020, 12:00:00 AM',
  accommodationLat: null,
  accommodationLng: null,
  accommodationImage: null,
  accommodationRate: null,
  googleContryAndCity: null,
  accommodationPreviewImage:null,
  accommodationPlannedDate: null,
  accommodationId: null,
  accommodationMode: null,
  accommodationFormFromRide: null,
  accommodationFormFromBaseRide: null,
  accommodationErrores: [],  
  // accommodationFormFromBaseRide: { "address": "וינה", "booking_confirmation": null, "check_in_date": "2021-07-15", "check_out_date": "2021-07-18", "id": 58, "image": "http://127.0.0.1:5000/static/accommodation_pics/9.jpeg", "latitude": "48.2220046", "link": "https://www.meininger-hotels.com/en/hotels/vienna/", "longtitude": "16.3738", "name": "MEININGER Hotel Wien Downtown Franz", "phone": null, "status": "base_trip_accomodation", "text": "Rembrandtstraße 21 \r\n1020 Wien\r\nAustria\r\nhttps://www.meininger-hotels.com/en/hotels/vienna/" },
};

const getters = {
  accommodationName: state => state.accommodationName,
  accommodationAddress: state => state.accommodationAddress,
  accommodationBookingConfirmation: state => state.accommodationBookingConfirmation,
  accommodationText: state => state.accommodationText,
  accommodationLink: state => state.accommodationLink,
  accommodationCheckOut: state => state.accommodationCheckOut,
  accommodationCheckIn: state => state.accommodationCheckIn,
  accommodationLat: state => state.accommodationLat,
  accommodationLng: state => state.accommodationLng,
  accommodationImage: state => state.accommodationImage,
  isAccommodationImage: state => state.accommodationImage != null,
  accommodationRate: state => state.accommodationRate,
  googleContryAndCity: state => state.googleContryAndCity,
  accommodationPreviewImage: state => state.accommodationPreviewImage,
  accommodationPlannedDate: state => state.accommodationPlannedDate,
  accommodationId: state => state.accommodationId,
  accommodationMode: state => state.accommodationMode,
  accommodationFormFromRide: state => state.accommodationFormFromRide,
  haveFormFromRide: state => !!state.accommodationFormFromRide,
  haveFormFromRideAndLocation: state => !!state.accommodationFormFromRide.latitude,
  accommodationIsBooked: state => state.accommodationFormFromRide.status=='booked',
  isAccommodationEditMode: state => state.accommodationMode=='edit',
  accommodationFormFromBaseRide: state => state.accommodationFormFromBaseRide,
  accommodationFormFromBaseRideCheckinFormatedToBoking:  state => {
    if( state.accommodationFormFromBaseRide.check_in_date != undefined )
      return state.accommodationFormFromBaseRide.check_in_date.split('-')[2]+'-'+state.accommodationFormFromBaseRide.check_in_date.split('-')[1]+'-'+state.accommodationFormFromBaseRide.check_in_date.split('-')[0]
    return '';
  },
  accommodationFormFromBaseRideCheckoutFormatedToBoking: state => {
    if( state.accommodationFormFromBaseRide.check_out_date != undefined )
      return state.accommodationFormFromBaseRide.check_out_date.split('-')[2]+'-'+state.accommodationFormFromBaseRide.check_out_date.split('-')[1]+'-'+state.accommodationFormFromBaseRide.check_out_date.split('-')[0];
    return '';
    },
  haveFormFromBaseRide: state => !!state.accommodationFormFromBaseRide,
  accommodationErrores: state => state.accommodationErrores,

};

const actions = {
  accommodationLoadDefault({commit}){
    commit( 'setAccommodationPlannedDate', null );
    commit( 'setAccommodationId', null );
    commit( 'setAccommodationName', '' );
    commit( 'setAccommodationAddress', '' );
    commit( 'setAccommodationBookingConfirmation', '' );
    commit( 'setAccommodationText', '' );
    commit( 'setAccommodationLink', '' );
    commit( 'setAccommodationCheckOut', null );
    commit( 'setAccommodationCheckIn', null );
    commit( 'setAccommodationLat', null );
    commit( 'setAccommodationLng', null );
    commit( 'setAccommodationImage', null );
    commit( 'setAccommodationGoogleContryAndCity', null );
    commit( 'setAccommodationRate', null );
    commit( 'setAccommodationPreviewImage', null );
    commit( 'setAccommodationMode', null );
    commit( 'setAccommodationFormFromRide', null );
    commit( 'setBasRideAccommodationFormFromRide', null );
    commit( 'setAccommodationErrores', [] );
  },
  async validateAccmmodationInput( { state, rootState, commit }, accommodation ){
    commit( 'setAccommodationErrores', [] );
    if ( accommodation['name'] == '' )
      state.accommodationErrores.push( "שם מקום הלינה")

    if ( accommodation['name'].length > 140 )
      state.accommodationErrores.push( "שם המיקום ארוך מידי")

    if ( ! accommodation['checkIn'] && ! accommodation['checkOut'] )
      state.accommodationErrores.push( "יש להכניס תאריך כניסה ויציאה עבור מקום הלינה")
    else{
      var rideFirstDay = Date.parse( rootState.ride.ride.ride.start_date.split("-")[1] + "-" + rootState.ride.ride.ride.start_date.split("-")[0] + "-" + rootState.ride.ride.ride.start_date.split("-")[2] );
      var rideLastDay = Date.parse( rootState.ride.ride.ride.end_date.split("-")[1] + "-" + rootState.ride.ride.ride.end_date.split("-")[0] + "-" + rootState.ride.ride.ride.end_date.split("-")[2] );
      var formCheckin = Date.parse(  accommodation['checkIn'].split("-")[1] + "-" + accommodation['checkIn'].split("-")[0] + "-" + accommodation['checkIn'].split("-")[2]  );
      var formCheckOut = Date.parse( accommodation['checkOut'].split("-")[1] + "-" + accommodation['checkOut'].split("-")[0] + "-" + accommodation['checkOut'].split("-")[2]  );

      if ( formCheckin < rideFirstDay )
        state.accommodationErrores.push( "תאריך ההזמנה קטן מיום תחילת הטיול")

      if ( formCheckOut > rideLastDay )
        state.accommodationErrores.push( "תאריך ההזמנה גדול מיום סיום הטיול")
    }
    // we shjould check that we dont have dubell boking as well!!
    if ( accommodation['bookingConfirmation'] && accommodation['bookingConfirmation'].length > 40 )
      state.accommodationErrores.push( "מספר אישור הזמנה ארוך מידי")

    if ( accommodation['lat'] == null )
      state.accommodationErrores.push( "נא להוסיף מיקום על המפה")

    if ( state.accommodationErrores.length > 0 )
      return false
    return true;
  },
  goToBaseRideAccommodation({ commit, dispatch }, baseRideAccommodation ){
    dispatch('accommodationLoadDefault');
    commit('setBasRideAccommodationFormFromRide', baseRideAccommodation );
    router.push('/accommodation' );
  },
  goToBokingDotCom({ commit, dispatch }, baseRideAccommodation ){
    dispatch('accommodationLoadDefault');
    commit('setBasRideAccommodationFormFromRide', baseRideAccommodation );
    router.push('/booking' );
  },
  goToRideAccommodation({ commit, dispatch }, rideAccommodation ){
    dispatch('accommodationLoadDefault');
    commit('setAccommodationFormFromRide', rideAccommodation );
    router.push('/accommodation' );
  },
  uploadAccommodationIfBooked({ getters, commit } ){
    const accommodation = getters.accommodationFormFromRide;
    if( getters.haveFormFromRide == false ){ return; }
    commit('setAccommodationName', accommodation.name );
    commit('setAccommodationGoogleContryAndCity', accommodation.cuntry_and_city );
    commit('setAccommodationAddress', accommodation.address );
    commit('setAccommodationBookingConfirmation', accommodation.booking_confirmation );
    commit('setAccommodationText', accommodation.text );
    commit('setAccommodationLink', accommodation.link );
    commit('setAccommodationLat', accommodation.latitude );
    commit('setAccommodationLng', accommodation.longtitude );
    commit('setAccommodationCheckIn', accommodation.check_in_date );
    commit('setAccommodationCheckOut', accommodation.check_out_date );
    commit('setAccommodationImage', accommodation.image );
  },
  async deleteTheAccommodation({ rootState, getters, dispatch } ){
    const accommodationId = getters.accommodationFormFromRide.id;
    const { apptoken } = rootState.appauth;
    const response = await api.deleteAccommodation( apptoken,
                                                    accommodationId);
    const rideId = rootState.ride.rideId;
    if( response.data.ServerMassageCode != "success" ){
      dispatch( 'showModalWithParameters',
      { errorNumber : response.data.ServerMassageCode,
        type:'textConverter' 
      } );
      return
    }
    await dispatch('accommodationLoadDefault');
    router.push('/single_ride_with_map/' + rideId );
  },
  async updateTheAccommodation({ rootState, getters, dispatch } ){
    const accommodationId = getters.accommodationFormFromRide.id;
    const rideId = rootState.ride.rideId;
    var accommodation = await dispatch('getupdatedFormaccommodation');
    const { apptoken } = rootState.appauth;
    if (! await dispatch( 'validateAccmmodationInput',  accommodation))
      return
    const response = await api.updateAccommodation(  apptoken,
                                                     accommodationId,
                                                     accommodation);
    if( response.data.ServerMassageCode != "success" ){
      dispatch( 'showModalWithParameters',
      { errorNumber : response.data.ServerMassageCode,
        type:'textConverter' 
      } );
      return
    }
    await dispatch('accommodationLoadDefault');
    router.push('/single_ride_with_map/' + rideId );
  },
  async createAccommodation({ rootState, dispatch } ){
    const rideId = rootState.ride.rideId;
    var accommodation = await dispatch('getupdatedFormaccommodation');
    if (! await dispatch( 'validateAccmmodationInput',  accommodation))
      return
    const { apptoken } = rootState.appauth;
    const response = await api.createAccommodation(  apptoken,
                                                    rideId,
                                                    accommodation);
    if( response.data.ServerMassageCode != "success" ){
      dispatch( 'showModalWithParameters',
      { errorNumber : response.data.ServerMassageCode,
        type:'textConverter' 
      } );
      return
    }
    // TODO we should add popup of secses
    dispatch('accommodationLoadDefault');
    router.push('/single_ride_with_map/' + rideId );

  },
  getupdatedFormaccommodation({state}){
    var accommodation = {};
    accommodation['name'] = state.accommodationName;
    accommodation['address'] = state.accommodationAddress;
    accommodation['bookingConfirmation'] = state.accommodationBookingConfirmation;
    accommodation['text'] = state.accommodationText;
    accommodation['link'] = state.accommodationLink;
    if( state.accommodationCheckIn )
      accommodation['checkIn'] = state.accommodationCheckIn.toString();
    if( state.accommodationCheckOut )
      accommodation['checkOut'] = state.accommodationCheckOut.toString();
    accommodation['lat'] = state.accommodationLat;
    accommodation['lng'] = state.accommodationLng;
    accommodation['contryAndCity'] = state.googleContryAndCity;
    accommodation['image'] = state.accommodationImage;
    accommodation['rate'] = state.accommodationRate;
    return accommodation;
  },
};

const mutations = {
  setAccommodationFormFromRide: (state, accommodationFormFromRide) => {
    state.accommodationFormFromRide = accommodationFormFromRide;
  },
  setBasRideAccommodationFormFromRide: (state, accommodationFormFromBaseRide) => {
    state.accommodationFormFromBaseRide = accommodationFormFromBaseRide;
  },
  setAccommodationName: (state, accommodationName) => {
    state.accommodationName = accommodationName;
  },
  setAccommodationAddress: (state, accommodationAddress) => {
    state.accommodationAddress = accommodationAddress;
  },
  setAccommodationBookingConfirmation: (state, accommodationBookingConfirmation) => {
    state.accommodationBookingConfirmation = accommodationBookingConfirmation;
  },
  setAccommodationText: (state, accommodationText) => {
    state.accommodationText = accommodationText;
  },
  setAccommodationLink: (state, accommodationLink) => {
    state.accommodationLink = accommodationLink;
  },
  setAccommodationCheckOut: (state, accommodationCheckOut) => {
    state.accommodationCheckOut = accommodationCheckOut;
  },
  setAccommodationCheckIn: (state, accommodationCheckIn) => {
    state.accommodationCheckIn = accommodationCheckIn;
  },
  setAccommodationLat: (state, accommodationLat) => {
    state.accommodationLat = accommodationLat;
  },
  setAccommodationLng: (state, accommodationLng) => {
    state.accommodationLng = accommodationLng;
  },
  setAccommodationImage: (state, accommodationImage) => {
    state.accommodationImage = accommodationImage;
  },
  setAccommodationGoogleContryAndCity: (state, googleContryAndCity) => {
    state.googleContryAndCity = googleContryAndCity;
  },
  setAccommodationRate: (state, rate) => {
    state.accommodationRate = rate;
  },
  setAccommodationPreviewImage: (state, accommodationPreviewImage) => {
    state.accommodationPreviewImage = accommodationPreviewImage;
  },
  setAccommodationPlannedDate: (state, plannedDate) => {
    state.accommodationPlannedDate = plannedDate;
  },
  setAccommodationId: (state, attractionId) => {
    state.accommodationId = attractionId;
  },
  setAccommodationMode: (state, mode) => {
    state.accommodationMode = mode;
  },
  setAccommodationErrores: ( state, errores ) => {
    state.accommodationErrores = errores
  }

};

export default {
  state,
  getters,
  actions,
  mutations
};
