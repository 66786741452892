import { render, staticRenderFns } from "./Attraction.vue?vue&type=template&id=93f8a4ea&scoped=true"
import script from "./Attraction.vue?vue&type=script&lang=js"
export * from "./Attraction.vue?vue&type=script&lang=js"
import style0 from "./Attraction.vue?vue&type=style&index=0&id=93f8a4ea&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "93f8a4ea",
  null
  
)

export default component.exports