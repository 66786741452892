<template>
  <div class="home" style="position:relative;">
    <b-container 
      class="py-5 after_single_ride_header"
      >
      <div class="single_ride_sumary">
        
        <b-btn
          pill
          variant="warning"
          class="px-5 py-2 text-white"
          @click="addPoints()">
          הצגת הנקודות
        </b-btn>
        <div class="col-sm-6 width_60">
          <div class="trip-map-one">
             <h1> this is all the serch points:</h1>
             <GlobalMap 
               passed_class="ride_samery_map">
             </GlobalMap>
          </div>
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
import GlobalMap from '@/components/GlobalMap';
import { mapActions, mapGetters } from "vuex";

export default {
  name: "singleMapWithAllSearchOnMap",
  components: {
     GlobalMap,

  },
  computed: mapGetters([]),
  methods:{
    ...mapActions([ 'addAttractionToRide',
                    'updateAllSerchPonitsOnMap' ]),
    addPoints(){
      const filters={'startDate': '07.01.23', 'endtDate': '09.01.23'};
      this.updateAllSerchPonitsOnMap( filters )
    },
  },
};

</script>

<style scoped>

/* derty fix to start the data after the fixed map */
.after_single_ride_header{
  margin-top: 6rem;
}

</style>
