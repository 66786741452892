<template>
  <b-modal hide-footer centered v-model="isVisible">
    <section class="text-center">
      <b-row no-gutters class="justify-content-center">
        <b-col cols="12" class="py-2">
          <div class="text-center mt-2 mb-5">
            <h3 class="font-weight-bold">ליימ תועצמאב םשריה</h3>
          </div>
          <b-form role="form" @submit.prevent="handleSubmit(onSubmit)">
            <div>
              <b-form-input
                v-model="text"
                autocapitalize="none"
                placeholder="ליימ"
                class="border-0 shadow text-right"
                size="lg"
              ></b-form-input>
            </div>
            <div class="mt-3">
              <b-form-input
                v-model="text"
                autocapitalize="none"
                placeholder="אמסיס"
                class="border-0 shadow text-right"
                size="lg"
              ></b-form-input>
            </div>
            <div class="mt-3">
              <b-form-input
                v-model="text"
                autocapitalize="none"
                placeholder="תפסונ םעפ אמסיס"
                class="border-0 shadow text-right"
                size="lg"
              ></b-form-input>
            </div>
            <div class="mt-3">
              <b-form-input
                v-model="text"
                autocapitalize="none"
                placeholder="שמתשמ םש"
                class="border-0 shadow text-right"
                size="lg"
              ></b-form-input>
            </div>

            <div class="mt-3 text-right rtl">
              <label for="remember-me" class="mr-3">
                ןונקתה תא<span class="turquoise"> יתארק</span>
              </label>
              <b-form-checkbox
                style="display: inline-block"
                id="remember-me"
                class="mb-2 mr-sm-2 mb-sm-0"
              ></b-form-checkbox>
            </div>

            <div class="text-center mt-5">
              <b-row class="justify-content-center">
                <b-col lg="9" md="9">
                  <b-btn pill block variant="warning" class="py-3">םשריה</b-btn>
                </b-col>
              </b-row>
            </div>
            <div class="text-center mt-5">
              <b-link @click.prevent="$emit('showmodal', 'login')"
                >ןובשח ךל שי רבכ ?<span class="turquoise">
                  תורבחתה</span
                ></b-link
              >
            </div>
          </b-form>
        </b-col>
      </b-row>
    </section>
  </b-modal>
</template>
<script>
export default {
  props: ["value"],
  data() {
    return {
      text: "",
      model: {
        email: "",
        password: "",
        rememberMe: false,
      },
      validation: false,
    };
  },
  computed: {
    isVisible: {
      set(val) {
        this.$emit("input", val);
      },
      get() {
        return this.value;
      },
    },
  },
  methods: {
    onSubmit() {
      // this will be called only after form is valid. You can do api call here to login
    },
  },
};
</script>

<style>
</style>