<template>
  <div>
    <h2 class="title">add new attraction to the ride</h2>
    <section class="section">
      <div class="container">
        
        <div class="field">
          <label class="label is-large" for="title">שם הפעילות</label>
          <div class="control">
            <input type="title" class="input is-large" id="title"  :value="newAttractionTitle" @input="updateTitle">
          </div>
        </div>

        <input type="file" name="file"  accept="image/*" class="form-control" v-on:change="uploadImage" id="file">

        <div class="field">
          <label class="label is-large" for="address">address:</label>
          <div class="control">
          <input type="address" class="input is-large" id="address"  :value="newAttractionAddress" @input="updateAddress">
          </div>
        </div>

        <div class="field">
          <label class="label is-large" for="text">text:</label>
          <div class="control">
          <input type="text" class="input is-large" id="text"  :value="newAttractionText" @input="updateText">
          </div>
        </div>

        <div class="field">
          <label class="label is-large" for="link">link:</label>
          <div class="control">
          <input type="link" class="input is-large" id="link"  :value="newAttractionLink" @input="updateLink">
          </div>
        </div>

        <div class="field">
          <label class="label is-large" for="lat">lat:</label>
          <div class="control">
          <input type="lat" class="input is-large" id="lat"  :value="newAttractionLat" >
          </div>
        </div>
        <div class="field">
          <label class="label is-large" for="lng">lng:</label>
          <div class="control">
          <input type="lng" class="input is-large" id="lng"  :value="newAttractionLng" >
          </div>
        </div>
        <div>
          <GlobalMap
            @serchPointChange="handleUpdateserchpoint"
            passed_class="single_ride_map" >
          </GlobalMap>
        </div>



        <!-- <div class="field">
          <label class="label is-large" for="image">image:</label>
          <div class="control">
          <input type="image" class="input is-large" id="image"  :value="newAttractionImage" @input="updateImage">
          </div>
        </div> -->

        </div>
        <div v-if="isNewAttractionEditMode" class="control">
          <button  type="button" class="btn btn-primary" @click="this.updateAttraction">עריכת האטרקציה </button>
        </div>
        <div v-else class="control">
          <!-- <a class="button is-large is-primary" @click="this.authenticate">Login</a> -->
          <button type="button" class="btn btn-primary" @click="this.createAttraction">הוספת האטרקציה לטיול</button>
          <!-- <a class="button is-large is-success" @click="register">Register</a> -->

      </div>
    </section>

  </div>
</template>

<script>

import GlobalMap from './GlobalMap';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'CreateAttraction',
  components: {
    GlobalMap
  },
  computed: mapGetters([  'allRide',
                          'email',
                          'password',
                          'newAttractionTitle',
                          'newAttractionAddress',
                          'newAttractionText',
                          'newAttractionLink',
                          'newAttractionLat',
                          'newAttractionLng',
                          'newAttractionImage',
                          'newAttractionpreviewImage',
                          'isNewAttractionEditMode' ]),
  methods: 
  {
    ...mapActions(['register',
                  'authenticate',
                  'validateAndupdateAppToken',
                  'createAttraction',
                  'updateAttraction',
                  'uploadAttractionIfEditMode']),
    uploadImage(e){
                let files = e.target.files || e.dataTransfer.files;
                if (!files.length)
                    return;
                this.createFile(files[0]);
            },
            createFile(file) {
                let reader = new FileReader();
                let vm = this;
                reader.onload = (e) => {
                    vm.file = e.target.result;
                    this.$store.commit('setNewAttractionImage', file);
                };
                reader.readAsDataURL(file);
            },
    handleUpdateserchpoint (place) {
      this.updateLat( place.geometry.location.lat());
      this.updateLng( place.geometry.location.lng());
    },
    updateTitle (e) {
      this.$store.commit('setNewAttractionTitle', e.target.value)
    },
    updateAddress (e) {
      this.$store.commit('setNewAttractionAddress', e.target.value)
    },
    updateText (e) {
      this.$store.commit('setNewAttractionText', e.target.value)
    },
    updateLink (e) {
      this.$store.commit('setNewAttractionLink', e.target.value)
    },
    updateLat (lat) {
      this.$store.commit('setNewAttractionLat', lat)
    },
    updateLng (lng) {
      this.$store.commit('setNewAttractionLng', lng)
    },
    updateImage (e) {
      this.$store.commit('setNewAttractionImage', e.target.value)
    },
    
    register () {
      this.$store.dispatch('register', { email: this.email, password: this.password })
        .then(() => this.$router.push('/'))
    }
  },
  created() {
    this.validateAndupdateAppToken();
    this.uploadAttractionIfEditMode(); 
   }
};
</script>

<style>
   .uploading-image{
     display:flex;
   }
 </style>
