<template>
  <div>
			<div class="trip-brand-section">
				<div class="container">
					<div class="trip-brand-title">
						<h2>בשיתוף עם</h2>
					</div>
					<div class="trip-brand-wrap">
						<div class="trip-brand-item">
							<img 
								src="https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/gui_images%2FbrandSkyScanner.png?alt=media&token=77f6760f-28e6-46b6-8d2a-e5d06db4f939" 
								width="300px"
								height="88.73px"
								alt="brandSkyScannerIcon">
						</div>
						<div class="trip-brand-item">
							<img 
								src="https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/gui_images%2FbrandGoogle.png?alt=media&token=9dbbd9c5-84f1-4657-be7a-558a87930244" 
								width="173px"
								height="61px"
								alt="brandGoogleIcon">
						</div>
						<div class="trip-brand-item">
							<img 
								src="https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/gui_images%2Fbrand3Waze.png?alt=media&token=6617e3ea-3ef4-4e25-b3bb-8cfc805223b4" 
								width="189px"
								height="53px"
								alt="brandWazeIcon">
						</div>
						<div class="trip-brand-item">
							<img 
								src="https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/gui_images%2FbrandBooking.png?alt=media&token=646264dd-1f65-4083-a274-0f702f6eaee3" 
								width="300px"
								height="102.77px"
								alt="brandBookingIcon">
						</div>
					</div>
				</div>
			</div>
  </div>
</template>
<script>
export default {
  name: 'PartnershipSection',
};
</script>
<style>


@media (max-width: 991px) {
  .trip-brand-item {
      flex: 0 0 50%;
  }
  .trip-brand-item img{
      width: 150px;
  }
}


</style>
