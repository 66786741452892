import config from '../common/config';

const state = {
  accommodationIconsByDays: {0:config.config.MAP_POINT_ACCOMMODATION},
};

const getters = {
  accommodationIconsByDays: state => state.accommodationIconsByDays,
};

 const actions = {
  buildAccommodationIcons({getters}){
    const attractionsByDay = getters.allRide.attractionsByDay;
    var accommodationIconsByDays = getters.accommodationIconsByDays;
    for( var dayIndex=0; dayIndex < attractionsByDay.length; dayIndex++ ){
      if(dayIndex == 0 || dayIndex == (attractionsByDay.length - 1) ){
        accommodationIconsByDays[dayIndex] = config.config.MAP_POINT_ACCOMMODATION;  
      }
      else if( "firestDayAtThisAccommodation" in attractionsByDay[dayIndex].accommodation ){
        if( attractionsByDay[dayIndex].accommodation.firestDayAtThisAccommodation ){
          accommodationIconsByDays[dayIndex] = config.config.MAP_POINT_ACCOMMODATION_CHECKIN;
          // accommodationIconsByDays[dayIndex-1] = config.config.MAP_POINT_ACCOMMODATION_CHECKOUT
        }
        else{
          accommodationIconsByDays[dayIndex] = config.config.MAP_POINT_ACCOMMODATION;  
        }
      }
      else if( "firestDayAtThisAccommodation" in attractionsByDay[dayIndex].base_trip_accommodation ){
        if( attractionsByDay[dayIndex].base_trip_accommodation.firestDayAtThisAccommodation ){
          accommodationIconsByDays[dayIndex] = config.config.MAP_POINT_ACCOMMODATION_CHECKIN;
          // accommodationIconsByDays[dayIndex-1] = config.config.MAP_POINT_ACCOMMODATION_CHECKOUT
        }
        else{
          accommodationIconsByDays[dayIndex] = config.config.MAP_POINT_ACCOMMODATION;  
        }
      }
    }
    return config.config.MAP_POINT_ACCOMMODATION;
  },
};

// const mutations = {
//   setToken: (state, token) => {
//     state.token = token;
//   }
// };

export default {
   state,
   getters,
   actions,
//   mutations
};
