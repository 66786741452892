const state = {
  accommodationPresentedDay: null,
};
const getters = {
  accommodationPresentedDay: state => state.accommodationPresentedDay,
};
const actions = {  
  addSerchAccommodationMarker: ({ dispatch, rootGetters}, accommodationIndex ) => {
    const searchAccommodations = rootGetters.searchAccommodations;
    const updateWithThisAttraction = searchAccommodations[accommodationIndex];
    if( ! updateWithThisAttraction ){return;}
    //all the attraction at the day
    var lat = parseFloat(updateWithThisAttraction.latitude);
    var lng = parseFloat(updateWithThisAttraction.longtitude);
    dispatch( 'addMarkerByLatAndLngToGlobalMap',
              { latPoint: lat,
                lngPoint: lng,
                name:updateWithThisAttraction.name,
                bounce: google.maps.Animation.BOUNCE,
                iconUrl: "https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/icons%2Fmap_letter%2Fblack_numbers%2Fblack_map_Icon_empty.png?alt=media&token=ae5de8aa-cee4-47a7-966b-e02eb1b93480"});
  },
  async updateMarkerByScrollerLocationAtAccommodationSearch({dispatch , commit, getters }){
    var serchattractionIndexFocues = await dispatch( 'getTheFocuesDayAtSearchAccommodation' );
    const accommodationPresentedDay = getters.accommodationPresentedDay;
    
    if ( accommodationPresentedDay == serchattractionIndexFocues ){return;}
    await dispatch('removeAllMarkersToGlobalMAp');
    commit( 'setAccommodationPresentedDay', serchattractionIndexFocues );
    dispatch( 'addSerchAccommodationMarker', serchattractionIndexFocues );
    dispatch( 'fitBoundesToMarkersAtGlobalMap');
  },
  getTheFocuesDayAtSearchAccommodation: ({ rootGetters}) => {
    const searchAccommodations = rootGetters.searchAccommodations
    var i;
    for(i=0; i < searchAccommodations.length; i++ ){
      const dailyAttractionItem = document.getElementById('serch_accommodation_index_' + i );
      if( ! dailyAttractionItem ){
        return 0;
      }
      if ( dailyAttractionItem.getBoundingClientRect().y > 200 ){// this is the topest item
        if ( i == 0 ){
          return 0;
        }
        return i-1;
      }
    }
    return searchAccommodations.length - 1;
  },
}
const mutations = {
  setAccommodationPresentedDay( state, dayNumber ) {
    state.accommodationPresentedDay = dayNumber;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
