<template>
  <div>
    <div v-if="allRide.ride" class="btn-toolbar" >
      <div class="add-new-activity-add-icon-sticky"> 
        <div 
          v-if="compareToUserId(allRide.ride.authorId)"
          class="centering"
          >
          <b-btn
            pill
            variant="warning"
            class="single-ride-add-activity-btn single-ride-add-activity-btn_colors centering-with-start"
            @click="goToNewAttraction(serchDayIndex)"
          >
          <div class="add-new-activity-add-icon">
            <p>+</p>
          </div>
          <div class="add-new-activity-add-icon-text">
            יצירת פעילות חדשה
          </div>
          </b-btn>
        </div>
      </div>
    </div>
    <div class="trip-activity-top">
      <h4 v-if="serchAttractiosSorce=='google'" >תוצאות מ  GOOGLE  :</h4>
      <h4 v-if="serchAttractiosSorce=='internal'">תוצאות מטיולים של משפחות אחרות</h4>
    </div>
    <div class="daily-ride-list">
      <div
        v-for="(attraction, index ) in serchAttractios"
        :key="index"
      >
        <div :id="'serch_attractions_index_' + index" >
          <div class="trip-card-wrap">
            <div class="trip-card-header">
              <h4>{{attraction.title}}</h4>
              <div style="position: relative" class="single_ride_star-location">
                <star-rating 
                  v-if="attraction.rate"
                  class="star-custom-text"
                  :read-only="true"
                  :rtl="true"
                  :increment="0.01"
                  :star-size="15"
                  :rating="attraction.rate"
                  />
              </div>
            </div>
            <div class="trip-card-body">
              <div class="trip-card-content">
                <CollapseText 
                  v-if="attraction.text"
                  class="attraction_information"
                  :text="attraction.text"
                  :uniqueId="Math.random()"
                />
                  <div class="trip-card-footer">
                    <a
                      class="trip-card-btn"
                      @click="addAttractionToRideInternal( attraction )"
                      >
                      הוספת אטרקציה
                    </a>
                    <a
                      class="trip-card-btn" 
                      v-if="serchAttractiosSorce=='google'"
                      @click="lowedAttractionAndShow( { 'attractionId': attraction.place_id,
                                                        'sorce':'google' } )"
                    >
                      פרטים נוספים
                    </a>
                    <a
                      class="trip-card-btn" 
                      v-else-if="serchAttractiosSorce=='internal'"
                      @click="lowedAttractionAndShow( { 'attractionId': attraction.id,
                                                        'sorce':'internal' } )"
                      >
                      פרטים נוספים
                    </a>
                    <a 
                      class="trip-card-btn"
                      v-if="attraction.link"
                      @click="goTo( attraction.link )">
                      אתר
                    </a>
                    <a 
                      class="share-icon"
                      v-if="serchAttractiosSorce=='internal'"
                      @click="shareThisAttraction( allRide.ride.id , attraction.id )">
                      <img src="https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/gui_images%2FshareAttraction.svg?alt=media&token=a14011d2-1a93-448f-be7a-47d16071465f"
                        alt="">
                    </a>
                  </div>
              </div>
              <div class="trip-card-thumb-wrap">
                <div class="trip-card-thumb">
                <img
                  v-if="attraction.image"
                  :src="attraction.image"
                  alt="Image"
                  >
                </div>
                <div class="trip-card-thumb-data">
                    <p
                  v-if="attraction.imageHtmlAttributions"
                  v-html="getImageHtmlAttributions( attraction.imageHtmlAttributions[0])">
                </p>
                  </div>
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import CollapseText from "@/components/CollapseText";
import StarRating from 'vue-star-rating'

export default {
  components: {
    CollapseText,
    StarRating
  },

  name: 'SerchAttractions',
  computed: mapGetters([  'serchAttractios',
                          'isAppLoggedIn',
                          'allRide',
                          'serchDayIndex',
                          'plannedDate',
                          'compareToUserId',
                          'serchAttractiosSorce'
                           ]),
  methods: {...mapActions([ 'fetchSerchAttractionsByRide',
                            'addAttractionToRide',
                            'viewAttraction',
                            'validateAndupdateAppToken',
                            'ReturnToSingleRide',
                            'openLoginForm',
                            'addRideToUser',
                            'goToNewAttraction',
                            'lowedAttractionAndShow',
                            'uplowedGoogleAtractionIfEmpty',
                            'showModalWithParameters',
                            ]),
    goTo( link ){
      window.open(link)
      return 
    },
    shareThisAttraction( rideId, attractionId ){
      this.showModalWithParameters( { inerModalParameters : {rideId:rideId, attractionId:attractionId},
                                      type:'shareAttraction' } );      
    },
    addAttractionToRideInternal( attraction ){
      var id;
      if ( this.serchAttractiosSorce == 'internal'){
        id = attraction.id
      }
      else if ( this.serchAttractiosSorce == 'google'){
        id = attraction.place_id
      } 
      this.addAttractionToRide( { 'attractionId': id,
                                  'sorce': this.serchAttractiosSorce } );
    },
    getImageHtmlAttributions( htmlCode ){
      return htmlCode.slice(0,3) + 'target="_blank"' + htmlCode.slice(3)
    }

  },
  created() {
    this.fetchSerchAttractionsByRide();
    this.validateAndupdateAppToken();
  },

  updated(){
    this.uplowedGoogleAtractionIfEmpty();
  },

};
</script>

<style scoped>
</style>
