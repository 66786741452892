
const state = {
  arivedLabel: null,
};

const getters = {
  label: state => state.arivedLabel,
};

const actions = {
  async addLabelIfExists({ state }, url) {
    const label = state.arivedLabel;
    let staticURL = await url
    if( label ){
      const separator = staticURL.includes('?') ? '&' : '?';
      staticURL = staticURL + separator + "label=" + label;
    }
    return staticURL;
  },
};

const mutations = {
  setLabel: (state, query) => {
    const label = Object.entries(query)
    .map(([key, value]) => `${key}=${value}`)
    .join('_');
  
  const previousLabel = state.arivedLabel
    if(previousLabel){
      console.log("the label is alredy seted and canot update to: ", label, " from: ", previousLabel)
    }
    else{
      if(label){
        state.arivedLabel = label;
      }
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
