<template>
  <div v-if="cnvertTobookingID(allRide.ride.based_ride_id)">
    <!-- <h3 class="booking-offers-header" style="font-size: 24px; font-weight: bold; color: #D35400; padding: 20px; background-color: #ECF0F1;">
    הזמינו מלון עכשיו עם ביטול חינם!<br>
    שמרו את המחירים המעולים של היום עם גמישות לשינוי תוכניות.<br>
    <button 
      style="font-size: 20px; color: white; background-color: #E74C3C; border: none; padding: 10px 20px; border-radius: 5px; cursor: pointer;">
        הזמינו מקום מושלם עם שקט נפשי
    </button>
    </h3> -->





    <ins
      class="bookingaff"
      :data-aid=cnvertToDataAid(allRide.ride.based_ride_id)
      :data-target_aid=cnvertToDataTargetAid(allRide.ride.based_ride_id)
      data-prod="dfl2"
      data-width="100%"
      data-height="auto"
      data-lang="he"
      data-currency="ILS"
      :data-dest_id=cnvertToDataDestAid(allRide.ride.based_ride_id)
      :data-dest_type=cnvertToDataDestType(allRide.ride.based_ride_id)
      data-df_checkin="4"
      data-df_duration="3"
      data-df_num_properties="3">
      <a href="//www.booking.com?aid=2283612">Booking.com</a>
    </ins>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

import config from '@/common/config';

export default {
  name: "BookingDiscountLabel",
  computed: mapGetters([
    "allRide",
  ]),
  methods: {
    cnvertToDataAid( rideId ){
      return this.cnvertTobookingID( rideId )["data-aid"]
    },
    cnvertToDataTargetAid( rideId ){
      return this.cnvertTobookingID( rideId )["data-target_aid"]
    },
    cnvertToDataDestAid( rideId ){
      return this.cnvertTobookingID( rideId )["data-dest_id"]
    },
    cnvertToDataDestType( rideId ){
      return this.cnvertTobookingID( rideId )["data-dest_type"]
    },



    // heroku addons:create heroku-postgresql:hobby-basic

    // heroku addons:upgrade HEROKU_POSTGRESQL_LAVENDER_URL heroku-postgresql:hobby-basic -a sushi

// heroku addons:upgrade DATABASE_URL heroku-postgresql:hobby-basic -a tripplanneril


// heroku pg:copy DATABASE_URL HEROKU_POSTGRESQL_SILVER_URL --app tripplanneril
// heroku addons:create heroku-postgresql:hobby-basic --app tripplanneril
//heroku pg:promote HEROKU_POSTGRESQL_SILVER_URL --app tripplanneril

    cnvertTobookingID( rideId ){
      return config.config.RIDE_TO_BOKING_ID_CONVERTOR[rideId]
    }          



 },
  created() {
    (function (d, sc, u) {
      var s = d.createElement(sc),
        p = d.getElementsByTagName(sc)[0];
      s.type = "text/javascript";
      s.async = true;
      s.id = "bookingElement"
      s.src = u + "?v=" + +new Date();
      p.parentNode.insertBefore(s, p);
    })(
      document,
      "script",
      "//aff.bstatic.com/static/affiliate_base/js/flexiproduct.js"
    );
  },
  mounted() {
    // this is a very ugly work arownd to add title to the booking iframe for ther SEO grade      
    setTimeout(() => {
      const iframes = document.getElementsByTagName('iframe');
      for (let i = 0; i < iframes.length; i++) {
        if (iframes[i].src.includes("booking.com")) {  // Check for the booking widget's domain
          iframes[i].title = "Booking widget description";
        }
      }
    }, 1000);
  },
   destroyed() {
    while( window.document.getElementById("bookingElement") ){
      var bookingElement = window.document.getElementById("bookingElement");
      bookingElement.remove();
    }

    while( window.BookingAff )
      delete window.BookingAff;

    
  }
};
</script>

<style scoped>
</style>