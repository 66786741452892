<template>
  <div class="about" style="position: relative">
   <SingleRideHeader
      presentedText="מחיקת משתמש"
    />
    <b-container>
      <div class="trip-about-section after_page_header">
        <div class="container">
          <div class="row">
            <!-- <div class="col-lg-7"> -->
              <div class="trip-about-content">
                <h1>
                  מחיקת משתמש
                </h1> 
                <h4>
                  כדי למחוק משתמש יש לשלוח מייל 
                avshalom@tripplanner.co.il
                ולבקש להוריד את הפרטים שלכם
                </h4>
              </div>
            <!-- </div>             -->
          </div>
        </div>
      </div>
      <!-- End about section -->

      <div class="trip-cta-section">
        <div class="container">
          <div class="trip-cta-wrap">
            <h2>פרטי התקשרות</h2>
            <div class="trip-cta-menu">
              <ul>
                <li><a href="">טלפון : 052-686-7915 </a></li>
                <li>|</li>
                <li><a href="">אימייל:     avshalom@tripplanner.co.il </a></li>
                <li>|</li>
                <li><a href="">Salit 4588500  כתובת: </a></li>
              </ul>
            </div>
            <div class="trip-social">
              <ul>
                <li><a href=""><img src="https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/gui_images%2Fsocial-icon-telegram.svg?alt=media&token=21ecf836-710c-4fba-a69b-c74ca59ec7a5" alt=""></a></li>
                <li><a href=""><img src="https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/gui_images%2Fsocial-icon-whatsapp.svg?alt=media&token=7f535993-bc69-4a53-b764-44c4dce0217d" alt=""></a></li>
                <li><a href=""><img src="https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/gui_images%2Fsocial-icon-face-book.svg?alt=media&token=a3b98c59-95e9-4cb5-a6dc-04f1236d9e93" alt=""></a></li>
                <li><a href=""><img src="https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/gui_images%2Fsocial-icon-mail.svg?alt=media&token=544ee52a-6eb3-4f89-9900-25560e7f6e98" alt=""></a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    
    </b-container>
    <PageFooter />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import PageFooter from "@/components/PageFooter.vue";
import SingleRideHeader from "@/components/SingleRideHeader";

export default {
  name: "DeleteUser",
  components: {
    PageFooter,
    SingleRideHeader,    
  },
  computed: mapGetters(['globalRides', 'userId' ]),
  methods: {}
}
</script>

<style scoped>

.after_page_header{
  margin-top: 20rem;
}
@media (max-width: 991px) {
  .after_page_header{
    margin-top: 15rem;
  }
}

/* about page */
.trip-hero-section.about-page {
  padding: 120px 0 60px;
}

.trip-about-section {
  padding: 0rem 1rem 90px;
}
.trip-about-thumb {
  position: sticky;
  top: 150px;
}
.rounded-image {
  border-radius: 1.5rem;
}
.trip-about-content {
  text-align: right;
  margin-left: 64px;
}
.trip-about-content p{
  font-size: 18px;
  line-height: 24px;
  color: #614646;
}
.trip-cta-section {
  padding-bottom: 90px;
}
.trip-cta-wrap {
  background: rgba(7, 160, 195, 0.1);
  border-radius: 22px;
  padding: 25px;
  text-align: center;
}
.trip-cta-wrap h2{
  font-weight: 600;
  font-size: 40px;
  color: #0D2F29;
  margin-bottom: 40px;
}
.trip-cta-menu {
  margin-bottom: 55px;
}
.trip-cta-menu ul li{
  margin: 0 5px;
  display: inline-block;
}
.trip-cta-menu ul li a{
  font-weight: 600;
  font-size: 24px;
  color: #0D2F29;
}

.trip-social ul li{
  margin: 0 35px;
  display: inline-block;
}
.trip-social ul li:first-child{
  margin-left: 0;
}
.trip-social ul li:last-child{
  margin-right: 0;
}
</style>
