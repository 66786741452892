<script>

export default {
  props: {
    callBack: {
      type: Function,
      required: true
    },
    locationData: {
      type: Object,
      required: true
    },
  }
};
</script>

<template>
  <span 
    class="rounded-pill px-3 py-2 bg-light shadow-sm ml-2 mb-2"
    @click="callBack"
  >
    {{locationData.displayName.text}}
  </span>
</template>

<style scoped>

</style>