import axios from 'axios';
import config from '../common/config';

export default {
  fetchUserExtraInformationByRideID(token, rideID) {
    return axios.get(`${config.config.ROOT_URL}/user_info_from_ride_id_api/${rideID}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  },
};
