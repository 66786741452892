<template>
  <div>
    <div class="daily-ride-list">
      <div
        v-for="(accommodation, index ) in searchAccommodations"
        :key="index"
      >
        <div :id="'serch_accommodation_index_' + index" >
            <!-- this is the accomodation part -->
            <div
              :id="(accommodation.id + '_acommodation_ref_id')"
              class="trip-card-wrap accommodationBorder"
              v-if="( Object.keys( accommodation ).length )" >
              <div class="trip-card-header desktop">
                <h4 
                v-if="accommodation.name">
                  {{accommodation.name}}
                </h4>
                <!-- <div class="single_ride_star-location">
                  <star-rating 
                    v-if="accommodation.rate"
                    class="star-custom-text"
                    :read-only="true"
                    :rtl="true"
                    :increment="0.01"
                    :star-size="15"
                    :rating="accommodation.rate"
                    />
                </div> -->
              </div>
              <div class="trip-card-body">
                <div class="trip-card-thumb">
                  <img 
                  v-if="accommodation.image"
                  :src="accommodation.image"
                  alt="Image">
                </div>
                <div class="trip-card-content">
                  <div class="trip-card-header responsive">
                <h4 
                v-if="accommodation.name">
                  לינה: {{accommodation.name}}
                </h4>
                <!-- <div class="single_ride_star-location">
                  <star-rating 
                    v-if="accommodation.rate"
                    class="star-custom-text"
                    :read-only="true"
                    :rtl="true"
                    :increment="0.01"
                    :star-size="15"
                    :rating="accommodation.rate"
                    />
                </div> -->
              </div>
                  <p
                  v-if="accommodation.cuntry_and_city"
                  >{{accommodation.cuntry_and_city}}</p>
                  <CollapseText
                  v-if="accommodation.text"
                  class="accommodation_information"
                  :text="accommodation.text"
                  :uniqueId="Math.random()"
                  />
                  <!-- this part should not be an form, it should be an presented information -->
                    <div class="trip-card-footer">
                      <div class="trip-card-footer-top">
                        <button class="share-icon" type="button"
                        data-bs-toggle="modal" data-bs-target="#modalonetwo"
                        v-if="accommodation.link"
                        @click="goToBokingWithFaceBookQuery( addLabelIfExists( addhttp( accommodation.link ) ))">
                          <div class="trip-card-booking-icon">
                            <img src="https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/icons%2Fbookingcom%2FbookingcomiconB100.png?alt=media&token=3fd0b4ed-4612-42f7-b55b-bee015537b31" alt="">
                          </div>
                        </button>
                        <button class="trip-modal" type="button"
                        data-bs-toggle="modal" data-bs-target="#modalthree"
                        @click="goToRide(accommodation.ride_id)">
                          צפיה בטיול
                        </button>

                        <button class="trip-modal" type="button"
                        data-bs-toggle="modal" data-bs-target="#modalthree"
                        v-if="accommodation.link"
                        @click="goToBokingDotCom( accommodation )">
                          מלונות נוספים באיזור
                        </button>
                      </div>
                    </div>
                </div>
              </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import CollapseText from "@/components/CollapseText";

export default {
  components: {
    CollapseText,
  },

  name: 'SearchAccommodations',
  computed: mapGetters([  'searchAccommodations',
                          'allRide',
                          'serchDayIndex',
                          'compareToUserId',
                           ]),
  methods: {...mapActions([ 
                            'goToNewAttraction',
                            'showModalWithParameters',
                            'addLabelIfExists',
                            'addhttp',
                            "goToBokingDotCom",
                            ]),
    async goToBokingWithFaceBookQuery( link ){
      if (window.fbq) {
        window.fbq('track', 'Lead');
        window.fbq('track', 'gooToBooking');

      }
      const staticLink = await link;
      window.open(staticLink)
      return 
    },
    goToRide( rideId ) {
      this.$router.push('/single_ride_with_map/'+ rideId)
    },


  },
};
</script>

<style scoped>
</style>
