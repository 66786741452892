<template>
  <div>
    <div class="whatsapp-icon-container-with map-container fix_iteam_right_doun_for_mobile">
      <a @click="retutnToSingleRideWithMap()" class="map-link">
        <img 
          class="map-icon"
          :src="require('@/assets/images/icons/arrow-left-circle-fill.svg')"
          alt="Close Map"
          >
        <span>חזרה</span>
      </a>
    </div>
    <div class="full_size_map">
      <SingleRideMap />
    </div>
  </div>
</template>

<script>
import SingleRideMap from "@/components/SingleRideMap";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "FullScreenSingleRideMap",
  components: {
    SingleRideMap,
  },

  computed: mapGetters([  'allRide' ]),
  
  methods:{
    ...mapActions([ 'rideUpdateMarkerByDayIndex' ]),
    retutnToSingleRideWithMap() {
      const rideId = this.$route.params.id;
      this.$router.push('/single_ride_with_map/' + rideId )
    },
  },
  created() {
    const rideIdFromUrl = this.$route.params.id;
    if (rideIdFromUrl) {
      this.$store.commit("setLowedRideId", rideIdFromUrl);
    }
    const dayId = this.$route.params.dayId;
    this.rideUpdateMarkerByDayIndex( dayId );
  },
};

</script>

<style scoped>
</style>
