<template>
  <div class="trip-card-bottom">
    <div class="row">
      <div class="col-lg-6 col-md-6">
        <div class="trip-card2-wrap">
          <div class="trip-card2-thumb">
            <img 
              src="https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/gui_images%2Fgoogle-phone.png?alt=media&token=9c742f37-6095-42a9-a8fc-da28b616e9e9"
              @click="fetchSerchAttractionsByRide( 'google' )">
          </div>
          <div class="trip-card2-data">
            <h3>פעילויות מומלצות מ Google </h3>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-6">
        <div class="trip-card2-wrap">
          <div class="trip-card2-thumb">
            <img 
              src="https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/gui_images%2Ffamaly-at-forest.png?alt=media&token=304fe349-36fd-4458-b78f-4844c4fd135c"
              @click="fetchSerchAttractionsByRide( 'internal' )"
              >
          </div>
          <div class="trip-card2-data">
            <h3>פעילויות מומלצות מטיולים אחרים </h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    methods: {...mapActions([ 'fetchSerchAttractionsByRide' ]),    
    }
}
</script>


<style scoped>
</style>