import api from '../../api/presentUserInfo';

const state = {
  userExtraInformation: null,
};

const getters = {
  userExtraInformation: state => state.userExtraInformation,
};

const actions = {
  async fetchUserExtarInformationForRide({ rootState, commit }) {
    const {ride} = rootState;
    const { apptoken } = rootState.appauth;
    const response = await api.fetchUserExtraInformationByRideID (apptoken, ride.lowedRideId);
    if(response.statusText == "OK"){
      commit('setUserInfo', response.data);
    }
    else{
      commit('clearUserInfo');
    }
  },
  cleerUserExtarInformationForRide( {commit} ){
    commit('clearUserInfo');
  },

};

const mutations = {
  setUserInfo: (state, userExtraInformation) => {
    if( userExtraInformation.user_id){
      state.userExtraInformation = userExtraInformation

    }
    else{
      state.userExtraInformation = null;
    }
  },
  clearUserInfo: (state) => {
    state.userExtraInformation = null;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
