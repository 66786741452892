<template>
  <div class="shadow-sm py-2">
    <!-- visible on lg and up -->
    <b-container>
      <b-row no-gutters>
        <b-col>
          <b-nav v-if="allRide.ride" fill align="center">
            <b-nav-item
              exact
              active-class="active"
              class="link"
              v-for="(link, index) in links"
              @click="tubCliked(link.modal)"
              :key="index"
              :to="getUrl( link.url )"
              >
              <div class="single_ride_internal_nav_bar">
                {{ link.text }}
                <b-icon :icon="link.icon" variant="warning"></b-icon>
              </div>
            </b-nav-item>
          </b-nav>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>


<script>

import { mapActions, mapGetters } from 'vuex';

export default {
  props: {
    links: {
      type: Array,
      default: () => {
        return [
          {
            text: "",
            icon: "share",
            modal:"shareRide",
          },
          {
            text: "מקומות לינה",
            icon: "",
            url: "/ride_checklist/",
          },
          // {
          //   text: "הוספת פעילות",
          //   icon: "",
          // },
          {
            text: "האטרקציות של הטיול",
            icon: "",
            url: "/single_ride_with_map/",
          },
        ];
      },
    },
    mobileLinks: {
      type: Array,
      default: () => {
        return [
          {
            text: "שיתוף הטיול",
            icon: "icons8-connect-50.png",
            modal:"shareRide",
          },
          // {
          //   text: "הוספת פעילות",
          //   icon: "",
          // },
          {
            text: "האטרקציות של הטיול",
            icon: "",
            url: "/single_ride_with_map/",
          },
          {
            text: "מקומות לינה",
            icon: "",
            url: "/ride_checklist/",
          },
        ];
      },
    },
  },
  computed: mapGetters([  
    'allRide',
    "modals",
  ]),
  methods: {
    ...mapActions([
      "showModal",
    ]),
    getUrl( url ){
      if( url )
        return url + this.allRide.ride.id.toString();
    },
    tubCliked(modal) {
      if ( modal == '' ){
        return
      }
      this.showModal( modal )
    },
  }
};
</script>
<style>
.active {
  font-style: normal;
  font-weight: normal;
  color: #34d0b6;
}
</style>