<template>
  <div>
    <b-modal
      id="LoadingIconWithUploadStatus"
      modal-class="modal-cover"
      v-model="isVisible"
      hide-backdrop
      hide-footer
      content-class="shadow-lg"
      centered
    >
      <div class="text-center">
        <h3 class="h4 font-weight-bold">מעלה תמונות</h3>
        <span class="btn btn-primary px-3 py-2 rounded-pill">
          {{this.tripFromImagesUploadStatus.uploadedFiles + 1}} מתוך {{this.tripFromImagesUploadStatus.totalFiles}}
          </span>
        <div class="mt-3">
        <div
          class="spinner-border ml-auto"
          role="status"
        ></div>
        </div>
      </div>    
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";


export default {
  props: ["value"],
  computed: {
    ...mapGetters(['tripFromImagesUploadStatus' ]),
    isVisible: {
      set(val) {
        this.$emit("input", val);
      },
      get() {
        return this.value;
      },
    },
  },
};
</script>

<style scoped>
.modal.modal-cover .modal-content,
.modal.modal-cover .modal-footer,
.modal.modal-cover .modal-header {
  border: none;
}
.height-50 {
  height: 50px !important;
}
.width-50 {
  width: 50px !important;
}
</style>