import axios from 'axios';
import config from '../common/config';

export default {
  getRideInformation( token, rideInformationId ) {
    return axios.get(`${config.config.ROOT_URL}/ride_information_api/${rideInformationId}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  },
  updateRideInformation( token, rideInformationId, rideInformationInfo){
    const formData = new FormData()
    formData.append('data', JSON.stringify( rideInformationInfo ) );
    
    return axios.post(`${config.config.ROOT_URL}/update_ride_information_api/${rideInformationId}`,formData, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'content-type': 'multipart/form-data'        }
    });
  },
  setRideInformation( token, rideInformationInfo){
    const formData = new FormData()
    formData.append('data', JSON.stringify( rideInformationInfo ) );
    return axios.post(`${config.config.ROOT_URL}/add_ride_api`,formData, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'content-type': 'multipart/form-data'        }
    });
  },
};
