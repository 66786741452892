import config from '../../common/config';

const state = {
  rideCenter: { lat: 45.508, lng: 40.587 },
  rideMarkers: [],
  places: [],
  rideCurrentPlace: null,
  rideAppMap: null,
  ridePresentedDay: null,

};
const getters = {
  rideCenter: state => state.rideCenter,
  rideMarkers: state => state.rideMarkers,
  places: state => state.places,
  rideCurrentPlace: state => state.rideCurrentPlace,
  rideAppMap: state => state.rideAppMap,
  ridePresentedDay: state => state.ridePresentedDay,
};
const actions = {
  rideAddMarkerByLatAndLng: ({dispatch, getters }, point) => {
    const rideAppMap = getters.rideAppMap;
    const rideMarkers = getters.rideMarkers;
    if( rideAppMap ){
      dispatch( 'addMarkerByLatAndLng', { 
        map:rideAppMap,
        rideMarkers: rideMarkers,
        point:point
       });
      }
  },
  AddAccommodationMarkerByIndex: ({ dispatch, getters }, accommodationParameters ) => {
    const daylyAttraction = getters.allRide.attractionsByDay[ accommodationParameters.dayIndex ];
    var evningAccommodation = null
    if ( Object.keys( daylyAttraction.accommodation ).length ){
      evningAccommodation = daylyAttraction.accommodation
    }
    else if ( Object.keys( daylyAttraction.base_trip_accommodation ).length ){
      evningAccommodation = daylyAttraction.base_trip_accommodation
    }
    if ( evningAccommodation ){
      var lat = parseFloat( evningAccommodation.latitude);
      var lng = parseFloat( evningAccommodation.longtitude);
      if (lat && lng ){
        dispatch( 'rideAddMarkerByLatAndLng', { latPoint: lat,
                                                lngPoint: lng,
                                                name:accommodationParameters.name,
                                                iconUrl: accommodationParameters.iconUrl });
      }
    }  
  },
  async rideUpdateMarkerOfDay({ dispatch, getters}, dayIndex ){
    const daylyAttraction = getters.allRide.attractionsByDay[dayIndex];
    var i;
    if( ! daylyAttraction ){return;}
    //all the attraction at the day
    for(i=0; i < daylyAttraction.daily_attractions.length; i++ ){
      var lat = parseFloat(daylyAttraction.daily_attractions[i].latitude);
      var lng = parseFloat(daylyAttraction.daily_attractions[i].longtitude);
      var iconUrl;
      if( i < config.config.BLUE_MAP_POINT_WITH_NUMBERS.length )
        iconUrl = config.config.BLUE_MAP_POINT_WITH_NUMBERS[i].IMAGE_URL;
      else
        iconUrl = config.config.BLUE_MAP_POINT_WITH_NUMBERS[ config.config.BLUE_MAP_POINT_WITH_NUMBERS.length - 1 ].IMAGE_URL;
      if( daylyAttraction.daily_attractions[i].iconUrl )
        iconUrl = daylyAttraction.daily_attractions[i].iconUrl
      dispatch( 'rideAddMarkerByLatAndLng', { latPoint: lat,
                                              lngPoint: lng,
                                              name:daylyAttraction.daily_attractions[i].title,
                                              iconUrl: iconUrl});
    }
    const shouldPresentingTheAccommodationOfDayBefor = await dispatch( 'shouldPresentTheHoteOFTheDayBefor', dayIndex );
    const accommodationIconAtNight = await dispatch( 'accommodationIcon', dayIndex );
    dispatch( 'AddAccommodationMarkerByIndex', {  dayIndex: dayIndex,
                                                  iconUrl: accommodationIconAtNight});
    if( shouldPresentingTheAccommodationOfDayBefor ){
      dispatch( 'AddAccommodationMarkerByIndex', {  dayIndex: dayIndex-1,
                                                    iconUrl: config.config.MAP_POINT_ACCOMMODATION_CHECKOUT});
    }
  },
  rideRemoveAllMarkers: ({dispatch, getters}) =>{
    dispatch( 'removeAllMarkers', getters.rideMarkers );

  },
  async accommodationIcon({ getters}, dayIndex){
    const accommodationIconsByDays = getters.accommodationIconsByDays;
    return accommodationIconsByDays[dayIndex]
  },
  async shouldPresentTheHoteOFTheDayBefor({getters}, dayIndex){
    if( dayIndex == 0 )
      return false;
    const daylyAttraction = getters.allRide.attractionsByDay[dayIndex];
    if( Object.keys( daylyAttraction.accommodation ).length )
      return daylyAttraction.accommodation.firestDayAtThisAccommodation
    if( Object.keys( daylyAttraction.base_trip_accommodation ).length )
      return daylyAttraction.base_trip_accommodation.firestDayAtThisAccommodation
    return true
  },
  async rideUpdateMarkerByDayIndex({dispatch ,commit },dayIndex){
    var dayToUpdate = await dispatch( 'rideGetTheFocuesDay' );// work around to wait for the map to be load ( probably )
    dayToUpdate = dayIndex
    await dispatch('rideRemoveAllMarkers');// work around to wait for the map to be load ( probably )
    commit( 'rideSetPresentedDay', dayToUpdate );
    await dispatch( 'rideUpdateMarkerOfDay', dayToUpdate );
    dispatch( 'rideFitBoundesToMarkers');
  },
  async rideUpdateMarkerByScrollerLocation({dispatch , commit, getters }, forceUpadate = false){
    const isItMobile = getters.isMobile;
    if ( ( isItMobile ) && ( forceUpadate != true ) ){
      return;
    }
    var dayToUpdate = await dispatch( 'rideGetTheFocuesDay' );
    const ridePresentedDay = await getters.ridePresentedDay;
    if ( ( ridePresentedDay == dayToUpdate ) && ( forceUpadate != true ) )return;
    await dispatch('rideRemoveAllMarkers');
    commit( 'rideSetPresentedDay', dayToUpdate );
    // TODO we should remove this and help the application to load the map at the first day upload after refresh
    if( ! getters.allRide.attractionsByDay ){return;}
    await dispatch( 'rideUpdateMarkerOfDay', dayToUpdate );
    dispatch( 'rideFitBoundesToMarkers');
    
  },
  async rideGetTheFocuesDay({getters}){
    const daylyAttraction = await getters.allRide.attractionsByDay;
    if( ! daylyAttraction ){return 1000;}// in order to update at the first time that the map is lowded
    var i;
    for(i=0; i < daylyAttraction.length; i++ ){
      const dailyAttractionItem = document.getElementById('daily_attraction_day_' + i );
      if( ! dailyAttractionItem ){
        return 0;
      }
      if ( dailyAttractionItem.getBoundingClientRect().y > 210 ){// this is the topest item at the page
        if ( i == 0 ){
          return 0;
        }
        return i-1;
      }
    }
    return daylyAttraction.length - 1;
  },

  rideFitBoundesToMarkers: ({getters, dispatch}) => {
    const rideAppMap = getters.rideAppMap;
    const rideMarkers = getters.rideMarkers;
    if( rideAppMap ){
      dispatch( 'fitBoundesToMarkers', { 
       appMap:rideAppMap,
       markers: rideMarkers
       });
      }
    },
  rideAddMarker: ({state, commit }) => {
    if (state.rideCurrentPlace) {
      const marker = {
        lat: state.rideCurrentPlace.geometry.location.lat(),
        lng: state.rideCurrentPlace.geometry.location.lng()
      };
      commit( 'rideAddMarker', marker );
      commit( 'addRidePlace', state.rideCurrentPlace );
      commit( 'setrideCenter', marker);
      commit( 'ridesetCurrentPlace', null);
    }
  },
}
const mutations = {
  ridesetCurrentPlace( state, place ) {
    state.rideCurrentPlace = place;
  },
  rideSetAppMap( state, rideAppMap ) {
    state.rideAppMap = rideAppMap;
    // const forceMapUpdate = true;
    // this.dispatch( 'rideUpdateMarkerByScrollerLocation', forceMapUpdate );
  },
  rideAddMarker( state, marker ) {
    state.rideMarkers.push( marker );
  },
  cleanRideMarkers( state ) {
    state.rideMarkers = [];
  },
  addRidePlace( state, place ) {
    state.places.push( { place } );
  },
  setrideCenter( state, rideCenter ) {
    state.rideCenter =  rideCenter;
  },
  rideSetPresentedDay( state, dayNumber ) {
    state.ridePresentedDay = dayNumber;
  },
  rideSetPresentedDayIndex( state, map ){
    state.rideListMaps.push( map );
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
