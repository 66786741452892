<template>
  <div>
    <WhatsappChet />
    <SingleRideHeader presentedText="הוספת טיול חדש" />

    <div class="shifting-down centering">
      <div class="align_the_text">
        <h1>שיתוף טיול</h1>
        <h2>
          אנו שמחים שבחרתם לעזור לקהילה שלנו לתכנן טיול ליעד נוסף ע"י הוספת מסלול הטיול שלכם.
          <br>
          הוספת מסלול לאתר היא פעולה פשוטה שנעשית בשלושה שלבים.
        </h2>
        <h4>
          1. יוצרים אלבום תמונות עם תמונה אחת מכל מקום בטיול - 
          <a href="https://youtu.be/iXaEyBidYZY" target="_blank">סרטון הסבר</a>
        </h4>
        <h4>
          2. משתפים את האלבום עם המיקום דרך אימייל: avshalom@tripplanner.co.il או ב -
          <a href="https://wa.me/972526867915" target="_blank" rel="noopener noreferrer">WhatsApp</a>
        </h4>
        <h4>
          3. הטיול יווצר באופן אוטומטי ואתם תוכלו להוסיף את החוויות שלכם
        </h4>
      </div>
      <div class="button-group">
        <div v-if="this.$route.params.ride_id">
          <!-- at thios part we should add the add new ride page -->
          <button type="button" class="btn btn-primary btn-green" @click="goToRide()">המשיכו לטיול שלכם</button>
        </div> 
        <div v-else>
          <button type="button" class="btn btn-primary btn-green" @click="internalOpenRideInformationPage()">שיתוף טיול</button>
        </div> 
        <div>
          <!-- at thios part we should add the add new ride page -->
          <button type="button" class="btn btn-primary " @click="goBack()">חזרה</button>
        </div> 
      </div>
    </div>

    <PageFooter />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import SingleRideHeader from "@/components/SingleRideHeader";
import PageFooter from "@/components/PageFooter.vue";
import WhatsappChet from "@/components/WhatsappChet";

export default {
  name: 'InsertNewRideFromGooglePhotos',
  components: {
    SingleRideHeader,
    PageFooter,
    WhatsappChet
  },
  methods: {
    ...mapActions(["openRideInformationPage",]),
    goToRide() {
      this.$router.push('/single_ride_with_map/' + this.$route.params.ride_id);
    },
    internalOpenRideInformationPage(){
      this.openRideInformationPage()
    },
    goBack() {
      this.$router.go(-1); // This will take the user back to the previous page
    },
  },
}
</script>

<style scoped>
  .btn {
    font-size: 1.2em; /* Increase the font size */
    padding: 10px 20px; /* Increase padding for larger button size */
    border-radius: 5px; /* Optional: Adjust the border-radius for rounded corners */
    /* Add any other styling as needed */
  }
  .button-group {
    display: flex; /* Aligns children (buttons) in a row */
    justify-content: center; /* Centers the buttons horizontally */
    gap: 10px; /* Adds some space between the buttons */
  }
  h1 {
    font-size: 2em; /* Adjust as needed */
    font-weight: bold;
  }

  h2 {
    font-size: 1.5em; /* Slightly smaller than h1 */
  }

  h4 {
    font-size: 1.2em; /* Smaller than h2 */
  }

  .shifting-down {
    padding: 170px 0 50px;
  }

  .align_the_text {
    padding: 0em 1.5em;
    text-align: right;
    font-size: 14px;
  }

  .centering {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .btn-green {
    background-color: green; /* Adjust the shade of green as needed */
    color: white; /* Change text color if needed */
  }
   .btn-red {
    background-color: red; /* Adjust the shade of green as needed */
    color: white; /* Change text color if needed */
  }

</style>