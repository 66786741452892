<template>
  <div>
    <b-modal
      id="pleaseAddTheRideToYourList"
      modal-class="modal-cover"
      v-model="isVisible"
      hide-backdrop
      hide-footer
      content-class="shadow-lg"
    >
      <section class="text-center">
        <b-row>
          <b-col cols="12" class="py-2">
            <h3 class="font-weight-medium card-title">
              כדי שנוכל לשמור לכם את השינוים 
            </h3>
          </b-col>

          <b-col cols="12" class="py-2">
            <p>
              יש להוסיף את הטיול לרשימת הטיולים שלי
            </p>
          </b-col>

          <b-col cols="12" class="py-2">
            <b-btn
              pill
              variant="warning"
              class="px-5 py-2 text-white"
              @click="internalAddRideToUser(allRide.ride.id)"
              >הוספת טיול זה לטיול שלי</b-btn
            >
          </b-col>
        </b-row>
      </section>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  props: ["value"],
  
  methods: { 
    ...mapActions([ 'addRideToUser',
                    'showModal',
                    ]),
    internalAddRideToUser( rideId ){
      this.showModal('')
      this.addRideToUser( rideId );
    },
  },
  computed: {
    ...mapGetters(['allRide' ]),
    isVisible: {
      set(val) {
        this.$emit("input", val);
      },
      get() {
        return this.value;
      },
    },
  },
};
</script>



<style scoped>
.modal.modal-cover .modal-content,
.modal.modal-cover .modal-footer,
.modal.modal-cover .modal-header {
  border: none;
}
.height-50 {
  height: 50px !important;
}
.width-50 {
  width: 50px !important;
}
</style>