import axios from 'axios';

import config from '../common/config';

export default {
  fetchSerchAttractionsByRide(token, rideId, lat, lng) {
    return axios.get(`${config.config.ROOT_URL}/serch_attractions_by_location_api/${rideId}/${lat}/${lng}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  },
  fetchSerchActivityByRideFromGoogle(token, rideId, lat, lng, serchType) {
    return axios.get(`${config.config.ROOT_URL}/serch_activity_by_location_with_google_api/${rideId}/${lat}/${lng}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        Data: serchType
      }
    });
  },

};
