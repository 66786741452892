<template>
  <div class="whatsapp-icon-container-with whatsapp-container">
    <a 
      href="https://wa.me/972526867915"
      target="_blank"
      rel="noopener noreferrer">
      <img 
        src="https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/gui_images%2Ficons%2Fwhatsapp_50_50_green.png?alt=media&token=cdbb01a1-025c-4e1e-9ee1-bfb11525135f&_gl=1*nehect*_ga*MjA0MDE0NjExOC4xNjYzMzMzODA5*_ga_CW55HF8NVT*MTY5NjQ5MTI3NC4zNy4xLjE2OTY0OTMzOTAuNjAuMC4w" 
        alt="WhatsApp Contact"
        width="512px"
        height="512px">
      <span>אני כאן בשבילכם</span>
    </a>
  </div>
</template>



<script >
export default {
    name: 'WhatsappChet'
}
</script>

<style scoped>

.whatsapp-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 999;
}

.whatsapp-container a {
  display: flex; /* Changed to flex to align icon and text */
  align-items: center; /* Align items vertically */
  background-color: #25d366;
  padding: 10px;
  border-radius: 10px; /* Slightly rounded corners */
  text-decoration: none; /* Remove underline from text */
}

.whatsapp-container img {
  width: 30px;
  height: 30px;
  margin-left: 10px; /* Add space between icon and text */
}

.whatsapp-container span {
  color: white; /* Text color */
  font-size: 14px; /* Adjust as needed */
}

</style>
