<template>
  <div>
    <br>
    <gmap-map
      ref="mapRef"
      :center="center"
      :zoom="12"
      :class="[getClass()]"
      @click="handleMapClick"
    >
    </gmap-map>

  </div>
</template>


<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'GlobalMap',
  props: ['passed_class',
          'zoomLevel',
          ],

  computed: mapGetters(['markers', 'center']),
  methods: 
  {
    ...mapActions([ 'geolocate',
                    'setAppMap',
                    'fetchSerchAttractionsByRide',
                    'setOneMarker',
                    'showModalWithParameters',
                    ]),
    getClass(){
      return this.passed_class
    },
    
    handleMapClick(event) {
      if (this.$listeners.userPressedOnMap) {
        this.$emit('userPressedOnMap', event);
      } else {
        console.log('No callback provided for userPressedOnMap event.');
      }
    },
  },
  mounted(){
    this.$refs.mapRef.$mapPromise.then((map) =>{
      this.$store.commit('setAppMap', map)
      if (this.zoomLevel) {
        map.setZoom(this.zoomLevel);
      }

    });    
  }
};
</script>


<style scoped >

.single_ride_map{
  position: relative;
}
.the_old_one_single_ride_map_temp{
  width: 30rem;
  height: 30rem;
  /* border: 1px solid rgba(0, 0, 0, 0.4); */
  box-sizing: border-box;
  position: relative;
  z-index: 0;
}

.single_ride_map_temp {
    width: 100%;
    height: 100%;
    position: absolute; /* Or relative if needed for stacking context */
    top: 0;
    left: 0;
    box-sizing: border-box;
    z-index: 0; /* Ensure the map is at the correct layer */
}
</style>
