<template>
  <div>
    <h2 class="title">פרטי טיול</h2>
    <section class="section">
      <div class="container">
        
        <div class="field">
          <label class="label is-large" for="title">כותרת הטיול</label>
          <div class="control">
            <input type="title" class="input is-large" id="title"  :value="rideInformationTitle" @input="updateTitle">
          </div>
        </div>

        <div class="field">
          <label class="label is-large" for="text">טקסט חופשי על הטיול</label>
          <div class="control">
          <input type="text" class="input is-large" id="text"  :value="rideInformationText" @input="updateText">
          </div>
        </div>

        <div class="field">
          <label class="label is-large" for="number_of_adoult">מספר המבוגרים</label>
          <div class="control">
          <input type="text" class="input is-large" id="text"  :value="rideInformationNumbrOfAdoult" @input="updateNumbrOfAdoult">
          </div>
        </div>

        <div class="field">
          <label class="label is-large" for="number_of_teen">מספר הנערים</label>
          <div class="control">
          <input type="text" class="input is-large" id="text"  :value="rideInformationNumbrOfTeen" @input="updateNumbrOfTeen">
          </div>
        </div>

        <div class="field">
          <label class="label is-large" for="number_of_chiled">מספר הילדים</label>
          <div class="control">
          <input type="text" class="input is-large" id="text"  :value="rideInformationNumbrOfChild" @input="updateNumbrOfChild">
          </div>
        </div>

        <div class="field">
          <label class="label is-large" for="physical_disabilities">מתאים לאנשים עם מוגבלות?</label>
          <div class="control">
          <input type="text" class="input is-large" id="text"  :value="rideInformationPhysicalDisabilities" @input="updatePhysicalDisabilities">
          </div>
        </div>

        <div class="field">
          <label class="label is-large" for="checkIn">תאריך יציאה</label>
          <div class="control">
          <datepicker name="checkInDate" v-model="updateRideInformationCheckIn"  ></datepicker>
          </div>
        </div>

        <div class="field">
          <label class="label is-large" for="checkOut">תאריך חזרה</label>
          <div class="control">
            <datepicker v-model="updateRideInformationCheckOut" name="checkOutDate"  ></datepicker>
          </div>
        </div>

        </div>
        <div v-if="isRideInformationEditMode" class="control">
          <button  type="button" class="btn btn-primary" @click="this.updateRideInformation">עריכת הטיול </button>
        </div>
        <div v-else class="control">
          <!-- <a class="button is-large is-primary" @click="this.authenticate">Login</a> -->
          <button type="button" class="btn btn-primary" @click="this.createRideInformation">יצירת הטיול</button>
          <!-- <a class="button is-large is-success" @click="register">Register</a> -->
        </div>        
    </section>

  </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';
import Datepicker from 'vuejs-datepicker';

export default {
  name: 'RideInformation',
  components: {
    Datepicker,

  },
  computed: {...mapGetters([  'rideInformationTitle',
                              'rideInformationText',
                              'rideInformationNumbrOfAdoult',
                              'rideInformationNumbrOfTeen',
                              'rideInformationNumbrOfChild',
                              'rideInformationPhysicalDisabilities',
                              'rideInformationCheckIn',
                              'rideInformationCheckOut',
                              'isRideInformationEditMode',
                              ]),
    updateRideInformationCheckIn:{
      get () {
        return this.rideInformationCheckIn
      },
      set (value) {
        this.$store.commit('setRideInformationCheckIn', value)
      }
    },
    updateRideInformationCheckOut:{
      get () {
        return this.rideInformationCheckOut
      },
      set (value) {
        this.$store.commit('setRideInformationCheckOut', value)
      }
    }

  },
  methods: 
  {
    ...mapActions([ 'register',
                    'authenticate',
                    'validateAndupdateAppToken',
                    'createRideInformation',
                    'updateRideInformation',
                    ]),
    updateTitle (e) {
      this.$store.commit('setRideInformationTitle', e.target.value)
    },
    updateText (e) {
      this.$store.commit('setRideInformationText', e.target.value)
    },
    updateNumbrOfAdoult (e) {
      this.$store.commit('setRiderideInformationNumbrOfAdoult', e.target.value)
    },
    updateNumbrOfTeen (e) {
      this.$store.commit('setRideRideInformationNumbrOfTeen', e.target.value)
    },
    updateNumbrOfChild (e) {
      this.$store.commit('setRideRideInformationNumbrOfChild', e.target.value)
    },
    updatePhysicalDisabilities (e) {
      this.$store.commit('setRideRideInformationPhysicalDisabilities', e.target.value)
    },
    register () {
      this.$store.dispatch('register', { email: this.email, password: this.password })
        .then(() => this.$router.push('/'))
    }
  },
};
</script>

<style>
   .uploading-image{
     display:flex;
   }
 </style>
