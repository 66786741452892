
// const state = {
//   locationName: null,
//   placePredictions: null,
//   returnedPlace: null,
//   callback: null,
// };

// const getters = {
//   callback: state => state.callback,
//   returnedPlace: state => state.returnedPlace,
  
// };

 const actions = {
  getPlaceDetails({dispatch, commit}, placeId) {
    const serviceDiv = document.createElement('div');
    const service = new google.maps.places.PlacesService(serviceDiv);
    const request = {
      placeId: placeId,
      fields: ['name', 'geometry'] // Specify the fields you want; 'geometry' includes location (lat, lng)
    };
    service.getDetails(request, (place, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        commit('setReturnedPlace', place);
        dispatch('runCallback', {"latitude":place.geometry.location.lat(), "longtitude":place.geometry.location.lng()});  
      } else {
          console.error('Place details request failed due to ' + status);
      }
    });
  },
  runCallback ({ dispatch, state }, latAndLng){
    const callback = state.callback;
    if( callback ){
      dispatch(callback, latAndLng);
    }
    else{
      console.log( "this one is not set!!!!")
    }
  },

  waitForGoogleAPI({dispatch}, sentParams) {
    function internalGoogleAPITest(){
      if (typeof google !== 'undefined') {
        dispatch('getPlaceLocationFromNameAndRunCalback', sentParams);
      }
      else{
        setTimeout(internalGoogleAPITest, 100); // Check again after 0.1 second
      }
    }
    internalGoogleAPITest()
  },

  async getPlaceLocationFromNameAndRunCalback({ dispatch, commit, state }, sentParams) {
    if (typeof google == 'undefined'){
      dispatch('waitForGoogleAPI', sentParams );
      return;
    }
    mutations.setPlaceInformationCallback(state, sentParams.callback)
    mutations.setLocationName( state, sentParams.name );
    const service = await new google.maps.places.AutocompleteService();
    service.getPlacePredictions({ input: sentParams.name }, (predictions, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK && predictions && predictions[0]) {
        const firestPlaceId = predictions[0].place_id;
        mutations.setPlacePredictions(state , predictions);
        actions.getPlaceDetails({dispatch, commit},firestPlaceId );
      }
    });
  },
};

const mutations = {
  setLocationName: (state, locationName) => {
    state.locationName = locationName;
  },
  setPlacePredictions: (state, placePredictions) => {
    state.placePredictions = placePredictions;
  },
  setReturnedPlace: (state, returnedPlace) => {
    state.returnedPlace = returnedPlace;
  },
  setPlaceInformationCallback: (state, callback) => {
    state.callback = callback;
  },
};

export default {
  //  state,
  //  getters,
  actions,
  mutations
};
