// import api from '../../api/imgur';
// import qs from 'qs';
// import { router } from '../../main';

// const state = {
//   token: window.localStorage.getItem('imgur_token')
// };

 const getters = {
    isMobile: () => window.innerWidth < 768,
    isNotMobile: () => window.innerWidth >= 768
 };

const actions = {
//   isMobile () {
//     return ( window.innerWidth < 768 )
//   },
//   isNotMobile () {
//     return ( window.innerWidth >= 768 )
//   },
};

// const mutations = {
//   setToken: (state, token) => {
//     state.token = token;
//   }
// };

export default {
//   state,
   getters,
   actions,
//   mutations
};
