<template>
  <div>
    <b-modal
      id="areYouShureRemovingAttraction"
      modal-class="modal-cover"
      v-model="isVisible"
      hide-backdrop
      hide-footer
      content-class="shadow-lg"
    >
      <section class="text-center">
        <b-row>
          <b-col cols="12" class="py-2">
            <h3 class="font-weight-medium card-title">
              בטוחים?  
            </h3>
          </b-col>

          <b-col cols="12" class="py-2">
            <p>
              פעולה זואת תמחוק את הפעילות מהטיול שלכם!
            </p>
          </b-col>

          <b-col cols="12" class="py-2">
            <b-btn
              pill
              variant="danger"
              class="px-5 py-2 text-white"
              @click="removeAtraction"
              >מחיקה</b-btn
            >
            <b-btn
              pill
              variant="success"
              class="px-5 py-2 text-white"
              @click="closePopup"
              >ביטול</b-btn
            >
          </b-col>
        </b-row>
      </section>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  props: ["value"],
  
  computed: {
    ...mapGetters([ 'allRide',
                    'inerModalParameters' ]),
    isVisible: {
      set(val) {
        this.$emit("input", val);
      },
      get() {
        return this.value;
      },
    },
  },
  methods: { 
    ...mapActions([ 'addRideToUser',
                    'removeAttractionFromRide',
                    'showModal',
     ]),
    removeAtraction(){
      this.removeAttractionFromRide(this.inerModalParameters.attractionId);
      this.showModal('')
    },
    closePopup(){
      this.showModal('')
    },

  },

};
</script>



<style scoped>
.modal.modal-cover .modal-content,
.modal.modal-cover .modal-footer,
.modal.modal-cover .modal-header {
  border: none;
}
.height-50 {
  height: 50px !important;
}
.width-50 {
  width: 50px !important;
}
</style>