import Vuex from 'vuex';
import Vue from 'vue';
import auth from './modules/auth';
import screanSize from '../common/screanSize';
import mapIcons from '../common/mapIcons';
import plaseInformation from '../common/plaseInformation';
import imageURLSizeManipulation from '../common/imageURLSizeManipulation'
import images from './modules/images';
import ride from './modules/ride';
import presentUserInfo from './modules/presentUserInfo';
import labelNameHolder from './modules/labelNameHolder';
import attractionUniqnes from './modules/attractionUniqnes';
import accommodations from './modules/accommodations';
import searchAccommodations from './modules/searchAccommodations';
import searchAccommodationsWithMap from './modules/searchAccommodationsWithMap';
import serchAttractions from './modules/serchAttractions';
import serchAttractionsWithMap from './modules/serchAttractionsWithMap';
import appauth from './modules/appauth';
import singleMapRide from './modules/singleMapRide';
import globalMap from './modules/globalMap';
import commonMap from './modules/commonMap';
import createAttraction from './modules/createAttraction';
import uploadTrip from './modules/uploadTrip';
import accommodation from './modules/accommodation';
import userRides from './modules/userRides';
import rideInformation from './modules/rideInformation';
import globalRides from './modules/globalRides';
import PagePopups from './modules/popups';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    screanSize,
    mapIcons,
    plaseInformation,
    imageURLSizeManipulation,
    images,
    ride,
    presentUserInfo,
    labelNameHolder,
    attractionUniqnes,
    appauth,
    singleMapRide,
    searchAccommodations,
    searchAccommodationsWithMap,
    serchAttractions,
    serchAttractionsWithMap,
    globalMap,
    commonMap,
    createAttraction,
    uploadTrip,
    accommodation,
    accommodations,
    userRides,
    rideInformation,
    globalRides,
    PagePopups,
  }
});
