import axios from 'axios';
import config from '../common/config';

export default {
  fetchAttractionUniqnes(token ) {
    return axios.get(`${config.config.ROOT_URL}/attraction_uniqnes`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  },

  setAsMainAttraction(token, uniqnessAttractionId, attractionId) {
    return axios.get(`${config.config.ROOT_URL}/update_main_attraction_uniqnes/${uniqnessAttractionId}/${attractionId}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  },
  
  moveAttractionToUniqnessGrop(token, attractionId, newUniqnessAttractionId ) {
    return axios.get(`${config.config.ROOT_URL}/update_attraction_to_uniqness_grop/${attractionId}/${newUniqnessAttractionId}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  },
};
