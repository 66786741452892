<script >
export default {
  props: {
    callBack: {
      type: Function,
      required: true
    },
  }
};
</script>

<template>
  <b-button
    class="btn btn-warning px-5 py-3 rounded-pill fw-semibold h5 font-weight-bold z-2"
    @click="callBack"
  >
    <slot></slot>
  </b-button>
</template>

<style scoped>

</style>