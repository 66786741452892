<template>
  <gmap-autocomplete
    class="gmap-autocomplete"
    @place_changed="updateCurrentPlace"
    :select-first-on-enter="true"
    placeholder="הכנסת מיקום"
    :options="{
      fields: ['geometry'],
    }"

    >
  </gmap-autocomplete>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'GlobalMapSerchAutoComplet',
  methods: 
  {
    ...mapActions([ 'setOneMarker',
                    ]),
    updateCurrentPlace (place) {
      if ( place.geometry ){
        this.setOneMarker( {  latPoint: place.geometry.location.lat(),
                              lngPoint: place.geometry.location.lng(),
                              name:"האטרקציה החדשה"} )
        this.$emit("handleserchPointChange", place);

        
      }
   },
  },
};
</script>
