<template>
  <div 
    class="image-container"
    @click="goTobookingLocal()"
>
    <img 
      src="https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/icons%2Fbookingcom%2FbookingImageLabel.webp?alt=media&token=a0ad3093-e502-45ec-974b-282cc2bd37bf"
      width="1792"
      height="1024"
      alt="bookingDiscaunt"     
    >
    <div class="centered-text">
      <p>מבצע מיוחד לזמן מוגבל</p>
      <h1>15% הנחה</h1>
      <p>על הזמנת מלונות בבוקינג בחו"ל</p>
      <button class="order-button">הזמינו עכשיו!</button>
    </div>
  </div>
</template>

<script>

export default {
  name: "BookingImageLabel",
  methods: {
    goTobookingLocal(  ) {
      if (window.fbq) {
          window.fbq('track', 'Lead');
          window.fbq('track', 'gooToBooking');

      }             
      window.open('https://www.booking.com/dealspage.he.html?aid=2427332&tmpl=landing_pages%2Frunway_internal_action&lang=he&soz=1&lang_click=other&cdl=en-us&lang_changed=1','_blank')
    },
  },

};

</script>

<style scoped>

.image-container {
            position: relative;
            width: 100%;
        }

.image-container:hover {
  cursor: pointer;
}

        .image-container img {
            width: 100%;
            height: auto;
        }

        .centered-text {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 60%;
            height: 60%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            color: #D35400; /* Text color */
            background: white; /* White background */
            padding: 20px;
            box-sizing: border-box;
            border-radius: 15px; /* Rounded corners */
            box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2); /* Shadow */
        }

        .centered-text h1 {
            font-size: 32px; /* Change as needed for bigger size */
            margin: 10px 0;
            font-weight: bold; /* Make the text bold */
        }

        .centered-text p {
            font-size: 18px; /* Change as needed */
            margin: 5px 0;
            font-weight: bold; /* Make the text bold */
        }

        .order-button {
            margin-top: 20px;
            padding: 10px 20px;
            font-size: 18px;
            background-color: #ff0000; /* Change as needed */
            color: white;
            border: none;
            border-radius: 15px; /* Add rounded corners */
            cursor: pointer;
            text-transform: uppercase;
            font-weight: bold; /* Make the button text bold */
        }

        /* Media query for mobile devices */
        @media (max-width: 840px) {
            .centered-text {
                width: 80%;
                height: 70%;
                padding: 10px;
            }

            .centered-text h1 {
                font-size: 24px;
            }

            .centered-text p {
                font-size: 14px;
            }

            .order-button {
                font-size: 16px;
                padding: 8px 16px;
            }
            .order-button {
                margin-top: 5px
            }

        }
        /* Media query for mobile devices */
        @media (max-width: 400px) {
            .centered-text {
                padding: 5px;
                margin: 5px 0;
            }
            .order-button {
                margin-top: 3px
            }
            .centered-text h1 {
                margin: 5px 0;
            }

        }

</style>
