<template>
	<div class="container-fluid bg-light-blue py-5 position-relative overflow-hidden">
		<div class="position-absolute top-50 translate-middle start-50">
			<img 
				alt=""
				src="https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/page_images%2Fairlines-draw.svg?alt=media&token=1b36f835-84c7-47e9-92c6-e71c20fe0a5f"
				height="675"
				width="1479"
				>
		</div>
		<div class="container">
			<h2 class="fw-bold text-center pt-3 pt-md-5">איך מתכננים חופשה חכם</h2>
			<div class="row my-3 my-md-5 equal-height">
				<div class="col-lg-4 mb-lg-0 order-1 order-lg-1 mb-3">
					<div class="card border-0 shadow-sm p-md-3 rounded-5 h-100">
						<div class="card-body d-flex flex-column text-end gap-md-3 position-relative">
							<div class="step-number-badge position-absolute fs-4 rounded-circle d-flex justify-content-center align-items-center">
								1
							</div>
							<img alt="step 1" class="image-how-it-works" src="https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/page_images%2Fstep1.svg?alt=media&token=3c66f4bb-ba14-423d-bf5a-e4c6ade381ef">
							<h4 class="fw-semibold mb-0 text-align-center">
								בחרו חופשה שאהבתם 
								<br>
								כבסיס לתכנון
							</h4>
							<p class="fs-6 mb-0 text-muted">
								התכנון שלכם מתחיל בבחירת חופשה מתוך המאגר באתר, שישמש כבסיס לתכנון החופשה שלכם
							</p>
						</div>
					</div>
				</div>
				<div class="col-lg-4 mb-lg-0 order-2 order-lg-2 mb-3">
					<div class="card border-0 shadow-sm p-md-3 rounded-5 h-100">
						<div class="card-body d-flex flex-column text-end gap-md-3 position-relative">
							<div class="step-number-badge position-absolute fs-4 rounded-circle d-flex justify-content-center align-items-center">
								2
							</div>
							<img alt="step 2" class="image-how-it-works" src="https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/page_images%2Fstep2.svg?alt=media&token=9b8aba8a-99c5-4952-b98b-24d593ee21af">
							<h4 class="fw-semibold mb-0 text-align-center">
								תוסיפו או תורידו
								<br>
								אטרקציות לחופשה שבחרתם
							</h4>
							<p class="fs-6 mb-0 text-muted">
								תהנו מתכנון קל ונוח על ידי התאמת החופשה שבחרתם והוספת פעילויות מומלצות מחופשות נוספות ששותפו באתר
							</p>
						</div>
					</div>
				</div>
				<div class="col-lg-4 mb-lg-0 order-3 order-lg-3 mb-3">
					<div class="card border-0 shadow-sm p-md-3 rounded-5 h-100">
						<div class="card-body d-flex flex-column text-end gap-2 gap-md-3 position-relative">
							<div class="step-number-badge position-absolute fs-4 rounded-circle d-flex justify-content-center align-items-center">
								3
							</div>
							<img alt="step 3" class="image-how-it-works" src="https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/page_images%2Fstep3.svg?alt=media&token=3109d1ec-849e-4434-bd44-0515717d694a">
							<h4 class="fw-semibold mb-0 text-align-center">
								צאו לחופשה בכיף <br>ושתפו חברים ומשפחה
							</h4>
							<p class="fs-6 mb-0 text-muted">
								תהנו מחוויית חופשה מדהימה עם ליווי מהאתר במהלך הטיול. ותחזירו לקהילה על ידי הוספת החופשה שלכם למאגר באתר ושיתוף עם חברים ומשפחה!
							</p>
						</div>
					</div>
				</div>
			</div>
			<div class="d-flex justify-content-center pb-3 pb-md-5">
				<button
					class="btn btn-primary px-5 py-3 rounded-pill fw-semibold fs-5 z-2"
					@click="goToSerchattractionPage()"
				>
					צפו בחופשות מוצלחות
				</button>
			</div>
		</div>

	</div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  name: 'HowItWorksNew',
  methods: {
  ...mapActions(["goToSerchattractionPage",
                ]),
  },

};
</script>

<style scoped>
.py-5 {
	padding-top: 4.8rem !important;
	padding-bottom: 4.8rem !important;
}
.start-50 {
	left: 50% !important;
}
.top-50 {
	top: 50% !important;
}
.translate-middle {
	transform: translate(-50%, -50%) !important;
}
img{
	max-width: none;
}
@media (min-width: 768px) {
	.pt-md-5, .py-md-5 {
		padding-top: 4.8rem !important;
	}
	.mb-md-5, .my-md-5 {
		margin-bottom: 4.8rem !important;
	}
	.mt-md-5, .my-md-5 {
		margin-top: 4.8rem !important;
	}
	.p-md-3 {
		padding: 1.6rem !important;
	}
	.gap-md-3 {
		gap: 1.6rem !important;
	}
	.pb-md-5 {
		padding-bottom: 3rem !important;
	}


}
.fw-bold {
	font-weight: 700 !important;
}
.rounded-5 {
	border-radius: 3.2rem !important;
}
.card {
	--bs-card-spacer-y: 1rem;
	--bs-card-spacer-x: 1rem;
	--bs-card-title-spacer-y: 0.5rem;
	--bs-card-title-color: ;
	--bs-card-subtitle-color: ;
	--bs-card-border-width: 1px;
	--bs-card-border-color: rgba(0, 0, 0, 0.175);
	--bs-card-border-radius: .5rem;
	--bs-card-box-shadow: ;
	--bs-card-inner-border-radius: calc((.5rem) - (1.6rem));
	--bs-card-cap-padding-y: 0.5rem;
	--bs-card-cap-padding-x: 1rem;
	--bs-card-cap-bg: rgba(33,37,41, 0.03);
	--bs-card-cap-color: ;
	--bs-card-height: ;
	--bs-card-color: ;
	--bs-card-bg: #fff;
	--bs-card-img-overlay-padding: 1.6rem;
	--bs-card-group-margin: 1.2rem;
	color: #212529;
	word-wrap: break-word;
	font-size: 1.6rem;
	background-size: cover;
	background-position: center;

}
.step-number-badge {
	height: 54px;
	width: 54px;
	top: 16px;
	right: 16px;
	color: #DD6E42;
	font-weight: 900;
	background-color: rgba(221, 110, 66, 0.25);
}
@media (min-width: 1200px) {
	.h2, h2 {
		font-size: 3.2rem;
	}
	.fs-4 {
		font-size: 2.4rem !important;
	}
		.h4, h4 {
		font-size: 2.4rem;
	}

}
.image-how-it-works {
	height: 150px !important;
}
.text-muted {
	--bs-text-opacity: 1;
	color: rgba(33, 37, 41, 0.75) !important;
}

.fs-6 {
	font-size: 1.6rem !important;
}
.fs-5 {
	font-size: 2rem !important;
}

button:not(:disabled) {
	cursor: pointer;
}
.z-2 {
	z-index: 2 !important;
}
.equal-height .card {
	height: 100%;
}
.fw-semibold {
    font-weight: 600 !important;
}
.text-align-center {
    text-align: center;
}

</style>
