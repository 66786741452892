import axios from 'axios';

import config from '../common/config';
export default {
  fetchSearchAccommodationsByLocation(token, lat, lng) {
    return axios.get(`${config.config.ROOT_URL}/serch_accommodations_by_location_api/${lat}/${lng}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  },

};
