<template>
  <div class="home" >
    <WhatsappChet/>
    <SingleRideHeader  
      presentedText="הוספת פעילות לטיול"
    >
    </SingleRideHeader>
      <div class="trip-card-section after_page_header">
        <div class="container serch_attraction_categories">
          <div class="trip-card-top">
            <GlobalMapSerchAutoComplet
              @handleserchPointChange="handleUpdateserchpoint" >
            </GlobalMapSerchAutoComplet>
            <a class="trip-btn-bg" @click="ReturnToSingleRide()" >חזרה לטיול ></a>
          </div>
          <SerchAttractionCategories />
        </div>
      </div>
      <div class="trip-activity-section">
				<div class="container">
          <div class="row">
            <div class="col-xl-7 col-lg-8">
              <div class="trip-sidebar">
                <SerchAttractions />
              </div>
            </div>
            <div class="col-xl-5 col-lg-4">
              <div class="trip-map-four serch_attraction_map_header">
                <div class="trip-location">
                  <ul>
                    <li>פעילויות שכבר קיימות בטיול<img src="https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/icons%2FfullColers%2FpointWithFullColler_07A0C3.png?alt=media&token=c3e342d6-f425-495f-842d-c0e6edd2730e" alt=""></li>
                    <li>פעילויות שמתאימות ליום שנבחר<img src="https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/icons%2Fmap_letter%2Fblack_numbers%2Fblack_map_Icon_empty.png?alt=media&token=ae5de8aa-cee4-47a7-966b-e02eb1b93480'" alt=""></li>
                  </ul>
                </div>
                <div >
                  <div >
                    <GlobalMap
                    passed_class="single_ride_map">
                    </GlobalMap>
                  </div>
                  <img src="images/map-two.png" alt="">
                </div>
              </div>
            </div>
          </div>
				</div>
      </div>
    <PageFooter />
  </div>
</template>

<script>

import PageFooter from '@/components/PageFooter.vue'
import SerchAttractions from '@/components/SerchAttractions';
import GlobalMap from '@/components/GlobalMap';
import SerchAttractionCategories from '@/components/SerchAttractionCategories';
import SingleRideHeader from '@/components/SingleRideHeader';
import GlobalMapSerchAutoComplet from '@/components/GlobalMapSerchAutoComplet';
import WhatsappChet from "@/components/WhatsappChet";
import { mapActions } from 'vuex';

export default {
  name: 'SerchAtrractionWithMap',
  components: {
    GlobalMapSerchAutoComplet,
    SerchAttractions,
    GlobalMap,
    PageFooter,
    SingleRideHeader,
    SerchAttractionCategories,
    WhatsappChet,
  },
  methods: {
    ...mapActions([ 'updateMarkerByScrollerLocation',
                    'fetchSerchAttractionsByRide',
                    'ReturnToSingleRide' ]),
    handleUpdateserchpoint( place ){
      this.$store.commit('setSerchLat', place.geometry.location.lat());
      this.$store.commit('setSerchLng', place.geometry.location.lng());
      this.$store.commit('setCurrentPlace', place)
      this.fetchSerchAttractionsByRide();
   },
  },
  created(){
    window.addEventListener('scroll', this.updateMarkerByScrollerLocation);
  },
  mounted(){
    this.$store.commit('setPresentedDay', null);
    // this.updateMarkerByScrollerLocation();

      // map stikiness
    const sectionHeroElMap = document.querySelector(".serch_attraction_categories");
    const obsMap = new IntersectionObserver(
      function (entries) {
        const ent = entries[0];
        if ( (ent.isIntersecting === false) && ( ent.boundingClientRect.y < 0) ) {
          document.body.classList.add("serch_attraction_map_sticky");
        }
        if (ent.isIntersecting === true) {
          document.body.classList.remove("serch_attraction_map_sticky");
        }
      },
      {
        // In the viewport
        root: null,
        threshold: 0,
        rootMargin: "-40px",
      }
    );
    obsMap.observe(sectionHeroElMap);


  },
  destroyed(){
    window.removeEventListener('scroll', this.updateMarkerByScrollerLocation);
  },
  
};


</script>

<style scoped>

.after_page_header{
  margin-top: 20rem;
}
@media (max-width: 991px) {
  .after_page_header{
    margin-top: 15rem;
  }
}

  .abit_right{
    padding-right: 70%;
  }
  .sidebar{
    position: fixed;
  }

  .sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
  }

</style>
