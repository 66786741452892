<template>
  <div class="row" id="recommended-trips">
    <div v-for="trip in recommendedTrips" :key="trip.id" :class="`${trip.size} mb-3`">
      <a 
        >
        <div
          class="clickable card border-0 shadow-sm rounded-5 bg-card"
          :style="{ height: '300px', backgroundImage: `url(${trip.image})`, backgroundSize: 'cover', backgroundPosition: 'center' }"
          @click="goToRideLocal(trip.RideId)"
        >
          <div class="card-body d-flex justify-content-between flex-column">
            <div>
              <div
                class="fw-bold fs-6 glass-card d-inline-flex px-3 py-2 rounded-pill align-items-center justify-content-center"
              >
                {{ trip.title }}
              </div>
            </div>
            <div class="d-flex justify-content-end gap-2">
              <div
                class="glass-card d-inline-flex px-3 py-2 rounded-pill align-items-center justify-content-center"
              >
                <!-- <i class="fa-solid fa-calendar-days text-primary fs-5 me-2"></i> -->
                {{ trip.manyDays }}
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/page_images%2Fcolander.png?alt=media&token=85389257-68ed-4590-9609-0213debfbae0"
                  height="20px"
                  alt="Calendar"
                  class="fa-solid fa-calendar-days text-primary fs-5 me-2"
                />

              </div>
              <div
                class="glass-card d-inline-flex px-3 py-2 rounded-pill align-items-center justify-content-center"
              >
                <!-- <i class="fa-solid fa-map-pin text-orange fs-5 me-2"></i> -->
                {{ trip.location }}
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/page_images%2Fpin_orange.png?alt=media&token=d15478b3-2f97-4ac8-8636-b7afc7b2caf0"
                  height="20px"
                  alt="Calendar"
                  class="fa-solid fa-map-pin text-orange fs-5 me-2"
                />
              </div>
            </div>
          </div>
        </div>
      </a>
    </div>
  </div>
</template>

<script>

import { mapActions } from 'vuex';

export default {
  name: 'RecommendedTrips',
  methods: {
    ...mapActions([ 'goToSerchattractionPage',
                     ]),
      goToRideLocal( rideId ) {
        this.$router.push('/single_ride_with_map/'+ rideId)
      },

  },
  data() {
    return {
      recommendedTrips: [
        {
          id: 1,
          title: 'וינה וזלצבורג',
          location: 'אוסטריה',
          manyDays: '14 ימים',
          image: 'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/1_30_Mar_2021_16%3A34%3A03_GMT?alt=media&token=983aa4d1-62ae-4436-8518-08f9e2558d0a',
          size: 'col-md-6 col-lg-7',
          RideId: 1,
        },
        {
          id: 2,
          title: 'פירנאים, ברצלונה ומדריד',
          location: 'ספרד',
          manyDays: '11 ימים',
          image: 'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/gui_images%2Fspain_park_climing.jpeg?alt=media&token=ae5b0b2d-036a-45ae-a747-49ca495c2c70',
          size: 'col-md-6 col-lg-5',
          RideId: 719,
        },
        {
          id: 3,
          title: 'קרקוב וזקופנה',
          location: 'דרום פולין',
          manyDays: '14 ימים',
          image: 'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/attractions%2F183_27_Aug_2022_20%3A43%3A38_GMT?alt=media&token=88678958-c118-484d-bfdb-6dcb2544040c',
          size: 'col-md-6 col-lg-4',
          RideId: 183,
        },
        {
          id: 4,
          title: 'ניו-יורק',
          location: 'ניו-יורק',
          manyDays: "10 ימים",
          image: 'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/attractions%2F843_05_May_2024_05%3A19%3A36_GMT?alt=media&token=984d7c84-d476-4335-8f01-d49ba245767b',
          size: 'col-md-6 col-lg-4',
          RideId: 843,
        },
        {
          id: 5,
          title: ' זלצבורג וטירול',
          location: 'אוסטריה',
          manyDays: "14 ימים",
          image: 'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/attractions%2F72_10_Aug_2021_10%3A33%3A53_GMT?alt=media&token=c100094f-90ef-4c05-98bc-9e774224fcb7',
          size: 'col-md-6 col-lg-4',
          RideId: 72,
        },
        {
          id: 6,
          title: 'סקוטלנד/אנגליה/ויילס',
          location: 'הממלכה המאוחדת',
          manyDays: "19 ימים",
          image: 'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/attractions%2F228_03_Oct_2022_14%3A14%3A09_GMT?alt=media&token=88757e40-65fc-4b7e-bec7-538e77de7e5d',
          size: 'col-md-6 col-lg-5',
          RideId: 228,
        },
        {
          id: 7,
          title: 'בסטאי והסביבה',
          location: 'גרמניה',
          manyDays: "8 ימים",
          image: 'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/attractions%2F233_02_Oct_2022_13%3A00%3A15_GMT?alt=media&token=9daa4dec-5943-4853-bccf-8824235b460c',
          size: 'col-md-6 col-lg-7',
          RideId: 233,
        },
      ]
    };
  }
};
</script>

<style scoped>
.bg-card {
  background-size: cover;
  background-position: center;
}

.glass-card {
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(10px);
}

.text-primary {
  color: #007bff !important;
}

.text-orange {
  color: #fd7e14 !important;
}





.glass-card {
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(10px);
}


.card {
    --bs-card-spacer-y: 1rem;
    --bs-card-spacer-x: 1rem;
    --bs-card-title-spacer-y: 0.5rem;
    --bs-card-title-color: ;
    --bs-card-subtitle-color: ;
    --bs-card-border-width: 1px;
    --bs-card-border-color: rgba(0, 0, 0, 0.175);
    --bs-card-border-radius: .5rem;
    --bs-card-box-shadow: ;
    --bs-card-inner-border-radius: calc((.5rem) - (1.6rem));
    --bs-card-cap-padding-y: 0.5rem;
    --bs-card-cap-padding-x: 1rem;
    --bs-card-cap-bg: rgba(33,37,41, 0.03);
    --bs-card-cap-color: ;
    --bs-card-height: ;
    --bs-card-color: ;
    --bs-card-bg: #fff;
    --bs-card-img-overlay-padding: 1.6rem;
    --bs-card-group-margin: 1.2rem;
    color: #212529;
    word-wrap: break-word;
    font-size: 1.6rem;
    background-size: cover;
    background-position: center;

}


.card-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.text-primary {
  color: #007bff !important;
}

.text-orange {
  color: #fd7e14 !important;
}

/* .text-primary {
    background-image: linear-gradient(45deg, #34D0B6, #07A0C3) !important;
    color: transparent !important;
} */
.py-2 {
    padding-top: 0.8rem !important;
    padding-bottom: .8rem !important;
}
.px-3 {
    padding-right: 1.6rem !important;
    padding-left: 1.6rem !important;
}
.me-2 {
  margin-right: .8rem !important;
}

@media (min-width: 768px) {
    .py-md-5 {
        padding-top: 3rem !important;
        padding-bottom: 3rem !important;
    }
}
.rounded-5 {
  border-radius: 3.2rem !important;
}
.clickable:not(:disabled):not(.disabled) {
    cursor: pointer;
}
@media (max-width: 768px) {
  .card {
    margin: 15px; /* Adjust the margin value as needed */
  }
}




</style>