// import screanSize from './screanSize'
// import api from '../../api/imgur';
// import qs from 'qs';
// import { router } from '../../main';

// const state = {
//   token: window.localStorage.getItem('imgur_token')
// };

 const getters = {
  smalerImage: () => (imageUrl) => {
    try {
      let delimiter = "?alt=";
      if (!imageUrl.includes(delimiter)) {
        throw new Error("Delimiter not found");
      }
      const resizedImageURL = imageUrl.split(delimiter)[0] + "_300x300" + delimiter + imageUrl.split(delimiter)[1];
      return resizedImageURL;
    } catch (error) {
      return imageUrl;
    }   
  },

 };

 const actions = {
  
  async getResizeImageForMobileDevicesCommon({ getters, rootGetters },imageUrl){
    if( rootGetters.isMobile ){
      return getters.smalerImage(imageUrl);
    }
    return imageUrl
  },

  // loadErrorImage( event, URL ){
  //   // console.log( event )
  //   // console.log( URL )
  //   screanSize.actions.isMobile();
  //   console.log( event)
  //   console.log( event.target)
  //   event.target.src = URL
  // },
  // async getResizeImageForMobileDevices( imageUrl ){
  //   console.log( imageUrl );
    
  //   // at this point we should check the screan size and to deside witch image we want to use
  //   // let imageUrl = imageUrl1
  //   // console.log( imageUrl );
  //   // let _imageUrl = imageUrl.toString()
  //   let delimiter = "?alt="
  //   const resizedImageURL = imageUrl.toString().split(delimiter)[0]+"_300x300" + delimiter +imageUrl.toString().split(delimiter)[1]
  //   console.log( resizedImageURL );
  //   return resizedImageURL;
  // },
};

// const mutations = {
//   setToken: (state, token) => {
//     state.token = token;
//   }
// };

export default {
//   state,
   getters,
   actions,
//   mutations
};
