<template>
  <div>
    <SingleRideHeader
      presentedText="מקומות הלינה"
     />
    <b-container class="py-5">
      <SingleRideChecklist />
    </b-container>
    <PageFooter />
  </div>
</template>

<script>

import PageFooter from '@/components/PageFooter.vue'
import SingleRideChecklist from '@/components/SingleRideChecklist'
import SingleRideHeader from '@/components/SingleRideHeader';

import { mapActions } from 'vuex';

export default {
  name: 'RideChecklist',
  components: {
    SingleRideChecklist,
    PageFooter,
    SingleRideHeader
  },
  methods: mapActions(['rideUpdateMarkerByScrollerLocation']),
  created(){
    window.addEventListener('scroll', this.rideUpdateMarkerByScrollerLocation);
    const rideIdFromUrl = this.$route.params.id;
    if ( rideIdFromUrl ){
      this.$store.commit('setLowedRideId', rideIdFromUrl);
    }

  },
  mounted(){
    this.$store.commit('rideSetPresentedDay', null);
    // this.rideUpdateMarkerByScrollerLocation();
  },
  destroyed(){
    window.removeEventListener('scroll', this.rideUpdateMarkerByScrollerLocation);
  },

};


</script>