
const state = {
  modals: {
    mainLogIn: false,
    mainRegistration: false,
    oldSignup: false,
    loginForm: false,
    signupForm: false,
    resetForm: false,
    shareRide: false,
    attraction: false,
    pleaseAddTheRideToYourList: false,
    updateAttractionTime: false,
    pleaseLogInInOrderToEditRide: false,
    areYouShureRemovingAttraction: false,
    doYouWantToAddThisPointToRide: false,
    dynamicModal: false,
    loadingIcon: false,
    LoadingIconWithUploadStatus: false,
  },
  loked: false,
  dynamicModal: null,
  inerModalParameters: null,
  inerModalParametersWereUsedWorkAround: false,
  errorNumberConverter: {
    1: {
      text: "זה עבד",
      subText: "יופי תמשיכו",
      modalsName: "dynamicModal",
      action: "openLoginFormPresed",
      actionText: "ננסה שוב",
    },
    2: {
      text: "מצטערים",
      subText: "משהו לא עבד טוב - נטפל בזה",
      modalsName: "dynamicModal",
      action: "openLoginFormPresed",
      actionText: "ננסה שוב",
    },
    3: {
      text: "המשתמש לא רשום אצלינו במערכת",
      subText: "אתם בטוחים שנרשמתם?",
      modalsName: "dynamicModal",
      action: "openLoginFormPresed",
      actionText: "ננסה שוב",
    },
    4: {
      text: "סיסמא שגויה",
      subText: "גם לי זה קורה מידי פעם",
      modalsName: "dynamicModal",
      action: "openLoginFormPresed",
      actionText: "ננסה שוב",
    },
    5: {
      text: "אתם כבר רשומים אצלינו באתר",
      subText: "גם לי זה קורה מידי פעם",
      modalsName: "dynamicModal",
      action: "openLoginFormPresed",
      actionText: "ננסה שוב",
    },
    "userLoggedInSuccessfully": {
      text: "התחברתם בהצלחה",
      textTemplate: "התחברתם בהצלחה עם {addToText} ",
      subText: "עכשיו תוכלו להעתיק חופשה ולשנות את המסלול לפי הצרכים שלכם",
      modalsName: "dynamicModal",
      action: "closePopup",
      actionText: "המשך",
    },
    "rideHaveOrderedAccommodation": {
      text: "אופס, יש לכם הזמנה של לינה",
      subText: "לא ניתן לערוך את הימים לאחר הכנסה של פרטי לינה, ניתן למחוק את פרטי הלינה ואז לערוך ",
      modalsName: "dynamicModal",
      action: "closePopup",
      actionText: "סגירה",
    },
    "fail_to_update_accommodation": {
      text: "אופס, משהוא השתבש בעדכון",
      subText: "תבדוק בבקשה שהכל עודכן כמו שצריך או צור איתנו קשר לתיקון ",
      modalsName: "dynamicModal",
      action: "closePopup",
      actionText: "סגירה",
    },
    "user_is_not_authorized": {
      text: "אופס, למשתמש אין הרשאה לעשות את זה",
      subText: "תתנתק בבקשה ותתחבר שוב, זה אמור לעזור ",
      modalsName: "dynamicModal",
      action: "closePopup",
      actionText: "סגירה",
    },
    "date_are_not_in_range": {
      text: "אופס, התאריכים לא תואמים לטיול",
      subText: "נסו לתקן בבקשה",
      modalsName: "dynamicModal",
      action: "closePopup",
      actionText: "סגירה",
    },
    "you_Are_having_double_booking": {
      text: "אופס, יש כבר הזמנה על חלק מהימים",
      subText: "נסו לתקן בבקשה",
      modalsName: "dynamicModal",
      action: "closePopup",
      actionText: "סגירה",
    },
    "uesr_is_not_authorize_to_edit_this_ride": {
      text: "אין לכם אפשרות לערוך את הטיול הזה",
      subText: "בחרו בכפתור ערוך את הטיול ונסו שוב",
      modalsName: "dynamicModal",
      action: "closePopup",
      actionText: "סגירה",
    },
    "scroll_down_to_see_the_added_day": {
      text: "הוספתם יום לטיול",
      subText: "צריך לרדת קצת בעמוד כדי לראות אותו",
      modalsName: "dynamicModal",
      action: "closePopup",
      actionText: "סגירה",
    },
    "the_day_wewre_delited_and_the activites_pushed_to_the_next_day": {
      text: "מחקתם יום מהטיול",
      subText: "כל הפעילויות של אותו היום עברו ליום אחד אחרי, ניתן להוריד אותן אים רוצים",
      modalsName: "dynamicModal",
      action: "closePopup",
      actionText: "סגירה",
    },
    "uesr_is_adding_new_ride": {
      text: "איזה כיף שבחרתם לשתף",
      subText: "בואו נתחיל בהכנסת פרטים כללים של הטיול ",
      modalsName: "dynamicModal",
      action: "closePopup",
      actionText: "סגירה",
    },
    "uesr_copyed_new_ride": {
      text: "איזה כיף שזה הטיול שבחרתם",
      subText: "חשוב לעדכן את תאריך תחילת הטיול, ומיד אחרי תוכלו לעדכן את הטיול לפי הצרכים שלכם",
      modalsName: "dynamicModal",
      action: "closePopup",
      actionText: "סגירה",
    },
    "please_use_auto_compleate": {
      text: "השתמשו באפשרויות של המילוי האוטומטי",
      subText: "בחרו את אחת האפשרויות",
      modalsName: "dynamicModal",
      action: "closePopup",
      actionText: "סגירה",
    },
    "sorry_we_dont_have_this_ride_please_add_it_at_your_return": {
      text: "אופס - עדיין אין לנו טיול לאיזור הזה",
      subText: "אנחנו נשמח אם תוסיפו אותו כשתחזרו",
      modalsName: "dynamicModal",
      action: "closePopup",
      actionText: "סגירה",
    },
    "updating_the_ride": {
      text: "מעלה נתונים",
      subText: "אנחנו מעדכנים את הטיול שלכם",
      modalsName: "dynamicModal",
      action: "closePopup",
      actionText: "סגירה",
    },
    "user_guide_for_editing": {
      text: "ידעתם שאפשר להעתיק את הטיול הזה ולערוך אותו?",
      subText: "אפשר להוסיף פעילויות מטיולים שמשפחות אחרות עשו באותו אזור!",
      modalsName: "dynamicModal",
      action: "closePopup",
      actionText: "סגירה",
    },
    "new_ride_detailes_form": {
      text: "יצרנו דרך נוספת וממש פשוטה להעלות טיול",
      subText: "תרצו שניצור אתכם קשר להסבר?",
      modalsName: "dynamicModal",
      action: "goToAddRideWithAlboumDetailesForm",
      actionText: "כן",
    },
    "account_exists_with_different_credential": {
      text: "המייל כבר רשום עם חיבור ממקור אחר",
      subText: "התחברות בעזרת המערכת שנרשמתם בפעם הראשונה",
      modalsName: "dynamicModal",
      action: "openLoginFormPresed",
      actionText: "ננסה שוב",
    },
    "email_is_not_baind_to_this_acount": {
      text: "אין מייל משויך לחשבון זה",
      subText: "לא ניתן להתחבר ללא מייל שמשויך לחשבון",
      modalsName: "dynamicModal",
      action: "openLoginFormPresed",
      actionText: "ננסה שוב",
    },
    "connect_me_for_adding_new_ride": {
      text: "כדי לקבל אפשרות לשתף את הטיול שעשיתם באתר",
      subText: "יש ליצור איתנו קשר ב 052-686-7915",
      modalsName: "dynamicModal",
      action: "closePopup",
      actionText: "סגירה",
    },
    "upload_trip_adding_text_stage": {
      text: "יש!! העלנו את המסלול",
      subText: "בשלב הזה אפשר לרשום את השם שנרצה לחפשה וקצת דברים כללים על החופשה",
      modalsName: "dynamicModal",
      action: "closePopup",
      actionText: "סגירה",
    },
    "all_images_dates_are_empty": {
      text: "כל התמונות לא מכילות מידע על זמן הצילום",
      subText: "כדי שזה יעבוד צריך להשתמש בתמונות האמיתיות מהטלפון שצילם אותם",
      modalsName: "dynamicModal",
      action: "closePopup",
      actionText: "סגירה",
    },
    "limiting_the_trip_duration": {
      text: "אורך המסלול מוגבל ל 60 יום",
      subText: "נא להעלתות טיול של עד 60 יום",
      modalsName: "dynamicModal",
      action: "closePopup",
      actionText: "סגירה",
    },
    "all_images_without_metadata": {
      text: "אין נתונים מהתמונות",
      subText: "כרגע אנו לא תומכים בתמונות שלא צולמו מהמכשיר שממנו מעלים את התמונות",
      modalsName: "dynamicModal",
      action: "closePopup",
      actionText: "סגירה",
    },
    "waiting_to_get_image_info": {
      text: "אני מבצע עיבוד על התמונות כדי להבין איפה הן צולמו",
      subText: "זה יכול לקחת עד 15 שניות",
      modalsName: "dynamicModal",
      action: "closePopup",
      actionText: "",
      spionr: true,
    },
    "plese_add_the_activity_info": {
      "text": "יש לנו טיול מוכן! עכשיו זה הזמן לתת את הטיפים למקומות",
      "subText": "אתם יכולים כרגע לעבור על כל האטרקציות ולתת את הטיפים שלכם כדי לעזור למשפחות שיטיילו שם למקסם את החוויה שלהם מהמקום",
      "modalsName": "dynamicModal",
      "action": "closePopup",
      "actionText": "סגירה"
    },
    "plese_add_the_accommodation_dates": {
      "text": "יש להכניס את הימים שישנתם באותו המקום",
      "subText": "כדאי לאפשר לאחרים לדעת כמה לילות להזמין למקום זה יש צורך בהכנסת תאריך הכניסה והיציאה ממקום לינה זה",
      "modalsName": "dynamicModal",
      "action": "closePopup",
      "actionText": "סגירה"
    },
    "not_supporting_FB_or_instagram_browsers": {
      "text": "לא ניתן להתחבר דרך Google בדפדפן זה",
      "subText": "נראה שאתם משתמשים בדפדפן של Facebook או Instagram. נא לפתוח בדפדפן רגיל כמו Safari או Chrome",
      "modalsName": "dynamicModal",
      "action": "closePopup",
      "actionText": "סגירה"
    },
  }
};

const getters = {
  modals: state => state.modals,
  dynamicModal: state => state.dynamicModal,
  errorNumberConverter: state => state.errorNumberConverter,
  inerModalParameters: state => state.inerModalParameters,
  inerModalParametersWereUsedWorkAround: state => state.inerModalParametersWereUsedWorkAround,
  
};

const actions = {
  lockShowModalOnce({ state } ) {
    state.loked = true;
  },
  resetInerParametersIfNesesery({ state, commit } ) {
    if( state.inerModalParametersWereUsedWorkAround )
      commit('setInerModalParameters', null);
    else{
      state.inerModalParametersWereUsedWorkAround=true;
    }
  },

  showModal({ state, dispatch }, modal) {
    dispatch( 'resetInerParametersIfNesesery');
    if( state.loked ){
      if( modal == ""){
        state.loked = false;
        console.log( "releasing the modal locked")
      }
      return;
    }
    var modals = state.modals;
    for (const key in modals) {
      modals[key] = false;
    }
    if (!(modal in modals)&&(modal!='')) {
      console.error(`Error: The modal "${modal}" is not part of the available modals.`);
      return;  // Exit the function if the modal is invalid
    }
    modals[modal] = true;

  },

  showModalWithParameters({ state, commit, dispatch }, modalInfo) {
    var modals = state.modals;
    const { errorNumberConverter } = state;
    for ( const key in modals ) {
      modals[ key ] = false;
    }
    if( modalInfo.type=='textConverter')
    {
      if( modalInfo.errorNumber in errorNumberConverter ){
        var dynamicModalContaent = { ...errorNumberConverter[ modalInfo.errorNumber ] }
        if( modalInfo.errorNumber == "userLoggedInSuccessfully" ){
          dynamicModalContaent.text = dynamicModalContaent.textTemplate.replace('{addToText}', modalInfo.textToAdd)
        }
        commit( 'setDynamicModal', dynamicModalContaent );
        modals[ dynamicModalContaent.modalsName ] = true;
      }
      else{
        console.log( "This error is not define !!!!!!!!!!!!!!" )
      }
    }
    
    if( modalInfo.inerModalParameters)
    {
      state.inerModalParametersWereUsedWorkAround=false;
      commit('setInerModalParameters', modalInfo.inerModalParameters);
      if( modalInfo.type=='shareAttraction')
        dispatch( 'showModal', "shareRide" );
      else
        dispatch( 'showModal', modalInfo.type );
    }
  },
};

const mutations = {
  setModal: ( state, modal ) => {
    for (const key in state.modals) {
      state.modals[key] = false;
    }
    state.modals[modal] = true;  
  },
  setModals: ( state, modals ) => {
    state.modals = modals;
  },
  setDynamicModal: ( state, dynamicModal) => {
    state.dynamicModal = dynamicModal;
  },
  setInerModalParameters: ( state, inerModalParameters) => {
    state.inerModalParameters = inerModalParameters;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
