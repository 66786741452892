<template>
  <gmap-map
    ref="mapRef"
    :center="rideCenter"
    class="single_ride_map_design"
    :zoom="2"
  >
  </gmap-map>
</template>



<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'SingleRideMap',
  computed: mapGetters(['rideCenter']),
  methods: 
  {
    ...mapActions(['rideSetAppMap']),
  },
  mounted(){
    this.$refs.mapRef.$mapPromise.then((map) =>{
      this.$store.commit('rideSetAppMap', map);

    });    
  }
};
</script>
