<template>
  <div class="card border-0 shadow-sm rounded-5">
    <div class="card-body p-2 p-md-3 d-flex flex-column text-right gap-md-3 position-relative">
      <div>
        <div class="component-image-wrapper">
          <img :src="backgroundImage" class="component-image" alt="Image" />
        </div>
        <div
          v-if="date"
          class="d-flex align-items-center justify-content-between px-2 py-2 bg-light mt-3 rounded-pill"
        >
          <h5 class="mb-0 h5 mr-2">
            צולם ב-
          </h5>
          <div class="d-flex align-items-center">
            <span class="ml-2">{{ date }}</span>
            <DatePicker/>
          </div>
        </div>
        
        
        <div 
          v-if="isAddingAccommodationsFromImages"
          ref="datePickerRange"
        >
          <div dir="ltr" class="trip-input-field d-flex flex-column align-items-start p-3 shadow-sm rounded bg-light">
            <label for="date-picker" class="mb-2" style="font-size: 1.25rem;">
              אנא בחרו את תאריכי הצ'ק-אין והצ'ק-אאוט של המקום לינה
            </label>
            <div class="d-flex align-items-center w-100">
              <img
                class="coulunder_image mr-3"
                :src="require('@/assets/images/icons/icons8-edit-calendar-50.png')"
                alt="Calendar Icon"
                style="width: 24px; height: 24px; cursor: pointer;"
                @click="openCalendar"
              >
              <flat-pickr
                v-model="localDateDuraton"
                id="date-picker"
                ref="datePicker"
                class="border border-primary rounded-pill px-3 py-2 w-100 form-control datepicker text-right"
                placeholder="צ'ק אין   ←   צ'ק אאוט"
                :config="{ mode: 'range', dateFormat: 'Y:m:d' }"
              ></flat-pickr>
            </div>
          </div>        
        </div>
        <slot></slot>
        <div 
          v-if="openAichosenPlace&&openAichosenPlace.success&&openAichosenPlace.success=='true'"
          class="mt-4 text-center">
          <h5>{{"האים זה " + openAichosenPlace.displayName.text + "?"}}</h5>
          <div class="d-flex justify-content-between align-items-center detection-question-buttons">
            <YesButton 
              class="w-100"
              :callBack=yesCallBack
            >
              כן
            </YesButton>
            <NoButton
              class="w-100"
              :callBack=noCallBack
            >
              לא
            </NoButton>
          </div>
          אים זה לא המקום שקשר לתמונה, 
          <span 
            class="text-underline"
            @click="noCallBack"
          >
            בחרו ידנית את מקום התמונה
          </span>.
        </div>
        <div
          v-else
          class="mt-4 text-center alert-container">
          <div class="alert-icon">
            ⚠️
          </div>
          <p class="alert-message">
            לא הצלחתי לזהות את המיקום של התמונה.<br>
            נא לבחור אותו באופן ידני.
          </p>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
// i should add the file that we are importing

import DatePicker from "@/components/Buttons/DatePicker.vue";
import NoButton from "@/components/Buttons/NoButton.vue";
import YesButton from "@/components/Buttons/YesButton.vue";
import flatPickr from "vue-flatpickr-component";

export default {
  components: { YesButton,
                NoButton,
                DatePicker,
                flatPickr,
              },
  props: {
    backgroundImage: {
      type: String,
      required: true
    },
    openAichosenPlace: {
      type: Object,
      required: false,
      default: () => null,
    },
    date: {
      type: String,
      required: true
    },
    yesCallBack: {
      type: Function,
      required: true
    },
    noCallBack: {
      type: Function,
      required: true
    },
    isAddingAccommodationsFromImages: {
      type: Boolean,
      required: true
    },
    datesDuraton: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      localDateDuraton: this.datesDuraton, // Sync local data with prop
    };
  },
  watch: {
    localDateDuraton(newDate) {
      this.$emit('update-dates-duraton', newDate); // Emit event to parent on change
    },
  },
  methods: {
    openCalendar() {
      this.$refs.datePicker.fp.open(); // Programmatically open the calendar
    },
    focusDatePicker() {
      const section = this.$refs.datePickerRange; // Reference to the target section
      if (section) {
        const sectionOffset = section.offsetTop;
        
        window.scrollTo({
          top: sectionOffset,
          behavior: 'smooth',
        });
      }
      // this.openCalendar(); // Opens the picker, we canot open it and ass an popup
    },
  },
};
</script>

<style scoped>

.component-image-wrapper {
  display: flex; /* Enables flexbox for centering */
  justify-content: center; /* Horizontally centers the content */
  align-items: center; /* Vertically centers the content (optional if needed) */
  width: 100%; /* Wrapper spans full width */
  position: relative;
  border-radius: 20px;
  overflow: hidden; /* Ensures the image fits within rounded corners */
}

.component-image {
  width: 80%; /* Image is 80% of the wrapper's width */
  height: auto; /* Maintains aspect ratio */
  border-radius: inherit; /* Matches wrapper's border-radius */
  object-fit: cover; /* Ensures proper scaling */
  border-radius: 20px;
}

.card-body {
  gap: 24px;
}

.detection-question-buttons {
  gap: 12px;
}

.text-underline {
  text-decoration: underline !important;
  color: #07A0C3 !important;
}








/* we should test if  we can remove all of ( to the end )this!!!!!!!!!!!!!!!! */
.top-section {
  margin-top: 96px;
  padding-bottom: 24px;
}

.section-bullet {
  background-color: rgba(7, 160, 195, 0.2);
  color: #07A0C3;
  height: 42px;
  width: 42px;
  top: 16px;
  right: 16px;
  font-weight: 900;
}

.h4 {
  font-weight: 700;
}

.h2 {
  font-weight: 800;
}

.circle {
  height: 42px;
  width: 42px;
}

.gap-2 {
  gap: 16px;
}

.btn-mobile {
  width: auto;
}

@media (max-width: 575.98px) {
  .btn-mobile {
    width: 100%;
  }

  .gap-2 {
    gap: 6px;
  }
}

.alert-container {
  background-color: #fff4e5; /* Soft orange background */
  border: 1px solid #ff9800; /* Orange border */
  border-radius: 8px;
  padding: 16px;
  max-width: 500px;
  margin: 0 auto;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

.alert-icon {
  font-size: 24px; /* Large icon */
  margin-bottom: 8px;
  color: #ff9800; /* Orange color */
}

.alert-message {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 16px;
  color: #333; /* Dark text for readability */
}


</style>