import axios from 'axios';
import config from '../common/config';

export default {
  fetchAccommodationsTemp(token, rideId) {
    return axios.get(`${config.config.ROOT_URL}/ride_accommodations_api/${rideId}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  },
  
  deleteAccommodation( token, accommodationId )
  {
    return axios.get(`${config.config.ROOT_URL}/delete_accommodation_api/${accommodationId}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
  },

  updateAccommodation( token, accommodationId, accommodationInfo )
    {
      const formData = new FormData()
      formData.append('data', JSON.stringify(accommodationInfo));
      
      return axios.post(`${config.config.ROOT_URL}/update_accommodation_api/${accommodationId}`,formData, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'content-type': 'multipart/form-data'        }
      });
    },

    createAccommodation( token, rideId, accommodationInfo)
    {
      const formData = new FormData()

      formData.append('data', JSON.stringify(accommodationInfo));
      
      return axios.post(`${config.config.ROOT_URL}/create_accommodation_and_add_to_ride_api/${rideId}`,formData, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'content-type': 'multipart/form-data'        }
      });
    },

    addAccommodationFromImageToRide( token, accommodationMetadataList ) {
      const formData = new FormData()
      const rideId = accommodationMetadataList['ride_id']
      formData.append('data', JSON.stringify(accommodationMetadataList));
      return axios.post(`${config.config.ROOT_URL}/create_and_add_accommodation_from_image_to_ride_api/${rideId}`,formData, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'content-type': 'multipart/form-data'        }
      });
    },
  
   
};
