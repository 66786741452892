import axios from 'axios';
import config from '../common/config';

export default {

  sendImageMetaData( token, atractionsMetadataList )
    {
      const formData = new FormData()
      formData.append('data', JSON.stringify(atractionsMetadataList));
      return axios.post(`${config.config.ROOT_URL}/get_image_information_api`,formData, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'content-type': 'multipart/form-data'        }
      });
    },
};