<template>
<div>
  <b-modal 
    hide-footer
    centered 
    v-model="isVisible"
    modal-class="modal-cover"
    content-class="shadow-lg"
  >
    <section class="text-center">
      <b-row class="justify-content-center">
        <b-col cols="12" class="py-2">
          <h3 class="font-weight-medium card-title">{{getTexts()[0].text}}</h3>
        </b-col>
        <b-col cols="12" class="py-2">
          <p>
            {{getTexts()[1].text}}
          <br />
          </p>

      <h4 v-if="allRide && allRide.ride && allRide.ride.id" > {{getLinkUrl()}} </h4>
        </b-col>
      </b-row>
      <b-row class="justify-content-center mb-3">
        <!-- <b-col cols="auto"
          ><div
            class="width-50 height-50 rounded-circle d-flex align-items-center justify-content-center mb-2 ml-auto mr-auto shadow"
          >
            <b-img
              :src="require('@/assets/images/icons/icons8-link-24.svg')"
              alt="Image"
            ></b-img>
          </div>
          <p class="mt-3 mb-0" style="font-size: 15px; line-height: 20px">
            העתקת
            <br />
            קישור לשליחה
          </p>
        </b-col> -->
        <!-- <b-col cols="auto"
          ><div
            class="width-50 height-50 rounded-circle d-flex align-items-center justify-content-center mb-2 ml-auto mr-auto shadow"
          >
            <ShareNetwork
            network="email"
            :url=getLinkUrl()
            title="Say hi to Vite! A brand new, extremely fast development setup for Vue."
            description="This week, I’d like to introduce you to 'Vite', which means 'Fast'. It’s a brand new development setup created by Evan You."
          >
            <b-img
                :src="
                  require('@/assets/images/icons/icons8-secured-letter-50.svg')
                "
                alt="Image"
              ></b-img>
            </ShareNetwork>
          </div>
          <p class="mt-3 mb-0" style="font-size: 15px; line-height: 20px">
            שיתוף הקישור<br />
            בשליחת מייל
          </p></b-col
        > -->
      </b-row>

      <b-row class="justify-content-center">
        <b-col cols="auto" class="py-2 mx-2">
          
          <ShareNetwork
          network="facebook"
          :url=getLinkUrl()
          :title=getTexts()[4].title
          :description=getTexts()[4].description
          :quote=getTexts()[4].quote
          :hashtags=getTexts()[4].hashtags
        >
            <b-img
              :src="require('@/assets/images/icons/icons8-facebook-f-30.svg')"
              alt="Image"
            ></b-img>
          </ShareNetwork>
        </b-col>
        <!-- <b-col cols="auto" class="py-2 mx-2">
          <a :href="'https://web.whatsapp.com/send?text=http://tripplanner.co.il/single_ride_with_map/' + allRide.ride.id" target="_blank">
            <b-img
              :src="require('@/assets/images/icons/icons8-whatsapp-50.svg')"
              alt="Image"
            ></b-img>
          </a>
        </b-col> -->
        <b-col cols="auto" class="py-2 mx-2">
          <ShareNetwork
          network="whatsApp"
          :url=getLinkUrl()
          :title=getTexts()[3].title
          :description=getTexts()[3].description
          >
            <b-img
              :src="require('@/assets/images/icons/icons8-whatsapp-50.svg')"
              alt="Image"
            ></b-img>
          </ShareNetwork>  
        </b-col>
        <b-col cols="auto" class="py-2 mx-2">
          <ShareNetwork
          network="telegram"
          :url=getLinkUrl()
          :title=getTexts()[2].title
          :description=getTexts()[2].description
          >
            <b-img
              :src="require('@/assets/images/icons/icons8-telegram-app-48.svg')"
              alt="Image"
            ></b-img>
          </ShareNetwork>  
        </b-col>
      </b-row>
    </section>
  </b-modal>
</div>
</template>
<script>

import { mapGetters } from "vuex";

export default {
props: ["value"],
  data(){return{
    shareRideNetworkingText: [
          
          {
            type: 'header',
            text: "שליחת הטיול לחברים"

          },
          {
            type: 'text',
            text: "האדם הצופה בטיול זה לא יוכל לערוך או לשנות את הטיול שלכם,\
            הוא יוכל רק לצפות בטיול ואים הוא ממש יאהב הוא יוכל להעתיק את הטיול ולערוך אחד אחר עבורו"

          },
          {
            network: "telegram",
            title: "בנית טיול משפחתי.",
            description: "יש כאן בסיס לטיול שניתן להתאים לרצונות שלנו",
          },
          {
            network: "whatsApp",
            title: "בנית טיול משפחתי.",
            description: "יש כאן בסיס לטיול שניתן להתאים לרצונות שלנו",
          },
          {
            network: "facebook",
            title: "בנית טיול משפחתי.",
            description: "יש כאן בסיס לטיול שניתן להתאים לרצונות שלנו",
            quote: "יש כאן טיול מגניב שניתן להעתיק אליכם לחשבון ואז לערוך אותו",
            hashtags: "tripplanner",
          },
        ],
    shareAtractionNetworkingText: [
          
          {
            type: 'header',
            text: "שליחת הפעילות לחברים"

          },
          {
            type: 'text',
            text: "האדם הצופה בטיול זה לא יוכל לערוך או לשנות את הטיול שלכם,\
            הוא יוכל רק לצפות בטיול ואים הוא ממש יאהב הוא יוכל להעתיק את הטיול ולערוך אחד אחר עבורו"

          },
          {
            network: "telegram",
            title: "בנית טיול משפחתי.",
            description: "יש כאן בסיס לטיול שניתן להתאים לרצונות שלנו",
          },
          {
            network: "whatsApp",
            title: "בנית טיול משפחתי.",
            description: "יש כאן בסיס לטיול שניתן להתאים לרצונות שלנו",
          },
          {
            network: "facebook",
            title: "בנית טיול משפחתי.",
            description: "יש כאן בסיס לטיול שניתן להתאים לרצונות שלנו",
            quote: "יש כאן טיול מגניב שניתן להעתיק אליכם לחשבון ואז לערוך אותו",
            hashtags: "tripplanner",
          },
        ],
  }
  },





  computed: {
    ...mapGetters([ 'allRide',
                    'inerModalParameters',
                     ]),
    isVisible: {
      set(val) {
        this.$emit("input", val);
      },
      get() {
        return this.value;
      },
    },
  },
  methods:{
    getLinkUrl(){
      if(this.inerModalParameters){
        if ( this.allRide && this.allRide.ride && this.allRide.ride.id ){
          return ( "https://tripplanner.co.il/single_ride_with_map_attraction/" + this.allRide.ride.id + "/" + this.inerModalParameters.attractionId )
        }
        return "https://tripplanner.co.il"
      }
      else{
        if ( this.allRide && this.allRide.ride && this.allRide.ride.id ){
          return ( "https://tripplanner.co.il/single_ride_with_map/" + this.allRide.ride.id )
        }
        return "https://tripplanner.co.il"
      }
    },
    getWhatsappUrl(){
      if(this.inerModalParameters){
        if ( this.allRide && this.allRide.ride && this.allRide.ride.id ){
          return "https://web.whatsapp.com/send?text=https://tripplanner.co.il/single_ride_with_map_attraction/" + this.allRide.ride.id + "/" + this.inerModalParameters.attractionId
        }
        return "https://web.whatsapp.com/send?text=https://tripplanner.co.il"
      }
      else{
        if ( this.allRide && this.allRide.ride && this.allRide.ride.id ){
          return "https://web.whatsapp.com/send?text=https://tripplanner.co.il/single_ride_with_map/" + this.allRide.ride.id
        }
        return "https://web.whatsapp.com/send?text=https://tripplanner.co.il"
      }
    },
    getTexts(){
      if( this.inerModalParameters) {
        return this.shareAtractionNetworkingText
        }
      else{
        return this.shareRideNetworkingText
      }
    },

  },

};
</script>
<style scoped>
.modal.modal-cover .modal-content,
.modal.modal-cover .modal-footer,
.modal.modal-cover .modal-header {
  border: none;
}
.height-50 {
  height: 50px !important;
}
.width-50 {
  width: 50px !important;
}
</style>