<template>
  <div class="position-relative overflow-hidden">
    <AppNavbar/>
    <div>
      <img
        alt=""
        class="background-image" 
        src="https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/page_images%2Fcircles-background.png?alt=media&token=d420088e-66c6-4c95-bd3b-2f218dd36aba">
    </div>

    <div class="accommodations">
      <ImageBanner 
        :backgroundImage= "'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/page_images%2Fadd-accommodation-banner.jpg?alt=media&token=74b4cf93-649d-4b32-9268-61e459cbd48d'"
        paragraph="נתחיל בהעלאת התמונות. כמו בתהליך הקודם, יש להעלות תמונה אחת מכל המקומות שבהם ישנתם. אם המלון שלכם לא היה מומלץ, מומלץ להעלות תמונה מהאזור ולציין באיזה אזור כדאי לישון במקום לציין את שם המלון."
        size="100%"
        title="הוספת מקום לינה - כך שמי שיעתיק מכם את הטיול יוכל לדעת באיזה אזור מומלץ לישון ולקבל המלצה.">
        <PrimaryButton
          @click="triggerAccommodationFileInput"  
        >
          הוספת מקומות לינה
        </PrimaryButton>
      </ImageBanner>

      <input 
        type="file"
        multiple
        @change="onFileChange"
        ref="accommodationFileInput"
        style="display: none;" 
      > 
    </div>
    <AppFooter/>
  </div>
</template>

<script>
import ImageBanner from "@/components/ImageBanner/ImageBanner.vue";
import AppNavbar from "@/components/Template/AppNavbar.vue";
import PrimaryButton from "@/components/Buttons/PrimaryButton.vue";
import AppFooter from "@/components/Template/AppFooter.vue";

import { fileHandlingMixin } from "@/mixins/fileHandlingMixin";
import { mapActions, mapGetters } from "vuex";
import { UPLOAD_RIDE_STATES } from '@/constants/uploadRideStates.js';


export default {
  mixins: [fileHandlingMixin], // Include the mixin
  components: { PrimaryButton,
              ImageBanner,
              AppNavbar,
              AppFooter,
            },
  computed: {
    ...mapGetters(['atractionsMetadataList', 'isAppLoggedIn']),
  },
  methods: {
    ...mapActions([ "addAccommodationsfromImages",
                    "showModal",
                    "showModalWithParameters",
                    "callAllImagesApi",
                  ]),
    
    triggerAccommodationFileInput() {
      this.$refs.accommodationFileInput.click(); // Triggers the hidden file input's click event
    },
    
    onFileChange(event) {
      if (event.target.files.length > 0) {
        this.files = event.target.files;

        // Pass the addAccommodationsfromImages function as the callback
        this.uploadFiles(() => {
          this.callAllImagesApi();
          this.addAccommodationsfromImages();
        }, 'accommodation');
      } else {
        console.log("No files selected");
      }
    },
  },

  created() {
    // we will change it after the user will want to add ride from the trip
    const rideIdFromUrl = this.$route.params.ride_id;
    if (rideIdFromUrl) {
      this.$store.commit("setIsFirstTimeAddingImages", false);
      this.$store.commit("setUploadRideIdFromImage", rideIdFromUrl);
    } else {
      this.$store.commit("setIsFirstTimeAddingImages", true);
      // this.$store.commit("setUploadRideIdFromImage", null);
    }
    this.$store.commit("setUploadRideState", UPLOAD_RIDE_STATES.UPLOADING_ACCOMMODATIONS_IMAGE );

    if (!this.isAppLoggedIn) {
      this.showModal("pleaseLogInInOrderToEditRide");
    }
  },
};
</script>

<style scoped>
.accommodations {
  height: calc(100dvh - 360px) !important;
}

@media (max-width: 575.98px) {
  .accommodations {
    height: calc(100dvh - 80px) !important;
  }
}
</style>