// import qs from 'qs';
import axios from 'axios';
import config from '../common/config';

export default {
  authenticate(email, password=null, userToken=null, provider=null) {
    const formData = new FormData();
    formData.append('email', email);
    formData.append('password', password);
    if( provider=="google")
      formData.append('googleIdToken', userToken);
    else if ( provider=="face-book")
      formData.append('facebookAccessToken', userToken);
    return axios.post(`${config.config.ROOT_URL}/app_login`,formData);

  },
  registerNewUser(email, password, userName) {
    const formData = new FormData();
    formData.append('email', email);
    formData.append('password', password);
    formData.append('userName', userName);
    return axios.post(`${config.config.ROOT_URL}/register_new_user_api`,formData);
  }
    // window.location = `${config.config.ROOT_URL}/app_login?${qs.stringify(
    //   querystring
    //   )}`;
    // }
  // login(username, password) {
  //   const querystring = {
  //     username: username,
  //     passsword: password
  //   };
  //   window.location = `${config.config.ROOT_URL}/app_login?${qs.stringify(
  //     querystring
  //   )}`;
  // }
};
