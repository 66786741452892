import axios from 'axios';
import config from '../common/config';

export default {
  fetchGlobalRides( filters ) {
    const formData = new FormData()
    formData.append('data', JSON.stringify( filters ) );
    return axios.post(`${config.config.ROOT_URL}/global_rides_api`, formData,);
  },

  fetchallSerchpoints(filters) {
    const formData = new FormData()
    formData.append('data', JSON.stringify( filters ) );
    return axios.post(`${config.config.ROOT_URL}/get_all_sertch_point`, formData, );
  },


};
