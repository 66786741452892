<template>
  <div>
    <h3 class="text-center mb-md-5 mb-4 fw-bold fs-1">Our Collaborators</h3>
    <div class="row align-items-center justify-content-center">
      <div v-for="(collaborator, index) in collaborators" :key="index" :class="collaborator.colClass">
        <img :alt="collaborator.alt" :class="collaborator.logoClass" :height="collaborator.height" :src="collaborator.src">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CollaboratorsWith',
  data() {
    return {
      collaborators: [
        {
          alt: 'Google Logo',
          logoClass: 'google-logo',
          height: '80px',
          src: 'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/page_images%2Fwaze-logo-1400x444.png?alt=media&token=ba31f446-0eba-4560-9850-fc8784e519e8',
          colClass: 'col-lg-3 col-6 mb-3'
        },
        {
          alt: 'Skyscanner Logo',
          logoClass: 'skyscanner-logo',
          height: '50px',
          src: 'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/page_images%2Fbooking-logo.png?alt=media&token=59c22950-fa84-44a9-86e8-80b1410cc3e0',
          colClass: 'col-lg-3 col-6 mb-3'
        },
        {
          alt: 'Booking Logo',
          logoClass: 'booking-logo',
          height: '50px',
          src: 'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/page_images%2Fskyscanner-logo.png?alt=media&token=56954b1b-026d-4d7f-8c5f-a71d03c945e8',
          colClass: 'col-lg-3 col-6 mb-3'
        },
        {
          alt: 'Waze Logo',
          logoClass: 'waze-logo',
          height: '120px',
          src: 'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/page_images%2Fgoogle-logo.png?alt=media&token=598d5fab-e79e-4c70-8bef-a146f6d22e66',
          colClass: 'col-lg-3 col-6 mb-3'
        }
      ]
    };
  }
};
</script>

<style scoped>
@media (min-width: 1200px) {
    .fs-1 {
        font-size: 4rem !important;
    }
}
@media (min-width: 768px) {
    .mb-md-5 {
        margin-bottom: 4.8rem !important;
    }
}
.text-center {
    text-align: center !important;
}
.fw-bold {
    font-weight: 700 !important;
}
.fs-1 {
    font-size: calc(2.2rem + 1.5vw) !important;
}
.mb-4 {
    margin-bottom: 2.4rem !important;
}


.align-items-center {
    align-items: center !important;
}
.justify-content-center {
    justify-content: center !important;
}
.row {
    --bs-gutter-x: 2.4rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1* var(--bs-gutter-y));
    margin-right: calc(-.5* var(--bs-gutter-x));
    margin-left: calc(-.5* var(--bs-gutter-x));
}
.align-items-center {
    align-items: center !important;
}
.justify-content-center {
    justify-content: center !important;
}


</style>
