<template>
  <div class="about" style="position: relative">
    <section
      class="d-flex align-items-center"
      style="min-height: calc(100vh - 150px)"
    >
      <b-container>
        <b-row>
          <b-col
            class="text-right"
            style="
              font-style: normal;
              font-weight: normal;
              font-size: 13px;
              line-height: 20px;
              letter-spacing: 0.045em;
            "
          >
            <h4 class="font-weight-bold">תנאי שימוש ותקנון פרטיות</h4>
            <p>
              .
              טריפ פלנר 1/1/2020
            </p>
            <p>
 כללי
            </p>
            <p>

השימוש באתר טריפ פלנר מותנה בהסכמה לתנאים המפורטים להלן. אנא קרא תנאים אלה בעיון. בכניסתך לאתר הינך מאשר את הסכמתך לאמור בתנאים אלה.
במדורים שונים באתר, כגון בקבוצות דיון (פורומים), בלוגים וכו' יימצאו תנאי שימוש נוספים, המתייחסים לאותו מדור. השימוש במדורים אלו כפוף הן לתנאי השימוש שבאותו מדור והן לתנאי השימוש שלהלן.
תנאים אלה חלים על השימוש באתר ובתכנים הכלולים בו באמצעות מחשב או כל אמצעי תקשורת אחר, לרבות טלפון סלולארי, טאבלט וכיוצ"ב.
תנאי השימוש באתר מנוסחים בלשון זכר מטעמי נוחות בלבד, אך הם מיועדים גם לנשים.
            </p>
            <p>

בתקנון זה
 "האתר" או "אתרי טריפ פלנר" – אתר "טריפ פלנר" שכתובתו tripplanner.co.il , לפי הענין "החברה" – טריפ פלנר המפעילה את אתרי "טריפ פלנר"' שכתובתה בסלעית
"תוכן/ תכנים" מידע מכל סוג שהוא, לרבות תוכן מילולי, חזותי, אור קולי או כל שילוב שלהם, ובכלל זה תמונה, צילום, איור, תרשים, שרטוט, ציור, סרטון, קובץ קולי או מוזיקלי, תוכנה, יישום, כתבות, סקירות, נתונים, ידיעות, הערכות וכן כל תו, סמל, סימן או צלמית ((icon, וכיוצ"ב תכנים המועברים או העומדים לרשות גולשים באתר.
            </p>
            <p>

זכויות באתר
            </p>
            <p>
כל הזכויות בתכנים המופיעים באתר אשר מקורם בחברה ו/או מטעמה, לרבות זכויות יוצרים, זכויות הפצה וכל קניין רוחני אחר, שייכים במלואם לחברה. מבלי לגרוע מהאמור לעיל, כל הזכויות בעיצוב האתר ובאופן העיבוד וההצגה של התכנים באתר שייכות במלואן לחברה.
התכנים באתר נועדו לשימושו האישי של המשתמש בלבד, ואין לעשות בהם שימוש אחר כלשהו, לרבות שימוש מסחרי. אין להעתיק, לשכפל, להעתיק ו/או להדפיס תכנים כלשהם מהאתר לצורך הפצה או פרסום בדרך כלשהי, בלא קבלת הסכמתה של החברה, בכתב ומראש.
סימני המסחר באתר שבבעלות יחידים או תאגידים בקבוצת "טריפ פלנר" הינם קניינם של בעליהם הרשומים. סימני מסחר המוצגים באתר מטעמם של מפרסמים כלשהם הינם קניינם של מפרסמים אלה בלבד. אין לעשות שימוש בסימני מסחר בלא הסכמת בעליהם, מראש ובכתב.
            </p>
            
            <p>

שימוש באתר
            </p>
            <p>

השירותים והתכנים באתר ניתנים לשימוש כמות שהם (AS IS) בהתאם להחלטת החברה, ולא תהיה למשתמש כל טענה, תביעה או דרישה כלפי החברה בגין תכונות של השירותים ו/או התכנים באתר, יכולותיהם, מגבלותיהם, התאמתם לצרכי המשתמש, או התגובות שיעורר הפרסום באתר.
השימוש בתכנים המוצגים באתר ובשירותי האתר, ייעשה על אחריותו הבלעדית והמלאה של המשתמש. החברה לא תישא בכל אחריות ביחס לתכנים המתפרסמים באתר - תוכנם, מהימנותם, דיוקם, אמינותם והשפעתם על המחשבים של המשתמשים באתר ולא תהא אחראית לכל נזק, הפסד, אובדן רווח, אי נוחות, תשלום, הוצאה, עגמת נפש וכיוצא באלו תוצאות, ישירות או עקיפות, שייגרמו למשתמש או לכל צד שלישי בשל שימוש בתכנים המתפרסמים באתר.
ידוע למשתמש כי תכנים המוצגים באתר בכלל, ותכנים שמקורם במשתמשים אחרים בפרט, אינם בגדר ייעוץ מקצועי. החברה אינה אחראית לתכנים אלו ולתוצאות, ישירות או עקיפות, העלולות להיגרם למשתמש או לצד שלישי כלשהו עקב פעולה בהתבסס על תכנים אלו. על המשתמש חלה אחריות המלאה והבלעדית לפעולותיו ולתוצאותיהן.
הקישורים (links) לאתרים אחרים מתוך האתר נועדו לנוחיות המשתמש בלבד, ואין החברה אחראית לתוכנם של אתרים אלה, דיוקם, מהימנותם, חוקיותם או תקינותם. הכניסה לאתרים כאמור והשימוש בתכנים המופיעים בהם הינם באחריות המשתמש בלבד. המצאות קישור לאתר מסוים אינה מהווה אישור לכך שהאתר פעיל או שהמידע באתר זה אמין, מהימן, מלא או מעודכן.
מבלי לגרוע באמור לעיל החברה לא תהא אחראית לכל נזק, עקיף או ישיר שייגרם למשתמש כתוצאה משימוש או הסתמכות על מידע או תכנים המתפרסמים באתרים כאמור ו/או בגין שימוש או הסתמכות על מידע ותכנים המתפרסמים באתר ע"י צדדים שלישיים.
            </p>
            <p>

הרשמה לקבלת שירותים
            </p>
            <p>

חלק מהשירותים המוצעים באתר טעונים הרשמה באמצעות האתר או באמצעות חשבון "facebook" של המשתמש, במסגרתם יתבקש המשתמש למסור נתונים אישיים כגון שם מלא, כתובת, דרכי התקשרות ובכלל זה כתובת דוא"ל. ייתכן שיידרשו פרטים נוספים, בהתאם לאופי השירות.
חיבור לאתר באמצעות "facebook" עשוי להעביר למאגר המידע מידע על המשתמש כפי שמסר במסגרת הליך ההרשמה, ובכפוף לנהלי "facebook", כגון שם המשתמש, תמונה, מגדר, גיל או טווח גילאים, כתובת דוא"ל, תחומי ענין ומידע אחר. מסירת מידע על ידי משתמש אינה חובה על פי חוק, אך מהווה תנאי להרשמה לשירותים.
            </p>
            <p>
הגנה על פרטיות, שימוש במידע
            </p>
            <p>
ידוע למשתמש כי השימוש באתר עשוי להביא לאיסוף מידע ונתונים אודותיו, אודות הפעילות שביצע באמצעות האתר או אתרים המקושרים לאתר כגון, עיון בעמודים, מדורים או ממשקים ספציפיים, תמונות, תגובות, שיתופים לאתרים או פלטפורמות אחרות על חומרים באתר, תחומי ענין של המשתמש, מוצרים ושירותים שרכש, מיקום המחשב באמצעותו ניגש לאתרים וכיוצ"ב.
נתונים או מידע הנמסרים על ידי משתמש בעת הרשמה לשירותים באתר, או שנתקבלו מאתר "facebook" יישמרו במאגר המידע של החברה. החברה מתחייבת שלא להעביר מידע זה לצד שלישי כלשהו לרבות מפרסמים או גורמים מסחריים, אלא בכפוף לאמור להלן וללא זיהוי המשתמש, ולעשות שימוש במידע כאמור בכפוף לתנאי תקנון זה ועל פי הוראות הדין.
מבלי לגרוע מהאמור לעיל, החברה תהא רשאית לעשות שימוש במידע כאמור או בנתונים שימסור משתמש בעת ההרשמה למטרות שלהלן:
כדי לאפשר למשתמש לעשות שימוש בשירותים המוצעים באתר;
לשיפור השירות והתכנים המוצעים באתר, ובכלל זה ביטול ושינוי תכנים ושירותים. המידע עשוי לשמש כמידע סטטיסטי, ללא זיהוי המשתמש;
ליצירת אזורים אישיים באתר;
לצורך צריכה או רכישת מוצרים ושירותים באתר;
לצורך התאמת המודעות שיוצגו בפניך בגלישה באתר;
ליצירת קשר עם המשתמש;
לתפעולם התקין ולפיתוחם של אתרי טריפ פלנר;
לכל מטרה אחרת המפורטת בתנאי השימוש של איזה מהשירותים המוצעים באתרי טריפ פלנר.
על פי חוק הגנת הפרטיות, תשמ"א-1971 ("חוק הגנת הפרטיות"), כל אדם רשאי לעיין במידע שעליו המוחזק במאגר מידע ורשאי לדרוש ששמו ו/או המידע המתייחס אליו יימחק מהמאגר. אדם שעיין במידע שעליו ומצא כי אינו נכון, שלם, ברור או מעודכן, רשאי לפנות לבעל מאגר המידע בבקשה לתקן את המידע. ניתן לפנות לאתר בבקשה לעיון במידע כאמור באמצעות "צור קשר". החברה רשאית לשנות את מדיניות הגנת הפרטיות שלה, בכפוף להוראות החוק.
            </p>
            <p>
אגרות מידע ודיוור ישיר:
            </p>
            <p>
האתר עוסק, בין היתר, במשלוח אגרות מידע (ניוזלטר) בנושאים שונים בתחומי הפעילות של אתרי "טריפ פלנר" - טיילים, תיירות, נסיעות, אתרי טבע, תרבות וכיוצ"ב, הכוללים גם מידע בעל תוכן שיווקי על שירותים וטובין, וכן דיוור של חומר פרסומי.
אגרות המידע (ניוזלטר) של "טריפ פלנר" הן: ניוזלטר אתר "טריפ פלנר"; ניוזלטר דילים והצעות מיוחדות.
בהתאם להוראות החוק, אגרות המידע הכוללות חומר פרסומי, נשלחות אך ורק לגולשים מנויים, שנתנו את הסכמתם לכך במפורש, בין בטופס ההרשמה באתר "טריפ פלנר" ובין באמצעות אתר ."facebook"
כל משתמש שנרשם כמנוי לקבלת דיוור מהאתר רשאי בכל עת לבטל את הסכמתו ולחדול מקבלת דיוור מהאתר, באמצעות הודעה מתאימה למפעילי האתר. הסרת שמו של מנוי מרשימת דיוור של "טריפ פלנר" יכול שתיעשה בכל עת באמצעות הפרופיל האישי של המשתמש, באמצעות לחיצה על קישור ייעודי במסגרת הדיוור, או באמצעות פניה ישירה לצוות האתר בטל., בפקס. או בקישור ייעודי.
האתר רשאי לעשות שימוש במידע שנמסר על ידי המשתמש ו/או במידע שנאסף או שהופק מאיסוף נתונים על המנוי כאמור למטרות שיווקיות, לרבות משלוח חומר פרסומי ב"דיוור ישיר" כמשמעו בחוק הגנת הפרטיות, קרי, פניה אישית לנמען בהתבסס על השתייכותו לקבוצת אוכלוסין על פי איפיון אחד או יותר של בני אדם ששמותיהם כלולים במאגר המידע. המשתמש רשאי בכל עת לבטל הסכמתו ולחדול מקבלת דיוור ישיר, ומוותר על טענה או תביעה בענין זה.
נדרשה החברה למחוק ממאגר המידע שמו של אדם או מידע המתייחס אליו, תמחק החברה את המידע הדרוש לפניה אליו, והמידע לא ישמש לפניה למשתמש. עם זאת, החברה תהא רשאית לשמור מידע הדרוש לה לעסקיה ובכלל זה תיעוד פעולות מסחריות שביצע. תיקון או מחיקה של מידע על פי בקשת משתמש או סירוב לעשות כן ייעשו על פי תקנות מכח חוק הגנת הפרטיות.
            </p>
            <p>
תכנים באתר שמקורם בגולשים

            </p>
            <p>
באתר מוצגים תכנים, המלצות, תמונות ותגובות שנמסרו לאתר ע"י גולשים לצורך הצגתם באתר במסגרת כתבות, טיפים, דיווחים, קבוצות דיון (פורומים), בלוגים, תגובות לכתבות (טוק בקים), הודעות חיפוש שותפים, תחרויות וכד'.
אין לפרסם באתר תכנים כלשהם לרבות טקסטים, הודעות, תמונות או קישורים שהינם אחד מאלה:
כל תוכן שידוע לגולש המבקש להעבירו לאתר שהוא שקרי, מטעה או מסולף או הנחזה על פניו להיות שקרי, מטעה או מסולף;
תכנים הפוגעים ברגשות הציבור, או המהווים הוצאת לשון הרע, או המהווים הפרה כלשהי של חוק, או המעודדים ביצוע עבירה או עוולה או עלול להוות בסיס לתביעה או אחריות אזרחית.
כל תוכן פוגעני, מעליב, עויין או מאיים;
כל תוכן פורנוגרפי או בעל אופי מיני בוטה;
כל תוכן שיש בו כדי לעודד גזענות או אפליה פסולה, לרבות על בסיס מין, לאום, גזע, צבע עור, מוצא, עדה, נטייה מינית, אמונה או השקפה פוליטית, נכות או עיסוק;
תכנים אשר הזכויות בהם אינן שייכות למעביר התוכן או שיש בהם כדי לפגוע בזכויות קנייניות של אחרים, לרבות זכויות יוצרים, סימני מסחר וכיוצ"ב.
תכנים וכל תוכנת מחשב, קוד או יישום הכוללים וירוס מחשב כלשהו, וכן תכנים עוינים הידועים כ"סוס טרויאני", תולעים ("worms"), יישומים מזיקים וכיוצ"ב .
תכנים בעלי אופי מסחרי ו/או המכילים מידע פרסומי כלשהו.
כל תוכן הנוגע לקטין או מזהה אותו, פרטיו האישיים, מענו או דרך התקשרות עמו.
כל תוכן שיש בו כדי לזהות אישית אנשים אחרים תוך פגיעה בפרטיותם.
אין לפרסם באתר מודעות או כל תוכן אחר שנועדו לקדם נושאים פוליטיים או שמקורם בגופים פוליטיים. החברה רשאית להוריד בכל עת כל מודעה או תוכן הנושאים אופי פוליטי לפי שיקול דעתה המוחלט, והמפרסם מוותר על כל טענה בענין זה.
החברה ו/או מי מטעמה רשאים לסרב לפרסם תכנים אשר לדעתה מפרים את התנאים שלעיל, כולם או חלקם, ו/או עלולים לפגוע באתר ו/או בצד שלישי כלשהו, והיא רשאית למחוק תכנים כאמור בכל עת ולמנוע מהמפרסם העברת תכנים נוספים לאתר.
החברה רשאית לערוך כל תוכן המועבר לאתר, ובכלל זה לקצרו.
גולשים השולחים תכנים למערכת טריפ פלנר לצורך הצגתם במסגרת האתר, לרבות צילומים או תמונות, מצהירים בעצם פנייתם לאתר או בהעברת התכנים לאתר כי:
כל החומרים אשר נשלחים לאתר הם באחריותם המלאה, מדוייקים ונכונים במידת האפשר וללא הפרות זכויות קנייניות, לרבות זכויות יוצרים, כלשהן.
גולש שהינו בעל זכויות היוצרים בתוכן המועבר על ידיו לאתר, מסכים בעצם העברתו לאתר למוסרו לפרסום ולעשות בו כל שימוש, לרבות אכסונו, פרסומו, עריכתו, הסרתו וכיוצ"ב.
גולש שאינו בעל זכויות היוצרים בתוכן המועבר על ידיו לאתר מצהיר כי הוא בעל הרשאה כדין למסור את התוכן לפרסום באתר ולעשות בו כל שימוש, לרבות אכסונו, פרסומו, עריכתו הסרתו וכיוצ"ב.
גולש המעביר תוכן לאתר מתיר למערכת האתר להשתמש בתוכן לכל מטרה שהיא ובכל דרך שהיא, ומוסר לאתר את כל הזכויות ביחס לתוכן, לרבות זכות בלתי מוגבלת בזמן ובלתי חוזרת, לשימוש ופרסום בתוכן באתרים אחרים בקבוצת "טריפ פלנר" ובפרסומים אחרים, והכל ללא תמורה, תשלום או תמלוגים כלשהם.
מערכת האתר רשאית בכל עת על פי שיקול דעתה הבלעדי לפסול, לשנות, לערוך או להסיר כל תוכן המועבר לאתר, בין לפני פרסומו ובין לאחר פרסומו, מבלי שתחול עליה חובה לעשות כן או להודיע על כך מראש.
בעצם העברת התכנים לאתר מוותר הגולש על כל טענה בקשר עם זכותו המוסרית ביחס לתכנים וכן על כל דרישה או תביעה כספית או אחרת בקשר עם האמור לעיל.
ידוע לגולש כי תכנים הנמסרים על-ידיו לפרסום באתר יהיו חשופים למשתמשים אחרים, ולא ניתן לצפות אילו תגובות יתקבלו ואת אופיין. החברה לא תישא באחריות כלשהי לתגובות כלשהן לתכנים באתר לרבות לתכנים מטעם גולשים או לכל תוצאה או נזק שייגרמו עקב פרסום תכנים או תגובות לתכנים באתר.
ידוע לגולש כי עליו לנהוג משנה זהירות במסירת פרטים אישיים, כגון כתובות מספרי טלפון, דוא”ל וכיוצ"ב. למען הסר ספק, החברה אינה אחראית לתכנים הנמסרים לאתר על ידי גולשים, ואינה מתחייבת כי הם מלאים, נכונים מדויקים או עונים על ציפיות המשתמש. תכנים אלה אינם מבטאים את עמדת מערכת האתר, ואין בפרסומם כל ערובה לאמינותם, דיוקם, תקפותם או חוקיותם. החברה אינה אחראית לשימוש שייעשה ע"י צד שלישי כלשהו בתכנים שיפורסמו ע"י משתמש באתר.
הגולש מוותר על כל טענה או תביעה נגד החברה או מי מטעמה בכל הקשור להצגתו/ או לשימוש בתוכן שמקורו בגולש באתר.
באשר לפרסום במדורי "מחפשים שותפים", הפורומים והבלוגים, יש לקרוא גם את סעיפים 5, 6 ו-7 שלהלן, בהתאמה.
            </p>
            <p>
פרסומות ומידע מסחרי
            </p>
            <p>
באתר תכנים המהווים פרסומות ומידע מסחרי, מטעמם של מפרסמים שונים. החברה אינה אחראית לתוכן הפרסומות, המודעות המידע הפרסומי וההצעות המסחריות המתפרסמים באתר או לכל תוצאה עקב הסתמכות עליהם או שימוש בהם. האחריות הבלעדית לתוכן הפרסומי והמסחרי שבאתר חלה על המפרסמים. אין בהצגתם באתר משום המלצה או עידוד מטעם האתר לרכישת שירותים או מוצרים המפורסמים באתר. החברה אינה צד לעסקה כלשהי הנקשרת עקב פרסום באתר .
החברה רשאית למנוע פרסום, שיווק או קידום באתר של בתי עסק, חנויות, חברות ומותגים המתחרים בקבוצת "טריפ פלנר", לרבות חנויות וירטואליות המופעלות מחוץ לישראל, אתרי אינטרנט מתחרים, מבטחי נסיעות מתחרים, וכל מתחרה אחר בפעילויותיה המסחריות של קבוצת "טריפ פלנר".
החברה רשאית לעשות שימוש בזכות זו גם ביחס לתוכן גולשים במערכת הפורומים או הבלוגים.
            </p>
            <p>
שירותים של צדדים שלישיים

            </p>
            <p>
החברה רשאית להעמיד לרשות גולשים באתר שירותים המנוהלים על ידי צדדים שלישיים, המוגשים באמצעות מחשביהם של אותם שותפים ולא ממחשבי החברה. במהלך השימוש בשירותים אלה עשוי המשתמש להתבקש למסור פרטים אישיים, וייתכן שייאסף לגביך מידע. השימוש במידע זה כפוף למדיניות הפרטיות של מנהלי שירותים אלה ולכן מומלץ שתעיין גם במסמכי מדיניות הפרטיות שלהם.
 החברה עשויה להיעזר בגופים לצורך קבלת ניתוחים סטטיסטיים אודות השימוש באתרי טריפ פלנר. גופים אלה אוספים ומנתחים מידע על היקף השימוש באתר, תדירות השימוש בו, מקורות הגישה של המשתמשים לאתר וכיוצא בזה. המידע הנאסף הוא סטטיסטי במהותו, הוא איננו מזהה את הגולש אישית ונועד לצרכי ניתוח, מחקר ובקרה.
            </p>
            <p>
Cookies ו- Web Beacons
            </p>
            <p>

אתרים משתמשים ב'עוגיות (Cookies) 'לצורך תפעולם השוטף והתקין, ובכלל זה כדי לאסוף נתונים סטטיסטיים אודות השימוש באתרים, לאימות פרטים, כדי להתאים את האתרים להעדפות האישיות של המשתמש ולצרכי אבטחת מידע. 'עוגיות' (Cookies) הן קבצי טקסט, שהדפדפן של הגולש יוצר לפי פקודה ממחשב. חלק מהעוגיות יפקעו בעת סגירת הדפדפן ואחרות נשמרות על גבי הכונן הקשיח במחשב הגולש. לדוגמה, במערכת ההפעלה "חלונות" ובדפדפן מסוג אינטרנט אקספלורר של מייקרוסופט, ניתן למצוא אותם בספריה c:windows.cookies וכן ב- c:windows.Temporary Internet Files.
ה-Cookies מכילים מידע מגוון כדוגמת הדפים שבהם ביקר הגולש, משך הזמן ששהה באתרים, מהיכן הגיע אל האתרים, מדורים ומידע שהגולש מבקש לראות בעת הכניסה לאתרים ועוד. הם משמשים גם כדי לייתר את הצורך בהזנת פרטי הגולש בכל פעם שהגולש מבקר מחדש במדורים באתר המחייבים רישום. המידע ב- Cookies מוצפן, והחברה נוקטת צעדי זהירות כדי להבטיח שמחשביה יוכלו לקרוא ולהבין את המידע האגור בהם.
גולש שאינו רוצה לקבל Cookies יכול להימנע מכך על ידי שינוי ההגדרות בדפדפן. לשם כך הוא מוזמן להיעזר בקובץ העזרה של הדפדפן. עם זאת, נטרול העוגיות עלול לגרום לכך שלא ניתן יהיה להשתמש בחלק מהשירותים והתכונות באתר או באתרי אינטרנט אחרים. בנוסף לכך, ניתן למחוק את ה- Cookies במחשב בכל רגע. הואיל והעוגיות מונעות מהגולש לעתים את הצורך להזין שמות משתמש וסיסמאות, מוצע לא למחוק אותן אלא אם הגולש משוכנע שהוא רשם תחילה את כל הפרטים הדרושים לשימוש באתרים במקום בטוח.
החברה עשויה להתיר לחברות אחדות לנהל את מערך הגשת הפרסומות באתריה, כך שהמודעות בהן צופה הגולש בעת הביקור באתרי טריפ פלנר מגיעות ממחשביהן של אותן חברות. לצורך ניהול הפרסומות, חברות אלה מציבות Cookies במחשב הגולש ומשבצות "משואות רשת" (web beacons) במודעות הפרסומת או בדפי האתר. המשואות הן קבצים גראפיים זעירים בעלי מזהה ייחודי, המשובצים בדפי אינטרנט ושתפקידם לסייע באיסוף מידע אודות הצפייה והשימוש באתר וכן נתונים כגון גיל, מגדר או תחומי עניין המופקים מפרסום מבוסס עניין של Google או מפרסמים אחרים באמצעות קוד Google Analytics. המידע הנאסף איננו מזהה את הגולש אלא רק מבקש להתאים את הפרסומות שיוצגו בפניו לנושאים שיעניינו אותו. השימוש שחברות אלה עושות ב-Cookies ובמשואות רשת כפוף למדיניות הפרטיות שלהן ולא למדיניות זו של אתרי טריפ פלנר.
            </p>
            <p>
תקנון מערכת הפורומים
            </p>
            <p>
הפרסום במדור זה הינו בהתאם לתקנון האתר ובייחוד לסעיף 7 לעיל. מערכת הפורומים של "טריפ פלנר ברשת" הינה מקום מפגש לגולשים המיועד להחלפת דעות, לפרסום שאלות, מתן תשובות ורעיונות במגוון כולל של נושאים. אנו מבקשים מהגולשים לשמור על עקרונות הבסיס המופיעים בתקנון וליידע כי הפורומים נתונים לפיקוח מצד הנהלת הפורומים ובידיה הזכות לנהוג ע"פ שיקול דעתה המקצועי למחוק, לשנות, לעדכן ולבטל הודעות אשר אינם עומדים בתנאי התקנון. מטרת הנהלת הפורומים אינה לצנזר או למנוע חופש ביטוי מגולשים אלא לשמור על תרבות שיחה הוגנת העומדת בתנאי התקנון.
אנו מבקשים לשמור על תרבות דיון בין הגולשים ולהימנע משפה פוגענית, קללות, גידופים, דברי נאצה איומים, גסות רוח, וכן תכנים שיש בהם משום עבירה פלילית או העלולים להוות בסיס לתביעה או אחריות אזרחית.
הפצת מידע – יש להימנע ממשלוח כמות הודעות רציפה בעניין זהה. אין לפרסם בפורום תכנים שמטרתם לפגוע או לשבש את פעילות הפורום או המשתתפים.
אין לפרסם במערכת הפורומים הודעות או הצעות למכירה או לקניה של ציוד "יד שניה" או מודעות "מחפשים שותפים".
כניסה למערכת הפורומים מהווה הסכמה ואישור מצד הגולש כי קרא את הפסקה לעיל, הבין את תוכנה ומסכים לאמור בה.
            </p>
            <p>
תקנון מערכת הבלוגים
            </p>
            <p>
כתיבת בלוג באתר "טריפ פלנר" כפופה לתקנון הכללי של האתר, בייחוד לסעיף 7 לעיל ולרבות לזכות הנתונה לעורכי האתר לערוך או להסיר פוסטים שאינם עומדים בכללי התקנון. כל הכתוב או הצילומים בבלוג הם באחריותו הבלעדית של הכותב, ואתר טריפ פלנר אינו נושא באחריות לתכנים אלה.
מערכת האתר רשאית לעשות שימוש בתכני הבלוג, לרבות תמונות, לצרכי פרסום כתבות וטיפים במדורי האתר ו/או כל פעולה אחרת, לקידום אתר טריפ פלנר ומערכת הבלוגים והכותב מוותר על כל תביעה או דרישה בענין זה, לרבות בקשר עם זכותו המוסרית בתוכן המועבר..
אין להשתמש במערכת הבלוגים לצורך פרסום פוסטים לצרכים מסחריים-שיווקיים, אין עליהם לכלול מידע פרסומי ואין עליהם לקדם אתרי אינטרנט אחרים (בצורת שתילת קישורים או כל אמצעי קידום אחר).
            </p>
            <p>
 סמכויות החברה וזכויותיה
            </p>
            <p>
החברה רשאית לסגור את האתר ולשנות מעת לעת את מבנהו, מראהו, תוכנו, היקפו וזמינותם של השירותים והתכנים הניתנים בו, וזאת ללא צורך להודיע על כך מראש. למשתמש לא תהא כל טענה, תביעה או דרישה כלפי החברה בקשר לכך.
החברה אינה מתחייבת שהשירות הניתן באתר לא יופרע, יינתן כסדרו ללא הפסקות והפרעות ו/או יהיה חסין מפני גישה בלתי מורשית למחשבי החברה או מפני נזקים, תקלות, כשלים בחומרה, תוכנה או בקווי תקשורת, אצל החברה או מי מספקיה, או ייפגע מכל סיבה אחרת, והחברה לא תהא אחראית לכל נזק, ישיר או עקיף, עגמת נפש וכיוצ"ב שייגרמו למשתמש עקב כך.
החברה אינה אחראית לכל אובדן של מידע או תכנים כלשהם שייגרם מסיבה כלשהי.
החברה תימנע ככל האפשר ממסירת פרטים אישיים של משתמש לצדדים שלישיים, מלבד לצורך תפעול האתר, אלא אם החברה תהא חייבת לעשות כן על פי צו שיפוטי או אם תעמוד בפני איום שינקטו נגדה הליכים משפטיים (פליליים או אזרחיים) בגין פעולות של המשתמש, לרבות הגשת תלונה. במקרה זה תהא החברה רשאית לפי שיקול דעתה למסור את פרטי המשתמש לצד הטוען כי נפגע מפעולת המשתמש או בהתאם להוראות צו שיפוטי.
כן תהיה החברה רשאית לחשוף מידע אודות משתמשים במקרים הבאים:.
במקרה בו מידע על המשתמש מפורסם על ידיו;
בעת רכישת מוצרים או שירותים בשיתוף עם האתר, לצורך השלמת הליך הרכישה ושמירת קשר עם המשמש;
במקרה של הפרת תנאי השימוש באתר או ביצוע פעולה אשר לדעת האתר מנוגדת להוראות הדין.
במקרה שלדית מפעילי האתר הדבר נחוץ למניעת נזק לגופו או לרכושו של אדם, לרבות המשתמש.
במקרה של מיזוג עם תאגיד אחר, ובכפוף לתנאי התקנון.
החברה רשאית להפסיק את השימוש שעושה משתמש כלשהו באתר אם לדעת החברה ועל פי שיקול דעתה המוחלט, המשתמש לא עמד בתנאי השימוש, כולם או חלקם, או עלול לעשות כן, או אם המשתמש עלול לדעת החברה, לעשות שימוש לרעה בשירותים, כולם או חלקם, לרבות נגד החברה.
            </p>
            <p>
אבטחת מידע
            </p>
            <p>
החברה מיישמת באתריה מערכות ונהלים לאבטחת מידע. בעוד שמערכות ונהלים אלה מצמצמים את הסיכונים לחדירה בלתי-מורשית למחשביה אין בהם בטחון מוחלט. החברה אינה מתחייבת שהשירותים באתריה חסינים באופן מוחלט מפני גישה בלתי-מורשית למידע המאוחסן בהם.
            </p>
            <p>
תקשורת בין גולשים
            </p>
            <p>
השימוש באתר עשוי לאפשר תקשורת בין גולשים באמצעות מערכת מסרים פנימית שהאתר מספק. ידוע לגולש כי העברת תכנים מצידו לאתר עשויה לחשוף אותו לקבלת הודעות או מסרים ממשתמשים אחרים.
החברה אינה צד לתקשורת בין גולשים ואינה אחראית לתוכנה. הגולש פוטר את החברה, מנהליה ומי מטעמה מכל אחריות בקשר עם תקשורת זו וכל תוצאה שלה.
            </p>
            <p>
פיצוי/ שיפוי
            </p>
            <p>
המשתמש יפצה ו/או ישפה את החברה, עובדיה, מנהליה, בעלי מניותיה או מי מטעמם בגין כל נזק, הפסד, אבדן רווח, תשלום או הוצאה שייגרמו למי מהם, לרבות שכ"ט עו"ד והוצאות משפט, כתוצאה מהפרה של תנאי שימוש אלה, כולם או חלקם, או כתוצאה מתביעה או דרישה של צד ג' כלשהו בקשר עם שימוש באתר על ידי המשתמש.
            </p>
            <p>
שונות
            </p>
            <p>
תנאי שימוש אלה מתווספים לכל הוראות תקנון או תנאי שימוש אחרים המצויים באתר ובמקרה של אי התאמה, יגברו תנאי שימוש אלה. החברה רשאית לשנות תנאי שימוש אלה מעת לעת בלי למסור על כך הודעה מראש. החברה תפרסם את התנאים החדשים, ותחילת תוקפם יהיה במועד פרסומם.
על תקנון זה יחולו אך ורק דיני מדינת ישראל. מקום השיפוט הבלעדי לכל ענין הנוגע להסכם זה והשימוש באתר הינו בבתי המשפט המוסמכים במחוזות תל-אביב יפו והמרכז.
פרטי התקשרות
            </p>
            <p>052-686-7915 <span class="turquoise">:טלפון</span></p>
            <p>avshalom@tripplanner.co.il <span class="turquoise">:מייל</span></p>
            <p>
              4588500 <span class="turquoise">:כתובת</span>טריפ פלנר מושב סלעית
            </p>






            </b-col>
        </b-row>

      </b-container>
    </section>









    <PageFooter />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import PageFooter from "@/components/PageFooter.vue";

export default {
  name: "TermsOfUse",
  components: {
    PageFooter,
    
  },
  computed: mapGetters(['globalRides', 'userId' ]),
  methods: {}
}
</script>

<style>

</style>