<template>
  <div>
    <b-modal
      id="doYouWantToAddThisPointToRide"
      modal-class="modal-cover"
      v-model="isVisible"
      hide-backdrop
      hide-footer
      content-class="shadow-lg"
    >
      <section class="text-center">
        <b-row>
          <b-col cols="12" class="py-2">
            <h3 class="font-weight-medium card-title">
              התמונה שצילמתם נלקחה מהמקום שבחרתם במפה?  
            </h3>
          </b-col>

          <b-col cols="12" class="py-2">
            <p>
              פעולה זואת תוסיף את התמונה שצילמתם בתור הנקודה שבחרתם במפה
            </p>
          </b-col>
          <b-col cols="12" class="py-2">
            <NoButton
              :callBack="closePopup"
            >
              לא זה לא המקום
            </NoButton>
            <YesButton
              class="ml-2"
              :callBack="addThisPoint"
            >
              כן זה המקום
            </YesButton>
          </b-col>
      </b-row>
      </section>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import config from '@/common/config';

import NoButton from "@/components/Buttons/NoButton.vue";
import YesButton from "@/components/Buttons/YesButton.vue";


export default {
  props: ["value"],
  components: { YesButton,
                NoButton,
  },

  computed: {
    ...mapGetters([ 'allRide',
                    'inerModalParameters' ]),
    isVisible: {
      set(val) {
        this.$emit("input", val);
      },
      get() {
        return this.value;
      },
    },
  },
  methods: { 
    ...mapActions([ 'addRideToUser',
                    'removeAttractionFromRide',
                    'showModal',
     ]),
    addThisPoint(){
      const place = {type: config.config.PLACE_DATA_TYPE.PLACE_DATA_TYPE_GOOGLE_PLACE_ID, data: { placeId: this.inerModalParameters.placeId } }
      const tempCallback = this.inerModalParameters.callBack;
      // Close the current popup with a slight delay to ensure the callback triggers the next popup
      this.showModal('');
      setTimeout(() => {
        // After closing this popup, call the callback to open the next one
        tempCallback(place);
      }, 100); // 100ms delay to ensure popup sequencing works correctly
    },
    closePopup(){
      this.showModal('')
    },

  },

};
</script>



<style scoped>
.modal.modal-cover .modal-content,
.modal.modal-cover .modal-footer,
.modal.modal-cover .modal-header {
  border: none;
}
.height-50 {
  height: 50px !important;
}
.width-50 {
  width: 50px !important;
}


.pb-3, .py-3 {
    padding-bottom: 1rem !important;
}

.pt-3, .py-3 {
    padding-top: 1rem !important;
}

.rounded-pill {
    border-radius: 50rem !important;
}

.btn-warning {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107;
}

.btn-secondary {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
}

.btn {
    display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

h5, .h5 {
    font-size: 1.25rem;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
}

button, [type="button"], [type="reset"], [type="submit"] {
    -webkit-appearance: button;
}

button, select {
    text-transform: none;
}

button, input {
    overflow: visible;
}
input, button, select, optgroup, textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}
button {
    border-radius: 0;
}
*, *::before, *::after {
    box-sizing: border-box;
}
user agent stylesheet
button {
    appearance: auto;
    text-rendering: auto;
    color: buttontext;
    letter-spacing: normal;
    word-spacing: normal;
    line-height: normal;
    text-transform: none;
    text-indent: 0px;
    text-shadow: none;
    display: inline-block;
    text-align: center;
    align-items: flex-start;
    cursor: default;
    box-sizing: border-box;
    background-color: buttonface;
    margin: 0em;
    padding-block: 1px;
    padding-inline: 6px;
    border-width: 2px;
    border-style: outset;
    border-color: buttonborder;
    border-image: initial;
}
.text-center {
    text-align: center !important;
}
*, *::before, *::after {
    box-sizing: border-box;
}
user agent stylesheet
div {
    display: block;
    unicode-bidi: isolate;
}
.btn-warning {
    background: linear-gradient(45deg, #E0BE36, #DD6E42);
    color: white;
    border: 0;
}

.btn-warning:hover {
    background: linear-gradient(45deg, #e8c538, #ee7748);
    color: white;
    border: 0;
}
.btn-primary {
    background: linear-gradient(45deg, #34D0B6, #07A0C3);
    color: white;
    border: 0;
}

.btn-primary:hover {
    background: linear-gradient(45deg, #39e1c5, #08add3);
    color: white;
    border: 0;
}

</style>