<template>
  <SingleRideWithMap/>
</template>

<script>
import SingleRideWithMap from "@/views/SingleRideWithMap";
import { mapActions } from "vuex";

export default {
  name: "SingleRideWithMapAndAttraction",
  components: {
    SingleRideWithMap,

  },
  methods:{
    ...mapActions([ 'lowedAttraction',
                    'showModal',
                    'lockShowModalOnce' ]),
  },
  created() {
    const rideIdFromUrl = this.$route.params.id;
    const attractionIdFromUrl = this.$route.params.attractionId;
    if (rideIdFromUrl) {
      this.$store.commit("setLowedRideId", rideIdFromUrl);
    }
    var attraction = { 'attractionId': attractionIdFromUrl,
                   'sorce':'internal' }
    this.lowedAttraction( attraction )
    this.showModal( "attraction" );
    this.lockShowModalOnce();
  },
};

</script>

<style scoped>

</style>
