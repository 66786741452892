<template>
  <b-dropdown id="dropdown-counter" class="bg-white w-100 dropdown-button"
              text="כמות המשתתפים לפי גילאים"
              toggle-class="d-flex justify-content-end align-items-center py-2 border-primary dropdown-button rounded-2"
              variant="outline-secondary">
    <div class="d-flex justify-content-end align-items-center">
      <div 
        v-for="(item, index) in items"
        :key="index"
        class="p-2 internal-align_right">
        <span>{{ item.label }}</span>
        <b-input-group class="mt-1" size="sm">
          <b-input-group-prepend>
            <b-button variant="outline-secondary" @click="decrement(index)">-</b-button>
          </b-input-group-prepend>
          <b-form-input
              v-model="item.value"
              class="text-center"
              min="0"
              readonly
              type="number"
          ></b-form-input>
          <b-input-group-append>
            <b-button variant="outline-secondary" @click="increment(index)">+</b-button>
          </b-input-group-append>
        </b-input-group>
      </div>
    </div>
  </b-dropdown>
</template>

<script>
import { BButton, BDropdown, BFormInput, BInputGroup } from "bootstrap-vue";
import { mapGetters } from "vuex";

export default {
  components: {
    BDropdown,
    BInputGroup,
    BButton,
    BFormInput,
  },
  computed: {
    ...mapGetters([
      "rideInformationNumbrOfAdoult",
      "rideInformationNumbrOfTeen",
      "rideInformationNumbrOfChild",
    ]),
    numbrOfAdoult: {
      get() {
        return this.rideInformationNumbrOfAdoult;
      },
      set(value) {
        this.$store.commit("setRiderideInformationNumbrOfAdoult", value);
        this.syncItems();
      },
    },
    numbrOfTeen: {
      get() {
        return this.rideInformationNumbrOfTeen;
      },
      set(value) {
        this.$store.commit("setRideRideInformationNumbrOfTeen", value);
        this.syncItems();
      },
    },
    numbrOfChild: {
      get() {
        return this.rideInformationNumbrOfChild;
      },
      set(value) {
        this.$store.commit("setRideRideInformationNumbrOfChild", value);
        this.syncItems();
      },
    },
  },

  data() {
    return {
      items: [
        { label: "בוגרים", value: 0 },
        { label: "8-18", value: 0 },
        { label: "0-7", value: 0 },
      ],
    };
  },

  watch: {
    // Sync initial values from Vuex when the component is created
    numbrOfAdoult(value) {
      this.items[0].value = value;
    },
    numbrOfTeen(value) {
      this.items[1].value = value;
    },
    numbrOfChild(value) {
      this.items[2].value = value;
    },
  },

  created() {
    // Set initial values for the items array based on Vuex state
    this.syncItems();
  },

  methods: {
    increment(index) {
      this.items[index].value++;
      this.updateVuex(index);
    },
    decrement(index) {
      if (this.items[index].value > 0) {
        this.items[index].value--;
        this.updateVuex(index);
      }
    },
    updateVuex(index) {
      if (index === 0) {
        this.numbrOfAdoult = this.items[index].value;
      } else if (index === 1) {
        this.numbrOfTeen = this.items[index].value;
      } else if (index === 2) {
        this.numbrOfChild = this.items[index].value;
      }
    },
    syncItems() {
      this.items[0].value = this.numbrOfAdoult;
      this.items[1].value = this.numbrOfTeen;
      this.items[2].value = this.numbrOfChild;
    },
  },
};
</script>
<style scoped>
.internal-align_right{
  text-align: right;
}

#dropdown-counter {
  height: 50px;
}

.show > .btn-outline-secondary.dropdown-toggle, .btn-outline-secondary:hover, #dropdown-counter .dropdown-button:active {
  background-color: white !important;
  color: #1e1e1e;
}
</style>
