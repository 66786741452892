<script>
export default {
  props: {
    backgroundImage: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    paragraph: {
      type: String,
      required: true
    },
    paragraphSections: {
      type: Array,
      required: false,
      default: () => []
    },
    stepNumber: {
      type: String,
      required: true
    }
  }
};
</script>

<template>
  <b-col class="mt-3 mt-md-0" col lg="6">
    <div class="card border-0 shadow-sm p-md-3 rounded-5">
      <div class="card-body d-flex flex-column text-right gap-md-3 position-relative">
        <div
            class="step-number-badge position-absolute h4 rounded-circle d-flex justify-content-center align-items-center"
            style="">
          {{ stepNumber }}
        </div>
        <img :alt="title" :src="backgroundImage"
             class="image-how-it-works"/>
        <h3 class="fw-semibold mb-0 h3">
          {{ title }}
        </h3>
        <p class="mb-0 text-muted paragraph-font h6" style="white-space: pre-line;">
          {{ paragraph }}
        </p>
        <ul class="instructions-list">
          <li v-for="(item, index) in paragraphSections" :key="index">
            {{ item }}
          </li>
        </ul>
      </div>
    </div>
  </b-col>
</template>

<style scoped>
.card-body {
  gap: 1rem;
}

.paragraph-font {
  font-weight: 300;
  line-height: normal;
}
p, span, h1, h2, h3, h4, h5, h6 {
  line-height: 1.5 !important; /* explicitly set */
}
.instructions-list {
  list-style-type: disc; /* Use bullets for the list */
  margin: 1rem 0;
  padding-left: 1.5rem; /* Indent the list */
  color: #333!important; /* Darker color for better visibility */
}

.instructions-list li {
  margin-bottom: 0.5rem; /* Add space between list items */
  font-size: 1rem; /* Adjust font size for better readability */
  font-weight: 400; /* Slightly bold to make it pop */
}
</style>