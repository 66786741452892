<template>
  <div class="py-2 py-md-5">
    <div v-if="trips.length > 0" class="row">
      <div v-for="trip in trips" :key="trip.id" class="col-lg-4 mb-3">
        <div
          class="clickable card border-0 shadow-sm rounded-5 bg-card"
          :style="{ height: '300px', backgroundImage: `url(${trip.image})`, backgroundSize: 'cover', backgroundPosition: 'center' }"
          @click="goToRide(trip.RideId)"
        >
          <div class="card-body d-flex justify-content-between flex-column">
            <div>
              <div
                class="glass-card d-inline-flex px-3 py-2 rounded-pill align-items-center justify-content-center"
              >
                {{ trip.title }}
              </div>
            </div>
            <div class="d-flex justify-content-end gap-2">
              <div
                class="glass-card d-inline-flex px-3 py-2 rounded-pill align-items-center justify-content-center"
              >
                {{ trip.manyDays }}
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/page_images%2Fcolander.png?alt=media&token=85389257-68ed-4590-9609-0213debfbae0"
                  height="20px"
                  alt="Calendar"
                  class="fa-solid fa-calendar-days text-primary fs-5 me-2"
                />
              </div>
              <div
                class="glass-card d-inline-flex px-3 py-2 rounded-pill align-items-center justify-content-center"
              >
                {{ trip.location }}
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/page_images%2Fpin_orange.png?alt=media&token=d15478b3-2f97-4ac8-8636-b7afc7b2caf0"
                  height="20px"
                  alt="Calendar"
                  class="fa-solid fa-map-pin text-orange fs-5 me-2"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <h2 v-else>Log in to get started!</h2>
  </div>
</template>


<script>

import { mapActions } from 'vuex';

export default {
  name: 'TripsImagePresentation',
  data() {
    return {
      trips: [
        {
          id: 1,
          title: 'וינה וזלצבורג',
          location: 'אוסטריה',
          manyDays: '14 ימים',
          image: 'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/1_30_Mar_2021_16%3A34%3A03_GMT?alt=media&token=983aa4d1-62ae-4436-8518-08f9e2558d0a',
          RideId: 1
        },
        {
          id: 2,
          title: 'פירנאים, ברצלונה ומדריד',
          location: 'ספרד',
          manyDays: '11 ימים',
          image: 'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/gui_images%2Fspain_park_climing.jpeg?alt=media&token=ae5b0b2d-036a-45ae-a747-49ca495c2c70',
          RideId: 719
        },
        {
          id: 3,
          title: 'קרקוב וזקופנה',
          location: 'דרום פולין',
          manyDays: '14 ימים',
          image: 'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/attractions%2F183_27_Aug_2022_20%3A43%3A38_GMT?alt=media&token=88678958-c118-484d-bfdb-6dcb2544040c',
          RideId: 183
        },

      ]
    };
  },
  methods: {
    ...mapActions([ 'goToSerchattractionPage',
                     ]),
      goToRide( rideId ) {
        this.$router.push('/single_ride_with_map/'+ rideId)
      },

  },
};
</script>


<style scoped>
.glass-card {
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(10px);
}


.card {
    --bs-card-spacer-y: 1rem;
    --bs-card-spacer-x: 1rem;
    --bs-card-title-spacer-y: 0.5rem;
    --bs-card-title-color: ;
    --bs-card-subtitle-color: ;
    --bs-card-border-width: 1px;
    --bs-card-border-color: rgba(0, 0, 0, 0.175);
    --bs-card-border-radius: .5rem;
    --bs-card-box-shadow: ;
    --bs-card-inner-border-radius: calc((.5rem) - (1.6rem));
    --bs-card-cap-padding-y: 0.5rem;
    --bs-card-cap-padding-x: 1rem;
    --bs-card-cap-bg: rgba(33,37,41, 0.03);
    --bs-card-cap-color: ;
    --bs-card-height: ;
    --bs-card-color: ;
    --bs-card-bg: #fff;
    --bs-card-img-overlay-padding: 1.6rem;
    --bs-card-group-margin: 1.2rem;
    color: #212529;
    word-wrap: break-word;
    font-size: 1.6rem;
    background-size: cover;
    background-position: center;

}


.card-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.text-primary {
  color: #007bff !important;
}

.text-orange {
  color: #fd7e14 !important;
}

/* .text-primary {
    background-image: linear-gradient(45deg, #34D0B6, #07A0C3) !important;
    color: transparent !important;
} */
.py-2 {
    padding-top: 0.8rem !important;
    padding-bottom: .8rem !important;
}
.px-3 {
    padding-right: 1.6rem !important;
    padding-left: 1.6rem !important;
}
.me-2 {
  margin-right: .8rem !important;
}

@media (min-width: 768px) {
    .py-md-5 {
        padding-top: 3rem !important;
        padding-bottom: 3rem !important;
    }
}
.rounded-5 {
  border-radius: 3.2rem !important;
}
.clickable:not(:disabled):not(.disabled) {
    cursor: pointer;
}
@media (max-width: 768px) {
  .card {
    margin: 15px; /* Adjust the margin value as needed */
  }
}
</style>