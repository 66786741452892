<template>
  <footer class="bg-footer container-fluid py-4 text-white">
    <div class="container">
      <div class="row mb-3">
        <div class="col d-flex justify-content-between align-items-center">
          <a href="/">
            <img class="footer-image" src="https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/page_images%2FLogo-footer.svg?alt=media&token=28027fca-93e4-4d6a-86e4-ffd544e27c14" height="48px" alt="">
          </a>          
          <a href="#top" style="height: 40px; width: 40px;">
            <img src="https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/page_images%2Fgo_up.png?alt=media&token=ba4bf321-a2cd-421e-a156-ec8b20602717" 
            height="40px"
            width="40px"
            alt="Go Up" />
          </a>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-md-6">
          <div class="row">
            <div class="col-4">
              <p class="mb-0 fs-5 fw-bold">עמודים</p>
              <div class="opacity-75">
                <router-link to="/" class="d-block mb-0 fs-6 footer-link">בית</router-link>
                <router-link to="/about" class="d-block mb-0 fs-6 footer-link">אודות</router-link>
                <router-link to="/search_ride" class="d-block mb-0 fs-6 footer-link">חיפוש חופשות</router-link>
                <router-link to="/insert_new_ride_from_google_photos" class="d-block mb-0 fs-6 footer-link">העלאת חופשה</router-link>
              </div>
            </div>
            <div class="col-4">
              <p class="mb-0 fs-5 fw-bold">Other</p>
              <div class="opacity-75">
                <router-link to="/terms_of_use" class="d-block mb-0 fs-6 footer-link">תקנון</router-link>
                <router-link to="/about" class="d-block mb-0 fs-6 footer-link">יצירת קשר</router-link>
              </div>
            </div>

            
            <div class="col-4">
              <p class="mb-0 fs-5 fw-bold">רשתות חברתיות</p>
              <div class="opacity-75">
                <a href="https://www.facebook.com/Tripplaner-100134939297544" target="_blank" class="d-block mb-0 fs-6 footer-link">
                  Facebook
                  <img src="https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/page_images%2Ffacebook%20-footer.png?alt=media&token=d1d6dea0-0d20-4cbf-837b-7916d24a0832" height="20px" alt="Facebook" />
                </a>
              </div>
            </div>



            
          </div>
        </div>
      </div>
      <p class="text-center mb-0 opacity-50 fs-7">
        כל הזכויות שמורות
        Tripplanner - 
        {{ currentYear }}
        © 
      </p>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'PageFooter',
  data() {
    return {
      currentYear: 2021
    };
  }
};
</script>

<style scoped>
html {
    font-size: 100%; /* 16px */
}
#welcome-section {
    padding-top: 98px;
    padding-bottom: 24px;
    height: 100dvh;
}

.text-welcome {
    font-size: 72px;
    font-weight: 800;
}

.input-icon {
    position: relative;
}

.input-icon input {
    padding-right: 6.4em;
    padding-left: 6.4em;
}

.fs-5 {
    font-size: 2rem !important;
}

.fa-plane_image{
  height: 31px;
}

.image-31px {
  height: 31px;
  width: 31px;
}

.py-3 {
    padding-bottom: 3rem !important;
    padding-top: 3rem !important;
}
.input-icon .fa-plane {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    background: linear-gradient(45deg, #34D0B6, #07A0C3);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.bg-welcome {
    position: relative;
    background-image: url('https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/page_images%2Fwelcome-trip.jpg?alt=media&token=0454fe77-65ec-4ece-8c99-6d978d2a7bbd');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    height: 100%;
}

.bg-welcome::after {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 32px;
    background: linear-gradient(45deg, rgba(43, 43, 43, 0), rgba(30, 30, 30, 0.8));
    z-index: 1;
}
.rounded-4 {
  border-radius: 1.6rem !important;
}

.gap-3{
    gap: 1.6rem !important;
}
.p-4 {
    padding: 2.4rem !important;
}

.rounded-5{
	border-radius: 32px !important;
}

/* .gap-3{
  gap: 5rem !important;
} */

.gap-2{
  gap: .8rem !important;
}

@media (min-width: 768px){
  .gap-md-5{
    gap: 5rem !important;
  }
  .px-md-4 {
      padding-right: 2.4rem !important;
      padding-left: 2.4rem !important;
  }
    .py-md-5 {
        padding-top: 4.8rem !important;
        padding-bottom: 4.8rem !important;
    }

}

.margin-for-h2 {
    margin-bottom: 0.8rem !important;
}
.mb-md-2, .my-md-2 {
    margin-bottom: 2rem !important;
}
.position-index {
    position: relative;
    z-index: 2; /* Higher than the overlay */
}

.glass-card {
  background-color: rgba(255, 255, 255, 0.75);
  backdrop-filter: blur(8px);
}

.text-secondary {
    color: #0D2F29 !important;
}

.carousel-height {
    height: 450px;
}

.review-image {
    width: 100%;
    height: 100%;
}


.col-6 {
    flex: 0 0 40%;
    width: 40%;
}

@media (max-width: 575.98px) {

    .text-welcome {
        font-size: 32px;
        font-weight: 800;
    }

    #welcome-section {
        padding-top: 74px;
        padding-bottom: 16px;
        height: 100dvh;
        min-height: 100dvh;
    }


    .full-width {
        width: 100% !important;
    }

    .carousel-height {
        height: 620px;
    }

    .review-image {
        width: 100%;
        height: 150px;
    }
    .col-6 {
      flex: 0 0 50%;
      width: 50%;
    }

}

.fs-4 {
  font-size: 2.4rem !important;
}

.py-3 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
}
.px-3 {
    padding-right: 2.4rem !important;
    padding-left: 2.4rem !important;
}

.pading_top_and_butom_3{
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}
.fw-semibold {
    font-weight: 600 !important;
}
p {
    margin-top: 0;
    margin-bottom: 1.6rem;
}

img{
  height: revert-layer;
}
.fw-bold {
    font-weight: 700 !important;
}

@media (min-width: 1200px) {
    .h2, h2 {
        font-size: 3.6rem;
    }
}








body {
    font-family: "Nunito", sans-serif;
    font-optical-sizing: auto;
    color: #1e1e1e;
}

.primary {
    background: linear-gradient(45deg, #34D0B6, #07A0C3);
    color: white;
}

.text-primary {
    background-image: linear-gradient(45deg, #34D0B6, #07A0C3) !important;
    color: transparent !important;
    -webkit-background-clip: text !important;
    -moz-background-clip: text !important;
    background-clip: text !important;
}

.btn-primary {
    background: linear-gradient(45deg, #34D0B6, #07A0C3);
    color: white;
    border: 0;
}

.btn-primary:hover {
    background: linear-gradient(45deg, #39e1c5, #08add3);
    color: white;
    border: 0;
}

.btn-warning {
    background: linear-gradient(45deg, #E0BE36, #DD6E42);
    color: white;
    border: 0;
}

.btn-warning:hover {
    background: linear-gradient(45deg, #e8c538, #ee7748);
    color: white;
    border: 0;
}

.transparent-background-primary {
    background-color: rgba(7, 160, 195, 0.1) !important;
}

.font-64 {
    font-family: "Nunito Sans", sans-serif;
    font-optical-sizing: auto;
    font-size: 64px !important;
    font-weight: 800;
    color: #07A0C3;
    opacity: 20%;
    line-height: 64px !important;
}

#banner {
    height: 150px;
    /* background-image: url('/assets/images/banner-background.png'); */
    background-repeat: no-repeat;
    /*background-attachment: fixed;*/
    background-size: cover;
    color: white;
}


.vl {
    border-left: 2px solid rgba(7, 160, 195, 0.5);
    height: 100%;
}

.color-primary {
    color: #07A0C3 !important;
    border: 0
}

.text-orange {
    color: #DD6E42;
}

#review {
    background-color: rgba(7, 160, 195, 0.1);
    border-radius: 0 0 32px 32px;
}

#traveler-image {
    height: 100px;
    width: 100px;
    /* background-image: url("/assets/images/traveler-image.png"); */
    background-repeat: no-repeat;
    background-size: cover;
}

.fs-7 {
    font-size: 14px !important;
}

#map-mobile {
    height: 100% !important;
    width: 100% !important;
    /* background-image: url("/assets/images/map.svg"); */
    background-repeat: no-repeat;
    background-size: cover;
}

#scroll-map {
    position: sticky;
    top: 70px;
    height: 100vh;
    z-index: 1; /* Ensure the map stays above other content */
}

#location1 {
    height: 140px;
    width: 140px;
    /* background-image: url("/assets/images/location1.jpg"); */
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 20px;
}

#location2 {
    height: 140px;
    width: 140px;
    /* background-image: url("/assets/images/location2.jpg"); */
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 20px;
}

.border-primary {
    border-color: rgba(7, 160, 195, 0.3) !important;
}

.content {
    max-height: calc(1.5em * 2);
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    word-wrap: break-word;
    opacity: 60%;
}


.show-all-content {
    max-height: none; /* Show all content */
    -webkit-line-clamp: initial;
}

.stars {
    color: #FFD83B;
}

.waze {
    background-color: #05C8F7 !important;
    color: white;
}

.booking {
    background-color: #003580 !important;
    color: white;
}

.get-your-guide {
    background-color: #FF5532 !important;
    color: white;
}

.whats-up {
    background-color: #25D366 !important;
    color: white;
}

.whats-up:hover {
    background-color: #22c75f !important;
    color: white;
}


.bg-footer {
    background-color: #0D2F29;
}

.floating-buttons {
    position: fixed;
    bottom: 20px; /* Adjust the bottom margin as needed */
    right: 20px; /* Adjust the right margin as needed */
    display: flex;
    flex-direction: column-reverse;
    gap: 10px;
    z-index: 2 !important;
}

/* .carousel-control-prev-icon {
    background-image: url("../assets/images/arrows/carousel-control-prev-icon.svg");
}

.carousel-control-next-icon {
    background-image: url("../assets/images/arrows/carousel-control-next-icon.svg");
} */

.step-number-badge {
    height: 54px;
    width: 54px;
    top: 16px;
    right: 16px;
    color: #DD6E42;
    font-weight: 900;
    background-color: rgba(221, 110, 66, 0.25);
}

.bg-light-blue {
    background-color: #E6F5F9 !important;
}

.bg-light-orange {
    background-color: rgba(221, 110, 66, 0.15);
}

.background-image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -1;
}
.background-image-max-with-none {
  max-width: none;
}

.bg-card {
    background-size: cover !important;
    background-position: center center;
    background-repeat: no-repeat;
}

.image-how-it-works {
    height: 150px !important;
}

.accordion-button:focus {
    z-index: 3;
    outline: 0;
    box-shadow: 0 0 white;
}

.accordion {
    --bs-accordion-border-color: transparent;
}

.fs-6 {
    font-size: 1.6rem !important;
}

@media (max-width: 575.98px) {
    .fs-7 {
        font-size: 14px !important;
    }

    .fs-6 {
        font-size: 15px !important;
    }

    .fs-5 {
        font-size: 15px !important;
    }

    .fs-4 {
        font-size: 18px !important;
    }

    #map {
        width: 100% !important;
    }

    #location1 {
        height: 90px;
        width: 90px;
        /* background-image: url("/assets/images/location1.jpg"); */
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 20px;
    }

    #location2 {
        height: 90px;
        width: 90px;
        /* background-image: url("/assets/images/location2.jpg"); */
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 20px;
    }

    .get-your-guide-image {
        height: 16px !important;
    }

    .google-logo {
        height: 50px !important;
    }

    .waze-logo {
        height: 80px !important;
    }

    .skyscanner-logo {
        height: 30px !important;
    }

    .booking-logo {
        height: 30px !important;
    }

    .footer-image {
        height: 28px;
    }

    .step-number-badge {
        height: 42px;
        width: 42px;
    }

}
.black{
  color: #1e1e1e;
}
.what_can_be_done_explanation{
  color: rgba(33, 37, 41, 0.75) !important;
  font-size: 1.6rem !important;
  margin-bottom: 1.6rem;
}

.rounded-2 {
    border-radius: 0.5rem !important;
}

row.align-items-stretch {
    display: flex;
    flex-wrap: wrap;
}

.card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.card-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.bg-card {
    background-size: cover;
    background-position: center;
}

@media (min-width: 1200px) {
    .h4, h4 {
        font-size: 2.3rem;
    }
}
.h4, h4 {
    font-size: 2.2rem;
}
h2, h2 {
    font-size: calc(2.2rem + 1.5vw);
}
.pointer-over{
  cursor: pointer;
}




.footer-image {
  height: 48px;
}

.footer-link {
  color: white;
  text-decoration: none;
}

.footer-link:hover {
  color: lightgray;
}

.bg-footer {
  background-color: #0D2F29;
}

</style>