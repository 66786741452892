<template>
			<div class="trip-cta3-section trip-section-padding" style="background-image: url('https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/gui_images%2FhomePage%2Fcta3-bg.png?alt=media&token=220c5a27-69c7-4bc1-8815-2e2cf05a84a5')">
				<div class="container">
					<div class="trip-default-column">
						<h3>איך זה עובד?</h3>
						<div class="trip-cta3-column">
							<div class="trip-cta3-wrap">
								<div class="trip-cta3-thumb">
									<img src="https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/gui_images%2FhomePage%2Fcta1.png?alt=media&token=5debe311-4a38-4c73-b492-8146b3467cbc" alt="">
								</div>
								<div class="trip-cta3-data">
									<p>נהנים מהטיול
										ומשתפים משפחות</p>
								</div>
							</div>
							<div class="trip-cta3-wrap">
								<div class="trip-cta3-thumb">
									<img src="https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/gui_images%2FhomePage%2Fcta2.png?alt=media&token=f4f12130-be0d-47e4-aea6-f67adf8c0472" alt="">
								</div>
								<div class="trip-cta3-data">
									<p>מוסיפים/מסירים פעילויות
										קיימות לטיול</p>
								</div>
							</div>
							<div class="trip-cta3-wrap">
								<div class="trip-cta3-thumb">
									<img src="https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/gui_images%2FhomePage%2Fcta3.png?alt=media&token=35ef2058-fa86-4cd2-af0c-6c15ce524661" alt="">
								</div>
								<div class="trip-cta3-data">
									<p>בוחרים טיול ששותף
										בתור בסיס</p>
								</div>
							</div>
						</div>
						<div
             v-if="this.shouldDisplayButen()"
             class="trip-center-button">
							<a 
                class="hero-outline-btn active"
                @click="goToSerchattractionPage()">
                צפו במסלולי טיול 
              </a>
						</div>
					</div>
				</div>
			</div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      icons: [
        {
          svg: "icons8-waypoint-map.svg",
          text: 'מעוניינים לתכנן טיול?',
          arrow: "arrow.png",
        },
        {
          svg: "icons8-buy-with-card.svg",
          text: 'בוחרים טיול ששותף בתור נקודת התחלה',
          hasArrow: true,
          arrow: "arrow.png",
        },
        {
          svg: "icons8-map-editing.svg",
          text: 'בחרתם טיול? ניתן להוסיף או להסיר פעילויות קיימות לטיול',
          hasArrow: true,
          arrow: "arrow.png",
        },
        {
          svg: "icons8-sign-up-in-calendar.svg",
          text: 'עכשיו כל מה שנשאר זה להנות מהטיול וכמובן לשתף',
          hasArrow: true,
          arrow: "arrow.png",
        },
        // {
        //   svg: "icons8-chicago.svg",
        //   text: 'הגעתם? נווטו לפעילויות שבחרתם',
        //   hasArrow: true,
        //   arrow: "arrow.png",
        // },
      ],
    };
  },

  // router.history.current.fullPath
  methods: { ...mapActions(["goToSerchattractionPage"]),
    shouldDisplayButen(){
      return this.$router.history.current.fullPath != "/search_ride"
    }
  }


};
</script>
<style scoped>
/* .img {
  width: 60px;
  margin-left: auto;
  margin-right: auto;
} */
@media (min-width: 1500px) {
  .container {
    max-width: 1360px;
  }
}
/* planner section */

.trip-planner-section {
  background-size: cover;
  background-position: center top;
  overflow: hidden;
}
.trip-section-padding{
  padding: 55px 0;
}
.trip-default-column {
  max-width: 1000px;
  margin: 0 auto;
}
.trip-default-column h3{
  font-weight: 500;
  font-size: 28px;
  text-align: center;
  color: #000000;
  margin-bottom: 45px;
  font-family: 'Rubik', sans-serif;
}
.trip-planner-wrap {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.trip-planner-card {
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  border-radius: 20px;
  padding: 25px;
  flex: 0 0 37.5%;
  text-align: center
}
.trip-planner-card-thumb{
  margin-bottom: 30px;
}
.trip-planner-card-thumb img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 22px;
}
.trip-planner-card-data h4{
  font-weight: 700;
  font-size: 25px;
  color: #000000;
  margin-bottom: 20px;
  font-family: 'Rubik', sans-serif;
}
.trip-planner-card-data p{
  margin-bottom: 20px;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
  font-family: 'Rubik', sans-serif;
}

/* trip cta section */
.trip-cta3-section {
  background-size: cover;
  background-position-x: -40px;
  background-repeat: no-repeat;
}
.trip-cta3-column {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-direction: row-reverse;
}
.trip-cta3-wrap {
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  border-radius: 20px;
  text-align: center;
  flex: 0 0 25%;
  padding: 25px;
}
.trip-cta3-data p{
  font-size: 20px;
  line-height: 24px;
  color: #000000;
  font-family: 'Rubik', sans-serif;
}
.trip-cta3-thumb{
  margin-bottom: 30px;
}
.trip-center-button {
  text-align: center;
  margin-top: 44px;
}

a.hero-outline-btn {
  height: 44px;
  border: 1px solid #21AD96;
  border-radius: 22px;
  font-weight: 500;
  font-size: 20px;
  text-align: center;
  color: #21AD96;
  display: inline-block;
  padding: 10px 26px;
  transition: all 0.4s;
  font-family: 'Rubik', sans-serif;

}
a.hero-outline-btn:hover{
  color: #fff;
  background-color: #21AD96;
}
a.hero-outline-btn.active{
  color: #fff;
  background-color: #21AD96;
}
a.hero-outline-btn.active.blue-bg {
  background-color: #08A0C3;
  border: 1px solid #08A0C3;
}








@media (max-width: 767px) {
    /* planner*/
    .trip-section-padding {
        padding: 50px 0;
    }
    .trip-planner-section{
        background-image: url("https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/gui_images%2FhomePage%2Fplanner-responsive.png?alt=media&token=ab22f740-735d-4b2c-a96d-0a04d7d5b317")!important;
    }
    .trip-default-column {
        max-width: 215px;
    }
    .trip-default-column h3 {
        font-size: 14px;
        margin-bottom: 25px;
    }
    .trip-planner-wrap {
        display: block;
    }
    .trip-planner-card-data h4 {
        font-size: 16px;
        margin-bottom: 10px;
    }
    .trip-planner-card-data p {
        font-size: 10px;
        line-height: 12px;
    }
    a.hero-outline-btn {
        height: 25px;
        font-size: 14px;
        padding: 1px 22px;
    }
    a.hero-outline-btn.responsive-white {
        background-color: white;
        border: 1px solid white;
    }
    .trip-planner-card{
        margin-bottom: 30px;
    }
    .trip-planner-card:last-child{
        margin-bottom: 0;
    }
    .trip-slider-bottom a.hero-outline-btn {
        height: 25px;
        font-size: 14px;
        padding: 0px 30px;
        margin-top: 15px;
    }

    /* trip cta */
    .trip-cta3-section {
        background-image: url("https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/gui_images%2FhomePage%2Fcta-responsive.png?alt=media&token=9592addf-6e1d-4632-b106-e3949403db5a")!important;
        background-size: contain;
        background-position-x: 16px;
        background-position-y: 51px;
        background-color: rgba(145, 214, 211, 0.1);
    }
    .trip-cta3-thumb {
        margin-bottom: 10px;
    }
    .trip-cta3-thumb img{
        width: 90px;
    }
    .trip-cta3-data p {
        font-size: 12px;
        line-height: 14px;
    }

    .trip-cta3-wrap {
        padding: 13px;
        margin-bottom: 25px;
        flex: 0 0 100%;
    }
    .trip-cta3-wrap:nth-child(1) {
        order: 3;
        margin: 0;
    }
    .trip-cta3-wrap:nth-child(2) {
        order: 2;
    }
    .trip-cta3-wrap:nth-child(3) {
        order: 1;
    }
    .trip-center-button {
        margin-top: 25px;
    }





}



















</style>
