
import api from '../../api/uploadTrip';
import rideInformationApi from '../../api/rideInformation';
import { router } from '../../main';
import editRideApi from '../../api/editRide';
import accommodationApi from '../../api/accommodation';
import { UPLOAD_RIDE_STATES } from '@/constants/uploadRideStates.js';
import config from '../../common/config';

  // const openAichosenPlaceTamplate={ displayName:  { languageCode: "en", 
                                                    // text: "Adventure Park Brasov"
                                                  // },
                                    // id: "ChIJtbdpkdhcs0ARxeQnxCx_djI",
                                    // success: "true"
                                  // }
  // const googleLocationsOptionsTamplate= [ {displayName:{languageCode: "ro", text: "Zoo Brașov"}, id: "ChIJw4ElPNlcs0ARvdRP--G4gKw"}]
  // const locationTemplate={  latitude: [ {numerator: 45, denominator: 1},
                                        // {numerator: 36, denominator: 1},
                                        // {numerator: 4476, denominator: 100}
                                      // ],
                            // latitudeRef: "N",
                            // longitude:[ {numerator: 25, denominator: 1},
                                        // {numerator: 38, denominator: 1},
                                        // {numerator: 1384, denominator: 100}
                                      // ],
                            // longitudeRef: "E"
                          // }
  // const atractionsMetadataListTamplate= [   { analytics:  { googleNearbyatractionsdisplayName:googleLocationsOptionsTamplate,
                                                            // imageUrl: "https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/test%2F15_Nov_2024_03%3A07%3A03_GMTIMG_5737.jpeg?alt=media&token=1a47a723-d12e-493e-8891-c54e9b1ed637",
                                                            // openAichosenPlace: { imagePlace:  openAichosenPlaceTamplate},
                                                            // dateTaken: "2024:08:19 10:47:51",
                                                            // filename: "IMG_5737.jpeg",
                                                            // location: locationTemplate,
                                                            // type: "image/jpeg",
                                                            // uploadedAt: "11/15/2024, 5:07:09 AM",
                                                            // url: "https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/test%2F15_Nov_2024_03%3A07%3A03_GMTIMG_5737.jpeg?alt=media&token=1a47a723-d12e-493e-8891-c54e9b1ed637"
                                                          // },
                                                          // dateTaken: "2024:08:19 10:47:51",
                                                          // filename: "IMG_5737.jpeg",
                                                          // location: locationTemplate,
                                                          // type: "image/jpeg",
                                                          // uploadedAt: "11/15/2024, 5:07:09 AM",
                                                          // url: "https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/test%2F15_Nov_2024_03%3A07%3A03_GMTIMG_5737.jpeg?alt=media&token=1a47a723-d12e-493e-8891-c54e9b1ed637"
                                          // }]
  // const uploadRideStateTemplate = UPLOAD_RIDE_STATES.ADDING_ACCOMMODATIONS_FROM_IMAGES //     ADDING_ATRACTIONS_FROM_IMAGES: ADDING_ATRACTIONS_FROM_IMAGES/ADDING_ACCOMMODATIONS_FROM_IMAGES

const state = {  
  isFirstTimeAddingImages: false,
  uploadRideIdFromImage: null,
  
  //atractionsMetadataList: atractionsMetadataListTamplate,
  //openAichosenPlace: openAichosenPlaceTamplate,
  //googleLocationsOptions: googleLocationsOptionsTamplate,
  //uploadRideState: uploadRideStateTemplate,
  
  atractionsMetadataList: [],
  openAichosenPlace: null,
  googleLocationsOptions: [],
  uploadRideState: UPLOAD_RIDE_STATES.INITIALIZING,
  
  locationFromImageCheckIn: null,
  locationFromImageCheckOut: null,
  newAttractionOptions: {"ACCOMMODATION": "accommodation", "ATTRACTION": "attraction"},
  tripFromImagesUploadStatus: { totalFiles: 0, uploadedFiles: 0 },
  tripFromImagesLocationInsertionStage: { totalLocationsSteps: 0, currentStep: 1 },
  imageUploadTripDefaultPresentedLocation: null,
  imageUploadTripLastImageDate: null,
};

const getters = {
  isFirstTimeAddingImages: state => state.isFirstTimeAddingImages,
  atractionsMetadataList: state => state.atractionsMetadataList,
  atractionsMetadataListFirestItem: state =>
    state.atractionsMetadataList && state.atractionsMetadataList.length > 0
      ? state.atractionsMetadataList[0]
      : null,
  uploadRideIdFromImage: state => state.uploadRideIdFromImage,
  openAichosenPlace: state => state.openAichosenPlace ,
  googleLocationsOptions: state => state.googleLocationsOptions,
  uploadRideState: state => state.uploadRideState,
  locationFromImageCheckIn: state => state.locationFromImageCheckIn,
  locationFromImageCheckOut: state => state.locationFromImageCheckOut,
  newAttractionOptions: state => state.newAttractionOptions,
  tripFromImagesUploadStatus: state => state.tripFromImagesUploadStatus,
  tripFromImagesLocationInsertionStage: state => state.tripFromImagesLocationInsertionStage,
  imageUploadTripDefaultPresentedLocation: state => state.imageUploadTripDefaultPresentedLocation,
  imageUploadTripLastImageDate : state => state.imageUploadTripLastImageDate,
};

const actions = {
  async TheSameIdLikeRide({ getters }) {
    const rideId = getters.rideId;
    const uploadRideIdFromImage = getters.uploadRideIdFromImage;

    if (rideId !== null && uploadRideIdFromImage !== null) {
      return String(rideId) === String(uploadRideIdFromImage);
    }
    return false;
  },

  async addAccommodationsfromImages() {
    // in the future we will add the accommodations date acording to the AI inforamtion - 
    // we will send to chetGPT the attraction location and the accommodation and we 
    // will aske for the split of days betwin the accommodations( we will send the taken image date as well)
    // 
    // right new we will continue to asked the user for the accommodation dates
    router.push('/add_accommodation_from_image');
    return true;
  },
  async callAllImagesApi({ getters, dispatch, rootState, commit }) {
    dispatch('showModalWithParameters', {
      errorNumber: "waiting_to_get_image_info",
      type: 'textConverter'
    });
    commit( 'setOpenAichosenPlace', {'success': 'false', 'id': null, 'displayName': {'text': 'loading the image data', 'languageCode': null}} );
    commit( 'setGoogleLocationsOptions',[] );

    const atractionsMetadataList = getters.atractionsMetadataList;
    const { apptoken } = rootState.appauth;
    var response = null
    // we should change the condithon and add the condition at the server as well!!!!!
    // if(atractionsMetadataList[0].location.latitude!=null){
    response = await api.sendImageMetaData(  apptoken,
                                                atractionsMetadataList );
    // }
    dispatch('showModal', '');
    if( response && response.data.ServerMassageCode == "success" ){
      commit( 'addImagesAnaliticsToMetaData', response.data.locationAnalitics);

    }
  },

  async createOrUpdateTripFromImages({ getters, dispatch }) {
    const uploadRideIdFromImage = getters.uploadRideIdFromImage;
    if(uploadRideIdFromImage){
      await dispatch('updateTheCurentTripDatesIfNedded' );
    }
    else{
      await dispatch('createTripFromImages' );
    }
  },

  async createTripFromImages({ commit, rootState, getters, dispatch }) {
    let uploadRideIdFromImage = getters.uploadRideIdFromImage;
    const atractionsMetadataList = getters.atractionsMetadataList;

    const { firstDateFromImagesNewFormat,lastDateFromImagesNewFormat, dayDifferenceFromImages } = await extractUpLoadImagesDates(atractionsMetadataList, dispatch);
    if (!firstDateFromImagesNewFormat || !lastDateFromImagesNewFormat) {
      dispatch('showModalWithParameters', {
        errorNumber: "all_images_without_metadata",
        type: 'textConverter'
      });
      return;
    }

    if (dayDifferenceFromImages > config.config.TRIP_DURATION_LIMIT_IN_DAYS) {
      dispatch('showModalWithParameters', {
        errorNumber: "limiting_the_trip_duration",
        type: 'textConverter'
      });
      return;
    }

    // Step 2: Handle ride ID and fetch ride details
    uploadRideIdFromImage = await createRideIfNeded({
      commit,
      rootState,
      dispatch,
      uploadRideIdFromImage,
      firstDateFromImagesNewFormat,
      lastDateFromImagesNewFormat
    });
    
    // Step 3: Load ride information and navigate
    await dispatch('rideInformationLoadDefault');
    commit('setLowedRideId', uploadRideIdFromImage);
    await dispatch('fetchRide');

    router.push('/add_attraction');
    return true;
  },

  async updateTheCurentTripDatesIfNedded({ getters, dispatch }) {
    const atractionsMetadataList = getters.atractionsMetadataList;
    const formatedDates = formatDates(atractionsMetadataList);
    const ride = getters.allRide;

    if (!formatedDates.length) {
      dispatch('showModalWithParameters', {
        errorNumber: "all_images_dates_are_empty",
        type: 'textConverter'
      });
      return;
    }
  
    const firstDateFromImages = new Date(Math.min(...formatedDates));
    const lastDateFromImages = new Date(Math.max(...formatedDates));
    const firstDateFromRide = new Date(ride.ride.start_date.split('-').reverse().join('-'));
    const lastDateFromRide = new Date(ride.ride.end_date.split('-').reverse().join('-'));

    if (!firstDateFromImages || !lastDateFromImages) {
      dispatch('showModalWithParameters', {
        errorNumber: "all_images_without_metadata",
        type: 'textConverter'
      });
      return;
    }
    
    const firstDate = new Date(Math.min(firstDateFromImages, firstDateFromRide));
    const lastDate = new Date(Math.max(lastDateFromImages, lastDateFromRide));
    const dayDifference = Math.abs(lastDate - firstDate) / (1000 * 60 * 60 * 24); // Convert to days
    if (dayDifference > config.config.TRIP_DURATION_LIMIT_IN_DAYS) {
      dispatch('showModalWithParameters', {
        errorNumber: "limiting_the_trip_duration",
        type: 'textConverter'
      });
      return;
    }

    await handleRideDateUpdates(
      dispatch,
      firstDateFromRide,
      firstDateFromImages,
      lastDateFromImages,
      lastDateFromRide
      );

    await dispatch('fetchRide');
    
    router.push('/add_attraction');
    return true;
  },

  async addAccommodationToRideFromImage( {dispatch, rootState, getters, commit },  place  ) {
    // const placeStructure = {type: "place_id", data: {placeId: "Ur3Smtia3RM3msMm"}}
    // const locationInfo1 = {type: "geoLocation", data: {location: { "latitude": [ 45, 24, 24.59 ], "latitudeRef": "N", "longitude": [ 24, 38, 12.39 ], "longitudeRef": "E" }}
    const atractionsMetadataList = getters.atractionsMetadataList;
    const uploadRideIdFromImage = getters.uploadRideIdFromImage;
    if (atractionsMetadataList && atractionsMetadataList.length > 0) {
      const firstElement = await dispatch('shiftAndReturnAtraction');

      const locationFromImageCheckIn = getters.locationFromImageCheckIn;
      const locationFromImageCheckOut = getters.locationFromImageCheckOut;

      const ride = getters.allRide;
      const locationFromImageCheckInDate = new Date( locationFromImageCheckIn.replace(/^(\d{4}):(\d{2}):(\d{2})/, '$1-$2-$3') );
      const locationFromImageCheckOutDate = new Date( locationFromImageCheckOut.replace(/^(\d{4}):(\d{2}):(\d{2})/, '$1-$2-$3') );
      const firstDateFromRide = new Date(ride.ride.start_date.split('-').reverse().join('-'));
      const lastDateFromRide = new Date(ride.ride.end_date.split('-').reverse().join('-'));

      const newFirstDate = new Date(Math.min(locationFromImageCheckInDate, firstDateFromRide));
      const newLastDate = new Date(Math.max(locationFromImageCheckOutDate, lastDateFromRide));
      const dayDifference = Math.abs(newLastDate - newFirstDate) / (1000 * 60 * 60 * 24); // Convert to days
      if (dayDifference > config.config.TRIP_DURATION_LIMIT_IN_DAYS) {
        dispatch('showModalWithParameters', {
          errorNumber: "limiting_the_trip_duration",
          type: 'textConverter'
        });
        return;
      }

      const validAccommodationBooking = await isValidAccommodationBooking(ride, locationFromImageCheckInDate, locationFromImageCheckOutDate);
      if (!validAccommodationBooking) {
        dispatch('showModalWithParameters', {
          errorNumber: "you_Are_having_double_booking",
          type: 'textConverter'
        });
        return;
      }

      await handleRideDateUpdates(
        dispatch,
        firstDateFromRide,
        locationFromImageCheckInDate,
        locationFromImageCheckOutDate,
        lastDateFromRide
        );

      const newAttractionInformation = await formatAttractionInformation( locationFromImageCheckIn,
                                                                          locationFromImageCheckOut,
                                                                          uploadRideIdFromImage,
                                                                          firstElement,
                                                                          place,
                                                                          getters
                                                                        );
                                                                      
      const { apptoken } = rootState.appauth;
      await accommodationApi.addAccommodationFromImageToRide( apptoken, 
                                                              newAttractionInformation);
    }
    commit( 'resetPlaceInAndOutInformation' );
    return true 
  },

  async addAttractionToRideFromImage( {rootState, getters, dispatch},  place  ) {
    // const placeStructure = {type: "place_id", data: {placeId: "Ur3Smtia3RM3msMm"}}
    // const locationInfo1 = {type: "geoLocation", data: {location: { "latitude": [ 45, 24, 24.59 ], "latitudeRef": "N", "longitude": [ 24, 38, 12.39 ], "longitudeRef": "E" }}
    const atractionsMetadataList = getters.atractionsMetadataList;
    const uploadRideIdFromImage = getters.uploadRideIdFromImage;
    if (atractionsMetadataList && atractionsMetadataList.length > 0) {
      const firstElement = await dispatch('shiftAndReturnAtraction');
      var dateTaken = firstElement.dateTaken
      const imageUploadTripLastImageDate = getters.imageUploadTripLastImageDate

      if(!dateTaken){
        dateTaken = imageUploadTripLastImageDate

      }
      
      const formattedDateTaken = new Date(dateTaken.replace(/^(\d{4}):(\d{2}):(\d{2})/, '$1-$2-$3')).toLocaleString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: true
      });
      var newAttractionInformation = {}
      newAttractionInformation['ride_id'] = uploadRideIdFromImage;
      newAttractionInformation['image_url'] = firstElement.url;
      newAttractionInformation['date_taken'] = formattedDateTaken;
      newAttractionInformation['place_data'] = place;
      
      const { apptoken } = rootState.appauth;
      await editRideApi.addAttractionFromImageToRide(apptoken,
        newAttractionInformation);
    }
    return true
  },

  formatDateToString( _ , date) {
    const day = String(date.getDate()).padStart(2, '0');  // Ensures two digits for day
    const month = String(date.getMonth() + 1).padStart(2, '0');  // Months are zero-indexed, so we add 1
    const year = date.getFullYear();  // Get the full year    
    return `${day}-${month}-${year}`;
  },
  
  async shiftAndReturnAtraction({ commit, state }) {
    const shiftedItem = state.atractionsMetadataList[0];
    if (state.atractionsMetadataList.length > 0) {
      state.atractionsMetadataList.shift();
      commit( 'incrementTripFromImagesLocationInsertionStage' );
    }
    if (shiftedItem.location && shiftedItem.location.latitude !== null && shiftedItem.location.longitude !== null) {
      state.imageUploadTripDefaultPresentedLocation = shiftedItem.location;
    }
    const tekenTime = shiftedItem.dateTaken;
    if( tekenTime ){
      state.imageUploadTripLastImageDate = tekenTime
    }
    return shiftedItem;
  }

};

const mutations = {
  addImagesAnaliticsToMetaData: ( state, imagesAnalitics) => {
    state.atractionsMetadataList = state.atractionsMetadataList.map((image) => {
      // Find the corresponding data in imagesAnalitics by matching the URL
      const matchingData = imagesAnalitics.find((imageAnalitics) => imageAnalitics.imageUrl === image.url);
    
      // Insert `matchingData` as `analytics` in the image object if a match is found
      return matchingData ? { ...image, analytics: matchingData } : image;
    });
    
  },
  setIsFirstTimeAddingImages: ( state, isFirstTimeAddingImages) => {
    state.isFirstTimeAddingImages = isFirstTimeAddingImages;
  },
  pushToAtractionsMetadataList: ( state, atraction) => {
    state.atractionsMetadataList.push(atraction);
  },
  sortAtractionsMetadataListByDate: (state) => {
    state.atractionsMetadataList.sort((a, b) => {
      var formattedA = null
      var formattedB = null
      if (a&&a.dateTaken){
        // Convert "YYYY:MM:DD HH:MM:SS" to "YYYY-MM-DDTHH:MM:SS"
        formattedA = a.dateTaken.replace(/^(\d{4}):(\d{2}):(\d{2})/, '$1-$2-$3');
      }
      if (b&&b.dateTaken){
        // Convert "YYYY:MM:DD HH:MM:SS" to "YYYY-MM-DDTHH:MM:SS"
        formattedB = b.dateTaken.replace(/^(\d{4}):(\d{2}):(\d{2})/, '$1-$2-$3');
      }
      if (formattedA && formattedB) {
        return new Date(formattedA) - new Date(formattedB); // Ascending order by date
      } else if (formattedA) {
        return -1; // `formattedA` has date, `formattedB` does not, `formattedA` should come first
      } else if (formattedB) {
        return 1; // `formattedB` has date, `formattedA` does not, `b` should come first
      }
      return 0; // Both are null, keep original order
    });
    const nextLocation = state.atractionsMetadataList.find(
      (item) => item.location && item.location.latitude !== null && item.location.longitude !== null
    );
  
    if (nextLocation) {
      state.imageUploadTripDefaultPresentedLocation = nextLocation.location;
    } else {
      state.imageUploadTripDefaultPresentedLocation = null;
    }

  },
  setUploadRideIdFromImage:(state, uploadRideIdFromImage) => {
    state.uploadRideIdFromImage = uploadRideIdFromImage;
  },
  setOpenAichosenPlace:(state, openAichosenPlace) => {
    state.openAichosenPlace = openAichosenPlace;
  },
  setGoogleLocationsOptions:(state, googleLocationsOptions) => {
    state.googleLocationsOptions = googleLocationsOptions;
  },
  setUploadRideState:(state, uploadRideState) => {
    state.uploadRideState = uploadRideState;
  },
  setLocationFromImageCheckIn:(state, locationFromImageCheckIn) => {
    state.locationFromImageCheckIn = locationFromImageCheckIn;
  },
  setLocationFromImageCheckOut:(state,locationFromImageCheckOut) => {
    state.locationFromImageCheckOut = locationFromImageCheckOut;
  },
  setTripFromImagesLocationInsertionStage(state, status) {
    state.tripFromImagesLocationInsertionStage = status;
  },
  incrementTripFromImagesLocationInsertionStage(state) {
    state.tripFromImagesLocationInsertionStage.currentStep++;
  },
  resetPlaceInAndOutInformation(state) {
    state.locationFromImageCheckIn = null;
    state.locationFromImageCheckOut = null;
  },
  resetTripFromImagesLocationInsertionStage(state, totalLocationsSteps) {
    state.tripFromImagesLocationInsertionStage = {
      currentStep: 1,
      totalLocationsSteps: totalLocationsSteps,
    };
  },
  setTripFromImagesUploadStatus(state, status) {
    state.tripFromImagesUploadStatus = status;
  },
  incrementUploadedFiles(state) {
    state.tripFromImagesUploadStatus.uploadedFiles++;
  },
  resetUploadStatus(state) {
    state.tripFromImagesUploadStatus = {
      totalFiles: 0,
      uploadedFiles: 0,
    };
  },
  setImageUploadTripLastImageDate(state, imageUploadTripLastImageDate) {
    state.imageUploadTripLastImageDate = imageUploadTripLastImageDate;
  },
};

async function isValidAccommodationBooking(ride, locationFromImageCheckInDate, locationFromImageCheckOutDate) {
  // Helper function to remove the time part from the date
  function stripTimeFromDate(date) {
    const strippedDate = new Date(date);
    strippedDate.setHours(0, 0, 0, 0); // Reset hours, minutes, seconds, and milliseconds
    return strippedDate;
  }

  // Ensure we compare only the date part
  const checkInDate = stripTimeFromDate(locationFromImageCheckInDate);
  const checkOutDate = stripTimeFromDate(locationFromImageCheckOutDate);

  for (let dayInformationInRide of ride.attractionsByDay) {
    let dayInformationInRideDate = stripTimeFromDate(new Date(dayInformationInRide.dateTime));

    if (dayInformationInRideDate >= checkOutDate) {
      return true;
    }

    if (dayInformationInRideDate >= checkInDate && Object.keys(dayInformationInRide.accommodation).length) {
      return false;
    }
  }
  return true;
}

async function formatAttractionInformation(locationFromImageCheckIn, locationFromImageCheckOut, uploadRideIdFromImage, firstElement, place, getters) {
  // last structure place = {id: "drS32rtTTmsdo34KS5d"}
  const formattedLocationFromImageCheckIn = locationFromImageCheckIn.replace(/^(\d{4}):(\d{2}):(\d{2})/, '$3-$2-$1');
  const formattedLocationFromImageCheckOut = locationFromImageCheckOut.replace(/^(\d{4}):(\d{2}):(\d{2})/, '$3-$2-$1');

  let newAttractionInformation = {};
  newAttractionInformation['ride_id'] = uploadRideIdFromImage;
  newAttractionInformation['image_url'] = firstElement.url;
  newAttractionInformation['place_data'] = place;
  newAttractionInformation['type'] = getters.newAttractionOptions['ACCOMMODATION'];
  newAttractionInformation['checkIn'] = formattedLocationFromImageCheckIn;
  newAttractionInformation['checkOut'] = formattedLocationFromImageCheckOut;

  return newAttractionInformation;
}

async function handleRideDateUpdates( dispatch, firstDateFromRide, firstDateFromImages, lastDateFromImages, lastDateFromRide ) {
  const newRideDates = { rideCheckout: null, rideCheckin: null };
  let needToUpdateTheRideDates = false;

  if (isDateGreaterWithoutTime(firstDateFromRide, firstDateFromImages)) {
    newRideDates['rideCheckin'] = await dispatch('formatDateToString', firstDateFromImages);
    needToUpdateTheRideDates = true;
  }

  if (isDateGreaterWithoutTime(lastDateFromImages, lastDateFromRide)) {
    newRideDates['rideCheckout'] = await dispatch('formatDateToString', lastDateFromImages);
    needToUpdateTheRideDates = true;
  }

  if (needToUpdateTheRideDates) {
    await dispatch('updateRideDuration', newRideDates);
  }
}

async function extractUpLoadImagesDates(atractionsMetadataList, dispatch) {
  const formatedDates = formatDates(atractionsMetadataList);
  if (!formatedDates.length) {
    dispatch('showModalWithParameters', {
      errorNumber: "all_images_dates_are_empty",
      type: 'textConverter'
    });
    return { firstDateFromImagesNewFormat: null, lastDateFromImagesNewFormat:null, dayDifferenceFromImages: null }
  }

  const firstDate = new Date(Math.min(...formatedDates));
  const lastDate = new Date(Math.max(...formatedDates));

  const firstDateFromImagesNewFormat = await dispatch('formatDateToString', firstDate);
  const lastDateFromImagesNewFormat = await dispatch('formatDateToString', lastDate);

  const dayDifferenceFromImages = Math.abs(lastDate - firstDate) / (1000 * 60 * 60 * 24); // Convert to days
  return { firstDateFromImagesNewFormat, lastDateFromImagesNewFormat, dayDifferenceFromImages };
}

function formatDates(atractionsMetadataList) {
  const dateTakenList = atractionsMetadataList.map(item => item["dateTaken"]);
  return dateTakenList
    .map(dateString => {
      if (dateString) {
        const formatedDateString = dateString.replace(/^(\d{4}):(\d{2}):(\d{2})/, '$1-$2-$3');
        return new Date(formatedDateString);
      }
      return null;
    })
    .filter(date => date !== null);
}

async function createRideIfNeded({ commit, rootState, dispatch, uploadRideIdFromImage, firstDateFromImagesNewFormat, lastDateFromImagesNewFormat }) {
  const isSameRide = await dispatch('TheSameIdLikeRide');

  if (isSameRide) {
    return uploadRideIdFromImage;
  }

  // Create a new ride
  const rideInformation = buildRideInformation(firstDateFromImagesNewFormat, lastDateFromImagesNewFormat);
  const { apptoken } = rootState.appauth;

  if (!uploadRideIdFromImage) {
    const response = await rideInformationApi.setRideInformation(apptoken, rideInformation);

    if (response.statusText !== "OK") {
      return null;
    }
    commit('setUploadRideIdFromImage', response.data.rideId);
    uploadRideIdFromImage = response.data.rideId;
  }

  return uploadRideIdFromImage;
}

function buildRideInformation(firstDateNewFormat, lastDateNewFormat) {
  return {
    title: "",
    photos_albom_link: "",
    text: "",
    checkIn: firstDateNewFormat,
    checkOut: lastDateNewFormat,
    number_of_adult: 2,
    number_of_teen: 1,
    number_of_child: 1,
    physical_disabilities: "false"
  };
}

function isDateGreaterWithoutTime(date1, date2) {
  return (
    date1.getFullYear() > date2.getFullYear() ||
    (date1.getFullYear() === date2.getFullYear() && date1.getMonth() > date2.getMonth()) ||
    (date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() > date2.getDate())
  );
}

export default {
  state,
  getters,
  actions,
  mutations
};
