<template>
  <b-button
    class="btn btn-warning px-5 py-3 rounded-pill fw-semibold font-weight-bold z-2"
    @click="handleClick"
  >
    <slot></slot>
  </b-button>
</template>

<script >
export default {
  props: {
    callBack: {
      type: Function,
      required: true
    },
  },
  methods: {
    handleClick(event) {
      event.target.blur(); // Remove focus from the button
      this.callBack(); // Call the passed callback
    },
  },
};

</script>



<style scoped>

</style>