import api from '../../api/searchAccommodations'
import { router } from '../../main';

const state = {
  searchAccommodations: {},
  searchAccommodationsLocation: null,
};

const getters = {
  searchAccommodations: state => state.searchAccommodations,
  searchAccommodationsLocation: state => state.searchAccommodationsLocation,
};

const actions = {
  async goToSerchAccommodations({ rootState, commit, dispatch }, location) {
    const lat = location.latitude; // add this values -----------------------
    const lng = location.longtitude;
    commit('setSearchAccommodationLocation', {"lat":lat, "lng":lng});
    const { apptoken } = rootState.appauth;
    const response = await api.fetchSearchAccommodationsByLocation(apptoken, lat, lng);
    commit('setSearchAccommodations', response.data);
    dispatch('updateMarkerByScrollerLocationAtAccommodationSearch');
    if (router.currentRoute.path !== '/search_accommodations') {
      router.push('/search_accommodations');
    }
  },
  goToSerchAccommodationsByName({ dispatch }, placeName) {
    dispatch('getPlaceLocationFromNameAndRunCalback', { callback: "goToSerchAccommodations", name: placeName} );
  },
  
  
};

const mutations = {
  setSearchAccommodations: (state, searchAccommodations) => {
    state.searchAccommodations = searchAccommodations;
  },
  setSearchAccommodationLocation: (state, location) => {
    state.searchAccommodationsLocation = location;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
