var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"position-relative overflow-hidden"},[_c('AppNavbar'),_vm._m(0),_c('ImageBanner',{attrs:{"backgroundImage":'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/page_images%2Fadd-images-banner.jpg?alt=media&token=9e2124e3-11a6-4df9-bae0-3054eca5ba9a',"paragraph":"יצירת טיול בצורה אוטומטית על ידי העלאת תמונות מהטיול","size":"240px","title":"הכנסה אוטומטית של הטיול"}}),_c('b-container',[_c('b-row',{},[_c('AddImageCard',{staticClass:"order-2 order-md-2",attrs:{"backgroundImage":'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/page_images%2Faccomodation-illustration.svg?alt=media&token=a226080e-feba-4bbc-9669-192f9d1b007f',"paragraph":"בשלב נפרד אנו נכניס את המקומות שאתם ממליצים לישון\n                    אים המלון שלכם לא היה מומלץ אז כדי להגיד באיזה איזור מומלץ לישון\n                    ","step-number":"2","title":"הוספת מקום לינה"}}),_c('AddImageCard',{staticClass:"order-1 order-md-1",attrs:{"backgroundImage":'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/page_images%2Fattraction-illustration.svg?alt=media&token=b799c3b2-0870-4432-8adb-8b27c922e1e9',"paragraph":"כדאי ליצור את הטיול יש ללכת לאלבום התמונות בטלפון ולהעלות את התמונות מהטיול לפי הכללים הבאים:","paragraphSections":[
                      'יש להעלות תמונה אחת בלבד מכל אטרקציה.',
                      'מומלץ להעלות את כל התמונות בבת אחת.',
                      'חובה שהתמונות יכילו נתוני מיקום (GPS).',
                      'ודאו ששיתוף המיקום פעיל בעת העלאת התמונות.',
                      'התמונות יכולות לכלול כל מקום שתרצו להמליץ עליו למטיילים הבאים: מסעדה, שפת אגם, או כל מקום מעניין אחר.'
                    ],"step-number":"1","title":"הוספת אטרקציות"}})],1),_c('div',{staticClass:"d-flex justify-content-center"},[_c('PrimaryButton',{on:{"click":_vm.triggerFileInput}},[_vm._v("העלו את כל התמונות (אחת מכל אטרקציה)")]),_c('input',{ref:"fileInput",staticStyle:{"display":"none"},attrs:{"type":"file","id":"file-input","multiple":""},on:{"change":_vm.onFileChange}})],1)],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{staticClass:"background-image",attrs:{"alt":"","src":"https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/page_images%2Fcircles-background.png?alt=media&token=493f0ef0-6c28-4446-b093-b978b6b722fc"}})])
}]

export { render, staticRenderFns }