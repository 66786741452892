<template>
  <div class="SearchRide" style="position: relative">
        <SingleRideHeader
      presentedText="חיפוש טיול"
    />
    <div
    class="centering after_page_header"
    >
      <SearchBar/>
    </div>
    <div class="trip-page-section trip-page">
      <div class="container">
        <div
          v-if="globalRides.length"
          class="trip-card-item">
          <div
            class="trip-card-wrap border4"
            v-for="(globalRide, index ) in globalRides" :key="index">
            <div class="trip-card-header">
              <h4>{{globalRide.title}}</h4>
            </div>
            <div class="trip-card-body">
              <div class="trip-card-thumb">
                <b-carousel
                :id=carouselId(index)
                :interval="3000"
                controls
                indicators
                style="max-height: 245px;max-width: 350px"
                background="#ccc"
                >
                  <!-- we must updaete this one -->
                  <b-carousel-slide v-for="(image, idx) in globalRide.rideImages" :key="idx">
                    <template v-slot:img>
                      <img
                        class="d-block img-fluid w-100"
                        :src=smalerImage(image.href)
                        @error="loadErrorImage($event, image.href)"
                        alt="image slot"
                        style="max-height: 245px;max-width: 350px"
                      >
                    </template>
                  </b-carousel-slide>
                </b-carousel>
                <!-- <img src="images/thumb5.png" alt=""> -->
              </div>
              <div class="trip-card-content">
                <p>{{globalRide.text}}
                </p>
                <div class="trip-card-footer">
                  <div class="trip-date-wrap trip-date-wrap2">
                    <div class="field">
                      <div class="ui calendar rangestart2">
                        <div class="ui input icon">
                          <i class="calendar icon"></i>
                          <input type="text" :placeholder="globalRide.end_date">
                        </div>
                      </div>
                    </div>
                    <div class="field">
                      <div class="ui calendar rangeend2">
                        <div class="ui input icon">
                          <i class="calendar icon"></i>
                          <input type="text" :placeholder="globalRide.start_date">
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="trip-card-footer-top">
                    <button
                      class="trip-modal"
                      type="button"
                      @click="goToRide(globalRide.id)">
                      צפיה במסלול
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <instruction-card />

    <PageFooter />
  </div>
</template>

<script>

import config from '@/common/config';
import InstructionCard from "@/components/InstructionCard.vue";
import { mapActions, mapGetters } from 'vuex';
// import Gallery from "vue-cover-gallery";
import PageFooter from "@/components/PageFooter.vue";
import SingleRideHeader from "@/components/SingleRideHeader";


export default {
  name: "SearchRide",
  data() {
    return {
      slide: 0,
      sliding: null,
      links: [
        {
          text: "חיפוש מתקדם",
          icon: "",
        },
        {
          text: 'טיולים לחו"ל',
          icon: "",
        },
        {
          text: "טיולים בארץ",
          icon: "",
        },
        {
          text: "הטיולים הכי נפוצים",
          icon: "",
        },
        {
          text: "כל הטיולים",
          icon: "",
        },
      ],
      mobileLinks: [
        {
          text: "חיפוש מתקדם",
          icon: "",
        },
        {
          text: "הטיולים הכי נפוצים",
          icon: "",
        },
        {
          text: "כל הטיולים",
          icon: "",
        },
      ]
    };
  },
  components: {
    InstructionCard,
    PageFooter,
    SearchBar: () => import("@/components/SearchBar.vue"),
    SingleRideHeader,


  },
  computed: mapGetters(['globalRides',
                        'userId',
                        'smalerImage' ]),
  methods: {
    ...mapActions([ 'fetchGlobalRidesByName',
                    ]),
    loadErrorImage( event, URL ){
      event.target.src = URL
    },

    curentuserIsTheRideAuthor( attractionAuthorId ){
      if( this.userId == attractionAuthorId ){
        return true;
        }
      return false
    },
    carouselId( index ){
      // console.log( "carousel-" + index.toString() )
      return "carousel-" + index.toString()
    },
    yearPeriod( globalRide ){
      if( globalRide.start_date.split(" ")[1] == globalRide.end_date.split(" ")[1])
        return globalRide.start_date.split(" ")[1]
      else
        return globalRide.start_date.split(" ")[1] + " - " + globalRide.end_date.split(" ")[1]
    },
    rideDuration( globalRide){
      return " טיול של " + globalRide.duration + " ימים "
    },
    participent( globalRide ){
      var text = "" ;
      if( globalRide.number_of_child )
        text = "ילדים"
      if( ( ( globalRide.number_of_adult ) || ( globalRide.number_of_teen ) ) & ( globalRide.number_of_child ) )
        text = " ו" + text
      if( globalRide.number_of_teen )
        text = "נערים" + text
      if( ( globalRide.number_of_adult ) & ( globalRide.number_of_teen ) & ( globalRide.number_of_child ) )
        text = "," + text
      if( ( globalRide.number_of_adult ) & ( globalRide.number_of_teen ) & ( globalRide.number_of_child == 0 ) )
        text = " ו" + text
      if( globalRide.number_of_adult )
        text = "בוגרים" + text
      return text
    },
    goToRide( rideId ) {
      this.$router.push('/single_ride_with_map/'+ rideId)
    },
  },
  created(){
    const location_name = this.$route.params.location_name
    const urlName = this.$route.name;
    if( config.config.ROUTES_URL_NAMES.searchRideByName == urlName && location_name ){
      this.fetchGlobalRidesByName( location_name );      
    }
  },
  metaInfo() {
    return {
      title: 'TripPlanner',
      link: [
        { property: 'og:title', content: 'TripPlanner' },
        { property: 'og:description', content: 'ניתן למצוא מסלולים שמטיילים אחרים שיתפו' },
        { property: 'og:type', content: 'website' },
        { property: 'og:url', content: `https://tripplanner.co.il/search_ride` },
        // { property: 'og:image', content: 'https://tripplanner.co.il/image-url.jpg' },
        { name: 'description', content: 'חיפוש מסלולי טיול' },
        // { name: 'keywords', content: 'keyword1, keyword2, keyword3' },
        { rel: 'canonical', href: "https://tripplanner.co.il/search_ride" }
      ]
    };
  }
};
</script>

<style scoped>
.after_page_header{
  margin-top: 15rem;
}

.serch_ride_title{
  font-size: 2.4rem;
}
.serch_ride_content{
  font-size: 1.7rem;
}
.serch_ride_icon_information{
  font-size: 1.4rem;
}

.new_information {
  display: none !important;
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 3em;
}

#container {
  width: 100%;
  height: 100%;
}
#up {
  min-height: 50px;
}
#down {
  height: calc(100% - 50px);
}
.lead-head {
  /* font-family: Assistant; */
  font-style: normal;
  font-weight: 800;
  font-size: 30px;
  line-height: 39px;
  text-align: right;
}
.res-row {
  min-height: 235px;
}

@media (max-width: 575.98px) {
  .res-row {
    min-height: 235px;
  }
  .dropdown-width {
    width: 75% !important;
  }
}
@media (min-width: 768px) and (max-width: 1023.98px) {
  .res-row {
    min-height: 235px;
  }
}
@media (min-width: 1024.98px) {
  .res-row {
    min-height: 283px;
  }
}
.custom-select {
  width: 100%;
  border: 0;
  padding: 10px 10px;
  background-color: transparent;
  border-bottom: 1px solid #929fba !important;
  border-radius: 0;
}
.expand-arrow {
  background: #fff
    url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 50 50' %3E%3Cpath d='M 44.988281 13.984375 C 44.726563 13.992188 44.476563 14.101563 44.292969 14.292969 L 25 33.585938 L 5.707031 14.292969 C 5.519531 14.097656 5.261719 13.992188 4.992188 13.988281 C 4.582031 13.992188 4.21875 14.238281 4.0625 14.613281 C 3.910156 14.992188 4 15.421875 4.292969 15.707031 L 24.292969 35.707031 C 24.683594 36.097656 25.316406 36.097656 25.707031 35.707031 L 45.707031 15.707031 C 46.003906 15.421875 46.09375 14.980469 45.9375 14.601563 C 45.777344 14.222656 45.402344 13.976563 44.988281 13.984375 Z' %3E%3C/path%3E%3C/svg%3E")
    no-repeat;
  padding: 0.4em;
}
.my-class {
  overflow: hidden;
}
</style>
